export const cityNames=[
    "Abano Terme - PD",
    "Abbadia Cerreto - LO",
    "Abbadia Lariana - LC",
    "Abbadia San Salvatore - SI",
    "Abbasanta - OR",
    "Abbateggio - PE",
    "Abbiategrasso - MI",
    "Abetone - PT",
    "Abriola - PZ",
    "Acate - RG",
    "Accadia - FG",
    "Acceglio - CN",
    "Accettura - MT",
    "Acciano - AQ",
    "Accumoli - RI",
    "Acerenza - PZ",
    "Acerno - SA",
    "Acerra - NA",
    "Aci Bonaccorsi - CT",
    "Aci Castello - CT",
    "Aci Catena - CT",
    "Aci Sant'Antonio - CT",
    "Acireale - CT",
    "Acquacanina - MC",
    "Acquafondata - FR",
    "Acquaformosa - CS",
    "Acquafredda - BS",
    "Acqualagna - PU",
    "Acquanegra Cremonese - CR",
    "Acquanegra sul Chiese - MN",
    "Acquapendente - VT",
    "Acquappesa - CS",
    "Acquarica del Capo - LE",
    "Acquaro - VV",
    "Acquasanta Terme - AP",
    "Acquasparta - TR",
    "Acquaviva Collecroce - CB",
    "Acquaviva delle Fonti - BA",
    "Acquaviva d'Isernia - IS",
    "Acquaviva Picena - AP",
    "Acquaviva Platani - CL",
    "Acquedolci - ME",
    "Acqui Terme - AL",
    "Acri - CS",
    "Acuto - FR",
    "Adelfia - BA",
    "Adrano - CT",
    "Adrara San Martino - BG",
    "Adrara San Rocco - BG",
    "Adria - RO",
    "Adro - BS",
    "Affi - VR",
    "Affile - RM",
    "Afragola - NA",
    "Africo - RC",
    "Agazzano - PC",
    "Agerola - NA",
    "Aggius - OT",
    "Agira - EN",
    "Agliana - PT",
    "Agliano Terme - AT",
    "Agliè - TO",
    "Aglientu - OT",
    "Agna - PD",
    "Agnadello - CR",
    "Agnana Calabra - RC",
    "Agnone - IS",
    "Agnosine - BS",
    "Agordo - BL",
    "Agosta - RM",
    "Agra - VA",
    "Agrate Brianza - MB",
    "Agrate Conturbia - NO",
    "Agrigento - AG",
    "Agropoli - SA",
    "Agugliano - AN",
    "Agugliaro - VI",
    "Aicurzio - MB",
    "Aidomaggiore - OR",
    "Aidone - EN",
    "Aielli - AQ",
    "Aiello Calabro - CS",
    "Aiello del Friuli - UD",
    "Aiello del Sabato - AV",
    "Aieta - CS",
    "Ailano - CE",
    "Ailoche - BI",
    "Airasca - TO",
    "Airola - BN",
    "Airole - IM",
    "Airuno - LC",
    "Aisone - CN",
    "Ala - TN",
    "Alà dei Sardi - OT",
    "Ala di Stura - TO",
    "Alagna - PV",
    "Alagna Valsesia - VC",
    "Alanno - PE",
    "Alano di Piave - BL",
    "Alassio - SV",
    "Alatri - FR",
    "Alba - CN",
    "Alba Adriatica - TE",
    "Albagiara - OR",
    "Albairate - MI",
    "Albanella - SA",
    "Albano di Lucania - PZ",
    "Albano Laziale - RM",
    "Albano Sant'Alessandro - BG",
    "Albano Vercellese - VC",
    "Albaredo Arnaboldi - PV",
    "Albaredo d'Adige - VR",
    "Albaredo per San Marco - SO",
    "Albareto - PR",
    "Albaretto della Torre - CN",
    "Albavilla - CO",
    "Albenga - SV",
    "Albera Ligure - AL",
    "Alberobello - BA",
    "Alberona - FG",
    "Albese con Cassano - CO",
    "Albettone - VI",
    "Albi - CZ",
    "Albiano - TN",
    "Albiano d'Ivrea - TO",
    "Albiate - MB",
    "Albidona - CS",
    "Albignasego - PD",
    "Albinea - RE",
    "Albino - BG",
    "Albiolo - CO",
    "Albisola Superiore - SV",
    "Albissola Marina - SV",
    "Albizzate - VA",
    "Albonese - PV",
    "Albosaggia - SO",
    "Albugnano - AT",
    "Albuzzano - PV",
    "Alcamo - TP",
    "Alcara Li Fusi - ME",
    "Aldeno - TN",
    "Aldino - BZ",
    "Ales - OR",
    "Alessandria - AL",
    "Alessandria del Carretto - CS",
    "Alessandria della Rocca - AG",
    "Alessano - LE",
    "Alezio - LE",
    "Alfano - SA",
    "Alfedena - AQ",
    "Alfianello - BS",
    "Alfiano Natta - AL",
    "Alfonsine - RA",
    "Alghero - SS",
    "Algua - BG",
    "Alì - ME",
    "Alì Terme - ME",
    "Alia - PA",
    "Aliano - MT",
    "Alice Bel Colle - AL",
    "Alice Castello - VC",
    "Alice Superiore - TO",
    "Alife - CE",
    "Alimena - PA",
    "Aliminusa - PA",
    "Allai - OR",
    "Alleghe - BL",
    "Allein - AO",
    "Allerona - TR",
    "Alliste - LE",
    "Allumiere - RM",
    "Alluvioni Cambiò - AL",
    "Almè - BG",
    "Almenno San Bartolomeo - BG",
    "Almenno San Salvatore - BG",
    "Almese - TO",
    "Alonte - VI",
    "Alpette - TO",
    "Alpignano - TO",
    "Alseno - PC",
    "Alserio - CO",
    "Altamura - BA",
    "Altare - SV",
    "Altavilla Irpina - AV",
    "Altavilla Milicia - PA",
    "Altavilla Monferrato - AL",
    "Altavilla Silentina - SA",
    "Altavilla Vicentina - VI",
    "Altidona - FM",
    "Altilia - CS",
    "Altino - CH",
    "Altissimo - VI",
    "Altivole - TV",
    "Alto - CN",
    "Altofonte - PA",
    "Altomonte - CS",
    "Altopascio - LU",
    "Alviano - TR",
    "Alvignano - CE",
    "Alvito - FR",
    "Alzano Lombardo - BG",
    "Alzano Scrivia - AL",
    "Alzate Brianza - CO",
    "Amalfi - SA",
    "Amandola - FM",
    "Amantea - CS",
    "Amaro - UD",
    "Amaroni - CZ",
    "Amaseno - FR",
    "Amato - CZ",
    "Amatrice - RI",
    "Ambivere - BG",
    "Amblar - TN",
    "Ameglia - SP",
    "Amelia - TR",
    "Amendolara - CS",
    "Ameno - NO",
    "Amorosi - BN",
    "Ampezzo - UD",
    "Anacapri - NA",
    "Anagni - FR",
    "Ancarano - TE",
    "Ancona - AN",
    "Andali - CZ",
    "Andalo - TN",
    "Andalo Valtellino - SO",
    "Andezeno - TO",
    "Andora - SV",
    "Andorno Micca - BI",
    "Andrano - LE",
    "Andrate - TO",
    "Andreis - PN",
    "Andretta - AV",
    "Andria - BT",
    "Andriano - BZ",
    "Anela - SS",
    "Anfo - BS",
    "Angera - VA",
    "Anghiari - AR",
    "Angiari - VR",
    "Angolo Terme - BS",
    "Angri - SA",
    "Angrogna - TO",
    "Anguillara Sabazia - RM",
    "Anguillara Veneta - PD",
    "Annicco - CR",
    "Annone di Brianza - LC",
    "Annone Veneto - VE",
    "Anoia - RC",
    "Antegnate - BG",
    "Anterivo - BZ",
    "Antey-Saint-Andrè - AO",
    "Anticoli Corrado - RM",
    "Antignano - AT",
    "Antillo - ME",
    "Antonimina - RC",
    "Antrodoco - RI",
    "Antrona Schieranco - VB",
    "Anversa degli Abruzzi - AQ",
    "Anzano del Parco - CO",
    "Anzano di Puglia - FG",
    "Anzi - PZ",
    "Anzio - RM",
    "Anzola dell'Emilia - BO",
    "Anzola d'Ossola - VB",
    "Aosta - AO",
    "Apecchio - PU",
    "Apice - BN",
    "Apiro - MC",
    "Apollosa - BN",
    "Appiano Gentile - CO",
    "Appiano sulla Strada del Vino - BZ",
    "Appignano - MC",
    "Appignano del Tronto - AP",
    "Aprica - SO",
    "Apricale - IM",
    "Apricena - FG",
    "Aprigliano - CS",
    "Aprilia - LT",
    "Aquara - SA",
    "Aquila d'Arroscia - IM",
    "Aquileia - UD",
    "Aquilonia - AV",
    "Aquino - FR",
    "Aradeo - LE",
    "Aragona - AG",
    "Aramengo - AT",
    "Arba - PN",
    "Arborea - OR",
    "Arborio - VC",
    "Arbus - VS",
    "Arcade - TV",
    "Arce - FR",
    "Arcene - BG",
    "Arcevia - AN",
    "Archi - CH",
    "Arcidosso - GR",
    "Arcinazzo Romano - RM",
    "Arcisate - VA",
    "Arco - TN",
    "Arcola - SP",
    "Arcole - VR",
    "Arconate - MI",
    "Arcore - MB",
    "Arcugnano - VI",
    "Ardara - SS",
    "Ardauli - OR",
    "Ardea - RM",
    "Ardenno - SO",
    "Ardesio - BG",
    "Ardore - RC",
    "Arena - VV",
    "Arena Po - PV",
    "Arenzano - GE",
    "Arese - MI",
    "Arezzo - AR",
    "Argegno - CO",
    "Argelato - BO",
    "Argenta - FE",
    "Argentera - CN",
    "Arguello - CN",
    "Argusto - CZ",
    "Ari - CH",
    "Ariano Irpino - AV",
    "Ariano nel Polesine - RO",
    "Ariccia - RM",
    "Arielli - CH",
    "Arienzo - CE",
    "Arignano - TO",
    "Aritzo - NU",
    "Arizzano - VB",
    "Arlena di Castro - VT",
    "Arluno - MI",
    "Armeno - NO",
    "Armento - PZ",
    "Armo - IM",
    "Armungia - CA",
    "Arnad - AO",
    "Arnara - FR",
    "Arnasco - SV",
    "Arnesano - LE",
    "Arola - VB",
    "Arona - NO",
    "Arosio - CO",
    "Arpaia - BN",
    "Arpaise - BN",
    "Arpino - FR",
    "Arquà Petrarca - PD",
    "Arquà Polesine - RO",
    "Arquata del Tronto - AP",
    "Arquata Scrivia - AL",
    "Arre - PD",
    "Arrone - TR",
    "Arsago Seprio - VA",
    "Arsiè - BL",
    "Arsiero - VI",
    "Arsita - TE",
    "Arsoli - RM",
    "Arta Terme - UD",
    "Artegna - UD",
    "Artena - RM",
    "Artogne - BS",
    "Arvier - AO",
    "Arzachena - OT",
    "Arzago d'Adda - BG",
    "Arzana - OG",
    "Arzano - NA",
    "Arzene - PN",
    "Arzergrande - PD",
    "Arzignano - VI",
    "Ascea - SA",
    "Asciano - SI",
    "Ascoli Piceno - AP",
    "Ascoli Satriano - FG",
    "Ascrea - RI",
    "Asiago - VI",
    "Asigliano Veneto - VI",
    "Asigliano Vercellese - VC",
    "Asola - MN",
    "Asolo - TV",
    "Assago - MI",
    "Assemini - CA",
    "Assisi - PG",
    "Asso - CO",
    "Assolo - OR",
    "Assoro - EN",
    "Asti - AT",
    "Asuni - OR",
    "Ateleta - AQ",
    "Atella - PZ",
    "Atena Lucana - SA",
    "Atessa - CH",
    "Atina - FR",
    "Atrani - SA",
    "Atri - TE",
    "Atripalda - AV",
    "Attigliano - TR",
    "Attimis - UD",
    "Atzara - NU",
    "Auditore - PU",
    "Augusta - SR",
    "Auletta - SA",
    "Aulla - MS",
    "Aurano - VB",
    "Aurigo - IM",
    "Auronzo di Cadore - BL",
    "Ausonia - FR",
    "Austis - NU",
    "Avegno - GE",
    "Avelengo - BZ",
    "Avella - AV",
    "Avellino - AV",
    "Averara - BG",
    "Aversa - CE",
    "Avetrana - TA",
    "Avezzano - AQ",
    "Aviano - PN",
    "Aviatico - BG",
    "Avigliana - TO",
    "Avigliano - PZ",
    "Avigliano Umbro - TR",
    "Avio - TN",
    "Avise - AO",
    "Avola - SR",
    "Avolasca - AL",
    "Ayas - AO",
    "Aymavilles - AO",
    "Azeglio - TO",
    "Azzanello - CR",
    "Azzano d'Asti - AT",
    "Azzano Decimo - PN",
    "Azzano Mella - BS",
    "Azzano San Paolo - BG",
    "Azzate - VA",
    "Azzio - VA",
    "Azzone - BG",
    "Baceno - VB",
    "Bacoli - NA",
    "Badalucco - IM",
    "Badesi - OT",
    "Badia - BZ",
    "Badia Calavena - VR",
    "Badia Pavese - PV",
    "Badia Polesine - RO",
    "Badia Tedalda - AR",
    "Badolato - CZ",
    "Bagaladi - RC",
    "Bagheria - PA",
    "Bagnacavallo - RA",
    "Bagnara Calabra - RC",
    "Bagnara di Romagna - RA",
    "Bagnaria - PV",
    "Bagnaria Arsa - UD",
    "Bagnasco - CN",
    "Bagnatica - BG",
    "Bagni di Lucca - LU",
    "Bagno a Ripoli - FI",
    "Bagno di Romagna - FC",
    "Bagnoli del Trigno - IS",
    "Bagnoli di Sopra - PD",
    "Bagnoli Irpino - AV",
    "Bagnolo Cremasco - CR",
    "Bagnolo del Salento - LE",
    "Bagnolo di Po - RO",
    "Bagnolo in Piano - RE",
    "Bagnolo Mella - BS",
    "Bagnolo Piemonte - CN",
    "Bagnolo San Vito - MN",
    "Bagnone - MS",
    "Bagnoregio - VT",
    "Bagolino - BS",
    "Baia e Latina - CE",
    "Baiano - AV",
    "Bairo - TO",
    "Baiso - RE",
    "Bajardo - IM",
    "Balangero - TO",
    "Baldichieri d'Asti - AT",
    "Baldissero Canavese - TO",
    "Baldissero d'Alba - CN",
    "Baldissero Torinese - TO",
    "Balestrate - PA",
    "Balestrino - SV",
    "Ballabio - LC",
    "Ballao - CA",
    "Balme - TO",
    "Balmuccia - VC",
    "Balocco - VC",
    "Balsorano - AQ",
    "Balvano - PZ",
    "Balzola - AL",
    "Banari - SS",
    "Banchette - TO",
    "Bannio Anzino - VB",
    "Banzi - PZ",
    "Baone - PD",
    "Baradili - OR",
    "Baragiano - PZ",
    "Baranello - CB",
    "Barano d'Ischia - NA",
    "Baranzate - MI",
    "Barasso - VA",
    "Baratili San Pietro - OR",
    "Barbania - TO",
    "Barbara - AN",
    "Barbarano Romano - VT",
    "Barbarano Vicentino - VI",
    "Barbaresco - CN",
    "Barbariga - BS",
    "Barbata - BG",
    "Barberino di Mugello - FI",
    "Barberino Val d'Elsa - FI",
    "Barbianello - PV",
    "Barbiano - BZ",
    "Barbona - PD",
    "Barcellona Pozzo di Gotto - ME",
    "Barchi - PU",
    "Barcis - PN",
    "Bard - AO",
    "Bardello - VA",
    "Bardi - PR",
    "Bardineto - SV",
    "Bardolino - VR",
    "Bardonecchia - TO",
    "Bareggio - MI",
    "Barengo - NO",
    "Baressa - OR",
    "Barete - AQ",
    "Barga - LU",
    "Bargagli - GE",
    "Barge - CN",
    "Barghe - BS",
    "Bari - BA",
    "Bari Sardo - OG",
    "Bariano - BG",
    "Baricella - BO",
    "Barile - PZ",
    "Barisciano - AQ",
    "Barlassina - MB",
    "Barletta - BT",
    "Barni - CO",
    "Barolo - CN",
    "Barone Canavese - TO",
    "Baronissi - SA",
    "Barrafranca - EN",
    "Barrali - CA",
    "Barrea - AQ",
    "Barumini - VS",
    "Barzago - LC",
    "Barzana - BG",
    "Barzanò - LC",
    "Barzio - LC",
    "Basaluzzo - AL",
    "Bascapè - PV",
    "Baschi - TR",
    "Basciano - TE",
    "Baselga di Pinè - TN",
    "Baselice - BN",
    "Basiano - MI",
    "Basicò - ME",
    "Basiglio - MI",
    "Basiliano - UD",
    "Bassano Bresciano - BS",
    "Bassano del Grappa - VI",
    "Bassano in Teverina - VT",
    "Bassano Romano - VT",
    "Bassiano - LT",
    "Bassignana - AL",
    "Bastia Mondovì - CN",
    "Bastia Umbra - PG",
    "Bastida de' Dossi - PV",
    "Bastida Pancarana - PV",
    "Bastiglia - MO",
    "Battaglia Terme - PD",
    "Battifollo - CN",
    "Battipaglia - SA",
    "Battuda - PV",
    "Baucina - PA",
    "Bauladu - OR",
    "Baunei - OG",
    "Baveno - VB",
    "Bazzano - BO",
    "Bedero Valcuvia - VA",
    "Bedizzole - BS",
    "Bedollo - TN",
    "Bedonia - PR",
    "Bedulita - BG",
    "Bee - VB",
    "Beinasco - TO",
    "Beinette - CN",
    "Belcastro - CZ",
    "Belfiore - VR",
    "Belforte all'Isauro - PU",
    "Belforte del Chienti - MC",
    "Belforte Monferrato - AL",
    "Belgioioso - PV",
    "Belgirate - VB",
    "Bella - PZ",
    "Bellagio - CO",
    "Bellano - LC",
    "Bellante - TE",
    "Bellaria-Igea Marina - RN",
    "Bellegra - RM",
    "Bellino - CN",
    "Bellinzago Lombardo - MI",
    "Bellinzago Novarese - NO",
    "Bellizzi - SA",
    "Bellona - CE",
    "Bellosguardo - SA",
    "Belluno - BL",
    "Bellusco - MB",
    "Belmonte Calabro - CS",
    "Belmonte Castello - FR",
    "Belmonte del Sannio - IS",
    "Belmonte in Sabina - RI",
    "Belmonte Mezzagno - PA",
    "Belmonte Piceno - FM",
    "Belpasso - CT",
    "Belsito - CS",
    "Belvedere di Spinello - KR",
    "Belvedere Langhe - CN",
    "Belvedere Marittimo - CS",
    "Belvedere Ostrense - AN",
    "Belveglio - AT",
    "Belvì - NU",
    "Bema - SO",
    "Bene Lario - CO",
    "Bene Vagienna - CN",
    "Benestare - RC",
    "Benetutti - SS",
    "Benevello - CN",
    "Benevento - BN",
    "Benna - BI",
    "Bentivoglio - BO",
    "Berbenno - BG",
    "Berbenno di Valtellina - SO",
    "Berceto - PR",
    "Berchidda - OT",
    "Beregazzo con Figliaro - CO",
    "Bereguardo - PV",
    "Bergamasco - AL",
    "Bergamo - BG",
    "Bergantino - RO",
    "Bergeggi - SV",
    "Bergolo - CN",
    "Berlingo - BS",
    "Bernalda - MT",
    "Bernareggio - MB",
    "Bernate Ticino - MI",
    "Bernezzo - CN",
    "Berra - FE",
    "Bersone - TN",
    "Bertinoro - FC",
    "Bertiolo - UD",
    "Bertonico - LO",
    "Berzano di San Pietro - AT",
    "Berzano di Tortona - AL",
    "Berzo Demo - BS",
    "Berzo Inferiore - BS",
    "Berzo San Fermo - BG",
    "Besana in Brianza - MB",
    "Besano - VA",
    "Besate - MI",
    "Besenello - TN",
    "Besenzone - PC",
    "Besnate - VA",
    "Besozzo - VA",
    "Bessude - SS",
    "Bettola - PC",
    "Bettona - PG",
    "Beura-Cardezza - VB",
    "Bevagna - PG",
    "Beverino - SP",
    "Bevilacqua - VR",
    "Biancavilla - CT",
    "Bianchi - CS",
    "Bianco - RC",
    "Biandrate - NO",
    "Biandronno - VA",
    "Bianzano - BG",
    "Bianzè - VC",
    "Bianzone - SO",
    "Biassono - MB",
    "Bibbiano - RE",
    "Bibbiena - AR",
    "Bibbona - LI",
    "Bibiana - TO",
    "Biccari - FG",
    "Bicinicco - UD",
    "Bidonì - OR",
    "Biella - BI",
    "Bienno - BS",
    "Bieno - TN",
    "Bientina - PI",
    "Bigarello - MN",
    "Binago - CO",
    "Binasco - MI",
    "Binetto - BA",
    "Bioglio - BI",
    "Bionaz - AO",
    "Bione - BS",
    "Birori - NU",
    "Bisaccia - AV",
    "Bisacquino - PA",
    "Bisceglie - BT",
    "Bisegna - AQ",
    "Bisenti - TE",
    "Bisignano - CS",
    "Bistagno - AL",
    "Bisuschio - VA",
    "Bitetto - BA",
    "Bitonto - BA",
    "Bitritto - BA",
    "Bitti - NU",
    "Bivona - AG",
    "Bivongi - RC",
    "Bizzarone - CO",
    "Bleggio Superiore - TN",
    "Blello - BG",
    "Blera - VT",
    "Blessagno - CO",
    "Blevio - CO",
    "Blufi - PA",
    "Boara Pisani - PD",
    "Bobbio - PC",
    "Bobbio Pellice - TO",
    "Boca - NO",
    "Bocchigliero - CS",
    "Boccioleto - VC",
    "Bocenago - TN",
    "Bodio Lomnago - VA",
    "Boffalora d'Adda - LO",
    "Boffalora Sopra Ticino - MI",
    "Bogliasco - GE",
    "Bognanco - VB",
    "Bogogno - NO",
    "Boissano - SV",
    "Bojano - CB",
    "Bolano - SP",
    "Bolbeno - TN",
    "Bolgare - BG",
    "Bollate - MI",
    "Bollengo - TO",
    "Bologna - BO",
    "Bolognano - PE",
    "Bolognetta - PA",
    "Bolognola - MC",
    "Bolotana - NU",
    "Bolsena - VT",
    "Boltiere - BG",
    "Bolzano - BZ",
    "Bolzano Novarese - NO",
    "Bolzano Vicentino - VI",
    "Bomarzo - VT",
    "Bomba - CH",
    "Bompensiere - CL",
    "Bompietro - PA",
    "Bomporto - MO",
    "Bonarcado - OR",
    "Bonassola - SP",
    "Bonate Sopra - BG",
    "Bonate Sotto - BG",
    "Bonavigo - VR",
    "Bondeno - FE",
    "Bondo - TN",
    "Bondone - TN",
    "Bonea - BN",
    "Bonefro - CB",
    "Bonemerse - CR",
    "Bonifati - CS",
    "Bonito - AV",
    "Bonnanaro - SS",
    "Bono - SS",
    "Bonorva - SS",
    "Bonvicino - CN",
    "Borbona - RI",
    "Borca di Cadore - BL",
    "Bordano - UD",
    "Bordighera - IM",
    "Bordolano - CR",
    "Bore - PR",
    "Boretto - RE",
    "Borgarello - PV",
    "Borgaro Torinese - TO",
    "Borgetto - PA",
    "Borghetto d'Arroscia - IM",
    "Borghetto di Borbera - AL",
    "Borghetto di Vara - SP",
    "Borghetto Lodigiano - LO",
    "Borghetto Santo Spirito - SV",
    "Borghi - FC",
    "Borgia - CZ",
    "Borgiallo - TO",
    "Borgio Verezzi - SV",
    "Borgo a Mozzano - LU",
    "Borgo d'Ale - VC",
    "Borgo di Terzo - BG",
    "Borgo Pace - PU",
    "Borgo Priolo - PV",
    "Borgo San Dalmazzo - CN",
    "Borgo San Giacomo - BS",
    "Borgo San Giovanni - LO",
    "Borgo San Lorenzo - FI",
    "Borgo San Martino - AL",
    "Borgo San Siro - PV",
    "Borgo Ticino - NO",
    "Borgo Tossignano - BO",
    "Borgo Val di Taro - PR",
    "Borgo Valsugana - TN",
    "Borgo Velino - RI",
    "Borgo Vercelli - VC",
    "Borgoforte - MN",
    "Borgofranco d'Ivrea - TO",
    "Borgofranco sul Po - MN",
    "Borgolavezzaro - NO",
    "Borgomale - CN",
    "Borgomanero - NO",
    "Borgomaro - IM",
    "Borgomasino - TO",
    "Borgone Susa - TO",
    "Borgonovo Val Tidone - PC",
    "Borgoratto Alessandrino - AL",
    "Borgoratto Mormorolo - PV",
    "Borgoricco - PD",
    "Borgorose - RI",
    "Borgosatollo - BS",
    "Borgosesia - VC",
    "Bormida - SV",
    "Bormio - SO",
    "Bornasco - PV",
    "Borno - BS",
    "Boroneddu - OR",
    "Borore - NU",
    "Borrello - CH",
    "Borriana - BI",
    "Borso del Grappa - TV",
    "Bortigali - NU",
    "Bortigiadas - OT",
    "Borutta - SS",
    "Borzonasca - GE",
    "Bosa - OR",
    "Bosaro - RO",
    "Boschi Sant'Anna - VR",
    "Bosco Chiesanuova - VR",
    "Bosco Marengo - AL",
    "Bosconero - TO",
    "Boscoreale - NA",
    "Boscotrecase - NA",
    "Bosentino - TN",
    "Bosia - CN",
    "Bosio - AL",
    "Bosisio Parini - LC",
    "Bosnasco - PV",
    "Bossico - BG",
    "Bossolasco - CN",
    "Botricello - CZ",
    "Botrugno - LE",
    "Bottanuco - BG",
    "Botticino - BS",
    "Bottidda - SS",
    "Bova - RC",
    "Bova Marina - RC",
    "Bovalino - RC",
    "Bovegno - BS",
    "Boves - CN",
    "Bovezzo - BS",
    "Boville Ernica - FR",
    "Bovino - FG",
    "Bovisio-Masciago - MB",
    "Bovolenta - PD",
    "Bovolone - VR",
    "Bozzole - AL",
    "Bozzolo - MN",
    "Bra - CN",
    "Bracca - BG",
    "Bracciano - RM",
    "Bracigliano - SA",
    "Braies - BZ",
    "Brallo di Pregola - PV",
    "Brancaleone - RC",
    "Brandico - BS",
    "Brandizzo - TO",
    "Branzi - BG",
    "Braone - BS",
    "Brebbia - VA",
    "Breda di Piave - TV",
    "Bregano - VA",
    "Breganze - VI",
    "Bregnano - CO",
    "Breguzzo - TN",
    "Breia - VC",
    "Brembate - BG",
    "Brembate di Sopra - BG",
    "Brembilla - BG",
    "Brembio - LO",
    "Breme - PV",
    "Brendola - VI",
    "Brenna - CO",
    "Brennero - BZ",
    "Breno - BS",
    "Brenta - VA",
    "Brentino Belluno - VR",
    "Brentonico - TN",
    "Brenzone - VR",
    "Brescello - RE",
    "Brescia - BS",
    "Bresimo - TN",
    "Bressana Bottarone - PV",
    "Bressanone - BZ",
    "Bressanvido - VI",
    "Bresso - MI",
    "Brez - TN",
    "Brezzo di Bedero - VA",
    "Briaglia - CN",
    "Briatico - VV",
    "Bricherasio - TO",
    "Brienno - CO",
    "Brienza - PZ",
    "Briga Alta - CN",
    "Briga Novarese - NO",
    "Brignano Gera d'Adda - BG",
    "Brignano-Frascata - AL",
    "Brindisi - BR",
    "Brindisi Montagna - PZ",
    "Brinzio - VA",
    "Briona - NO",
    "Brione - BS",
    "Brione - TN",
    "Briosco - MB",
    "Brisighella - RA",
    "Brissago-Valtravaglia - VA",
    "Brissogne - AO",
    "Brittoli - PE",
    "Brivio - LC",
    "Broccostella - FR",
    "Brogliano - VI",
    "Brognaturo - VV",
    "Brolo - ME",
    "Brondello - CN",
    "Broni - PV",
    "Bronte - CT",
    "Bronzolo - BZ",
    "Brossasco - CN",
    "Brosso - TO",
    "Brovello-Carpugnino - VB",
    "Brozolo - TO",
    "Brugherio - MB",
    "Brugine - PD",
    "Brugnato - SP",
    "Brugnera - PN",
    "Bruino - TO",
    "Brumano - BG",
    "Brunate - CO",
    "Brunello - VA",
    "Brunico - BZ",
    "Bruno - AT",
    "Brusaporto - BG",
    "Brusasco - TO",
    "Brusciano - NA",
    "Brusimpiano - VA",
    "Brusnengo - BI",
    "Brusson - AO",
    "Bruzolo - TO",
    "Bruzzano Zeffirio - RC",
    "Bubbiano - MI",
    "Bubbio - AT",
    "Buccheri - SR",
    "Bucchianico - CH",
    "Bucciano - BN",
    "Buccinasco - MI",
    "Buccino - SA",
    "Bucine - AR",
    "Buddusò - OT",
    "Budoia - PN",
    "Budoni - OT",
    "Budrio - BO",
    "Buggerru - CI",
    "Buggiano - PT",
    "Buglio in Monte - SO",
    "Bugnara - AQ",
    "Buguggiate - VA",
    "Buja - UD",
    "Bulciago - LC",
    "Bulgarograsso - CO",
    "Bultei - SS",
    "Bulzi - SS",
    "Buonabitacolo - SA",
    "Buonalbergo - BN",
    "Buonconvento - SI",
    "Buonvicino - CS",
    "Burago di Molgora - MB",
    "Burcei - CA",
    "Burgio - AG",
    "Burgos - SS",
    "Buriasco - TO",
    "Burolo - TO",
    "Buronzo - VC",
    "Busachi - OR",
    "Busalla - GE",
    "Busana - RE",
    "Busano - TO",
    "Busca - CN",
    "Buscate - MI",
    "Buscemi - SR",
    "Buseto Palizzolo - TP",
    "Busnago - MB",
    "Bussero - MI",
    "Busseto - PR",
    "Bussi sul Tirino - PE",
    "Busso - CB",
    "Bussolengo - VR",
    "Bussoleno - TO",
    "Busto Arsizio - VA",
    "Busto Garolfo - MI",
    "Butera - CL",
    "Buti - PI",
    "Buttapietra - VR",
    "Buttigliera Alta - TO",
    "Buttigliera d'Asti - AT",
    "Buttrio - UD",
    "Ca' d'Andrea - CR",
    "Cabella Ligure - AL",
    "Cabiate - CO",
    "Cabras - OR",
    "Caccamo - PA",
    "Caccuri - KR",
    "Cadegliano-Viconago - VA",
    "Cadelbosco di Sopra - RE",
    "Cadeo - PC",
    "Caderzone Terme - TN",
    "Cadoneghe - PD",
    "Cadorago - CO",
    "Cadrezzate - VA",
    "Caerano di San Marco - TV",
    "Cafasse - TO",
    "Caggiano - SA",
    "Cagli - PU",
    "Cagliari - CA",
    "Caglio - CO",
    "Cagnano Amiterno - AQ",
    "Cagnano Varano - FG",
    "Cagno - CO",
    "Cagnò - TN",
    "Caianello - CE",
    "Caiazzo - CE",
    "Caines - BZ",
    "Caino - BS",
    "Caiolo - SO",
    "Cairano - AV",
    "Cairate - VA",
    "Cairo Montenotte - SV",
    "Caivano - NA",
    "Calabritto - AV",
    "Calalzo di Cadore - BL",
    "Calamandrana - AT",
    "Calamonaci - AG",
    "Calangianus - OT",
    "Calanna - RC",
    "Calasca-Castiglione - VB",
    "Calascibetta - EN",
    "Calascio - AQ",
    "Calasetta - CI",
    "Calatabiano - CT",
    "Calatafimi Segesta - TP",
    "Calavino - TN",
    "Calcata - VT",
    "Calceranica al Lago - TN",
    "Calci - PI",
    "Calciano - MT",
    "Calcinaia - PI",
    "Calcinate - BG",
    "Calcinato - BS",
    "Calcio - BG",
    "Calco - LC",
    "Caldaro sulla Strada del Vino - BZ",
    "Caldarola - MC",
    "Calderara di Reno - BO",
    "Caldes - TN",
    "Caldiero - VR",
    "Caldogno - VI",
    "Caldonazzo - TN",
    "Calendasco - PC",
    "Calenzano - FI",
    "Calestano - PR",
    "Calice al Cornoviglio - SP",
    "Calice Ligure - SV",
    "Calimera - LE",
    "Calitri - AV",
    "Calizzano - SV",
    "Callabiana - BI",
    "Calliano - AT",
    "Calliano - TN",
    "Calolziocorte - LC",
    "Calopezzati - CS",
    "Calosso - AT",
    "Caloveto - CS",
    "Caltabellotta - AG",
    "Caltagirone - CT",
    "Caltanissetta - CL",
    "Caltavuturo - PA",
    "Caltignaga - NO",
    "Calto - RO",
    "Caltrano - VI",
    "Calusco d'Adda - BG",
    "Caluso - TO",
    "Calvagese della Riviera - BS",
    "Calvanico - SA",
    "Calvatone - CR",
    "Calvello - PZ",
    "Calvene - VI",
    "Calvenzano - BG",
    "Calvera - PZ",
    "Calvi - BN",
    "Calvi dell'Umbria - TR",
    "Calvi Risorta - CE",
    "Calvignano - PV",
    "Calvignasco - MI",
    "Calvisano - BS",
    "Calvizzano - NA",
    "Camagna Monferrato - AL",
    "Camaiore - LU",
    "Camairago - LO",
    "Camandona - BI",
    "Camastra - AG",
    "Cambiago - MI",
    "Cambiano - TO",
    "Cambiasca - VB",
    "Camburzano - BI",
    "Camerana - CN",
    "Camerano - AN",
    "Camerano Casasco - AT",
    "Camerata Cornello - BG",
    "Camerata Nuova - RM",
    "Camerata Picena - AN",
    "Cameri - NO",
    "Camerino - MC",
    "Camerota - SA",
    "Camigliano - CE",
    "Caminata - PC",
    "Camini - RC",
    "Camino - AL",
    "Camino al Tagliamento - UD",
    "Camisano - CR",
    "Camisano Vicentino - VI",
    "Cammarata - AG",
    "Camo - CN",
    "Camogli - GE",
    "Campagna - SA",
    "Campagna Lupia - VE",
    "Campagnano di Roma - RM",
    "Campagnatico - GR",
    "Campagnola Cremasca - CR",
    "Campagnola Emilia - RE",
    "Campana - CS",
    "Camparada - MB",
    "Campegine - RE",
    "Campello sul Clitunno - PG",
    "Campertogno - VC",
    "Campi Bisenzio - FI",
    "Campi Salentina - LE",
    "Campiglia Cervo - BI",
    "Campiglia dei Berici - VI",
    "Campiglia Marittima - LI",
    "Campiglione-Fenile - TO",
    "Campione d'Italia - CO",
    "Campitello di Fassa - TN",
    "Campli - TE",
    "Campo Calabro - RC",
    "Campo di Giove - AQ",
    "Campo di Trens - BZ",
    "Campo Ligure - GE",
    "Campo nell'Elba - LI",
    "Campo San Martino - PD",
    "Campo Tures - BZ",
    "Campobasso - CB",
    "Campobello di Licata - AG",
    "Campobello di Mazara - TP",
    "Campochiaro - CB",
    "Campodarsego - PD",
    "Campodenno - TN",
    "Campodimele - LT",
    "Campodipietra - CB",
    "Campodolcino - SO",
    "Campodoro - PD",
    "Campofelice di Fitalia - PA",
    "Campofelice di Roccella - PA",
    "Campofilone - FM",
    "Campofiorito - PA",
    "Campoformido - UD",
    "Campofranco - CL",
    "Campogalliano - MO",
    "Campolattaro - BN",
    "Campoli Appennino - FR",
    "Campoli del Monte Taburno - BN",
    "Campolieto - CB",
    "Campolongo Maggiore - VE",
    "Campolongo sul Brenta - VI",
    "Campolongo Tapogliano - UD",
    "Campomaggiore - PZ",
    "Campomarino - CB",
    "Campomorone - GE",
    "Camponogara - VE",
    "Campora - SA",
    "Camporeale - PA",
    "Camporgiano - LU",
    "Camporosso - IM",
    "Camporotondo di Fiastrone - MC",
    "Camporotondo Etneo - CT",
    "Camposampiero - PD",
    "Camposano - NA",
    "Camposanto - MO",
    "Campospinoso - PV",
    "Campotosto - AQ",
    "Camugnano - BO",
    "Canal San Bovo - TN",
    "Canale - CN",
    "Canale d'Agordo - BL",
    "Canale Monterano - RM",
    "Canaro - RO",
    "Canazei - TN",
    "Cancellara - PZ",
    "Cancello ed Arnone - CE",
    "Canda - RO",
    "Candela - FG",
    "Candelo - BI",
    "Candia Canavese - TO",
    "Candia Lomellina - PV",
    "Candiana - PD",
    "Candida - AV",
    "Candidoni - RC",
    "Candiolo - TO",
    "Canegrate - MI",
    "Canelli - AT",
    "Canepina - VT",
    "Caneva - PN",
    "Canevino - PV",
    "Canicattì - AG",
    "Canicattini Bagni - SR",
    "Canino - VT",
    "Canischio - TO",
    "Canistro - AQ",
    "Canna - CS",
    "Cannalonga - SA",
    "Cannara - PG",
    "Cannero Riviera - VB",
    "Canneto Pavese - PV",
    "Canneto sull'Oglio - MN",
    "Cannobio - VB",
    "Cannole - LE",
    "Canolo - RC",
    "Canonica d'Adda - BG",
    "Canosa di Puglia - BT",
    "Canosa Sannita - CH",
    "Canosio - CN",
    "Canossa - RE",
    "Cansano - AQ",
    "Cantagallo - PO",
    "Cantalice - RI",
    "Cantalupa - TO",
    "Cantalupo in Sabina - RI",
    "Cantalupo Ligure - AL",
    "Cantalupo nel Sannio - IS",
    "Cantarana - AT",
    "Cantello - VA",
    "Canterano - RM",
    "Cantiano - PU",
    "Cantoira - TO",
    "Cantù - CO",
    "Canzano - TE",
    "Canzo - CO",
    "Caorle - VE",
    "Caorso - PC",
    "Capaccio - SA",
    "Capaci - PA",
    "Capalbio - GR",
    "Capannoli - PI",
    "Capannori - LU",
    "Capena - RM",
    "Capergnanica - CR",
    "Capestrano - AQ",
    "Capiago Intimiano - CO",
    "Capistrano - VV",
    "Capistrello - AQ",
    "Capitignano - AQ",
    "Capizzi - ME",
    "Capizzone - BG",
    "Capo di Ponte - BS",
    "Capo d'Orlando - ME",
    "Capodimonte - VT",
    "Capodrise - CE",
    "Capoliveri - LI",
    "Capolona - AR",
    "Caponago - MB",
    "Caporciano - AQ",
    "Caposele - AV",
    "Capoterra - CA",
    "Capovalle - BS",
    "Cappadocia - AQ",
    "Cappella Cantone - CR",
    "Cappella de' Picenardi - CR",
    "Cappella Maggiore - TV",
    "Cappelle sul Tavo - PE",
    "Capracotta - IS",
    "Capraia e Limite - FI",
    "Capraia Isola - LI",
    "Capralba - CR",
    "Capranica - VT",
    "Capranica Prenestina - RM",
    "Caprarica di Lecce - LE",
    "Caprarola - VT",
    "Caprauna - CN",
    "Caprese Michelangelo - AR",
    "Caprezzo - VB",
    "Capri - NA",
    "Capri Leone - ME",
    "Capriana - TN",
    "Capriano del Colle - BS",
    "Capriata d'Orba - AL",
    "Capriate San Gervasio - BG",
    "Capriati a Volturno - CE",
    "Caprie - TO",
    "Capriglia Irpina - AV",
    "Capriglio - AT",
    "Caprile - BI",
    "Caprino Bergamasco - BG",
    "Caprino Veronese - VR",
    "Capriolo - BS",
    "Capriva del Friuli - GO",
    "Capua - CE",
    "Capurso - BA",
    "Caraffa del Bianco - RC",
    "Caraffa di Catanzaro - CZ",
    "Caraglio - CN",
    "Caramagna Piemonte - CN",
    "Caramanico Terme - PE",
    "Carano - TN",
    "Carapelle - FG",
    "Carapelle Calvisio - AQ",
    "Carasco - GE",
    "Carassai - AP",
    "Carate Brianza - MB",
    "Carate Urio - CO",
    "Caravaggio - BG",
    "Caravate - VA",
    "Caravino - TO",
    "Caravonica - IM",
    "Carbognano - VT",
    "Carbonara al Ticino - PV",
    "Carbonara di Nola - NA",
    "Carbonara di Po - MN",
    "Carbonara Scrivia - AL",
    "Carbonate - CO",
    "Carbone - PZ",
    "Carbonera - TV",
    "Carbonia - CI",
    "Carcare - SV",
    "Carceri - PD",
    "Carcoforo - VC",
    "Cardano al Campo - VA",
    "Cardè - CN",
    "Cardedu - OG",
    "Cardeto - RC",
    "Cardinale - CZ",
    "Cardito - NA",
    "Careggine - LU",
    "Carema - TO",
    "Carenno - LC",
    "Carentino - AL",
    "Careri - RC",
    "Caresana - VC",
    "Caresanablot - VC",
    "Carezzano - AL",
    "Carfizzi - KR",
    "Cargeghe - SS",
    "Cariati - CS",
    "Carife - AV",
    "Carignano - TO",
    "Carimate - CO",
    "Carinaro - CE",
    "Carini - PA",
    "Carinola - CE",
    "Carisio - VC",
    "Carisolo - TN",
    "Carlantino - FG",
    "Carlazzo - CO",
    "Carlentini - SR",
    "Carlino - UD",
    "Carloforte - CI",
    "Carlopoli - CZ",
    "Carmagnola - TO",
    "Carmiano - LE",
    "Carmignano - PO",
    "Carmignano di Brenta - PD",
    "Carnago - VA",
    "Carnate - MB",
    "Carobbio degli Angeli - BG",
    "Carolei - CS",
    "Carona - BG",
    "Caronia - ME",
    "Caronno Pertusella - VA",
    "Caronno Varesino - VA",
    "Carosino - TA",
    "Carovigno - BR",
    "Carovilli - IS",
    "Carpaneto Piacentino - PC",
    "Carpanzano - CS",
    "Carpasio - IM",
    "Carpegna - PU",
    "Carpenedolo - BS",
    "Carpeneto - AL",
    "Carpi - MO",
    "Carpiano - MI",
    "Carpignano Salentino - LE",
    "Carpignano Sesia - NO",
    "Carpineti - RE",
    "Carpineto della Nora - PE",
    "Carpineto Romano - RM",
    "Carpineto Sinello - CH",
    "Carpino - FG",
    "Carpinone - IS",
    "Carrara - MS",
    "Carrè - VI",
    "Carrega Ligure - AL",
    "Carro - SP",
    "Carrodano - SP",
    "Carrosio - AL",
    "Carrù - CN",
    "Carsoli - AQ",
    "Cartigliano - VI",
    "Cartignano - CN",
    "Cartoceto - PU",
    "Cartosio - AL",
    "Cartura - PD",
    "Carugate - MI",
    "Carugo - CO",
    "Carunchio - CH",
    "Carvico - BG",
    "Carzano - TN",
    "Casabona - KR",
    "Casacalenda - CB",
    "Casacanditella - CH",
    "Casagiove - CE",
    "Casal Cermelli - AL",
    "Casal di Principe - CE",
    "Casal Velino - SA",
    "Casalanguida - CH",
    "Casalattico - FR",
    "Casalbeltrame - NO",
    "Casalbordino - CH",
    "Casalbore - AV",
    "Casalborgone - TO",
    "Casalbuono - SA",
    "Casalbuttano ed Uniti - CR",
    "Casalciprano - CB",
    "Casalduni - BN",
    "Casale Corte Cerro - VB",
    "Casale Cremasco-Vidolasco - CR",
    "Casale di Scodosia - PD",
    "Casale Litta - VA",
    "Casale Marittimo - PI",
    "Casale Monferrato - AL",
    "Casale sul Sile - TV",
    "Casalecchio di Reno - BO",
    "Casaleggio Boiro - AL",
    "Casaleggio Novara - NO",
    "Casaleone - VR",
    "Casaletto Ceredano - CR",
    "Casaletto di Sopra - CR",
    "Casaletto Lodigiano - LO",
    "Casaletto Spartano - SA",
    "Casaletto Vaprio - CR",
    "Casalfiumanese - BO",
    "Casalgrande - RE",
    "Casalgrasso - CN",
    "Casalincontrada - CH",
    "Casalino - NO",
    "Casalmaggiore - CR",
    "Casalmaiocco - LO",
    "Casalmorano - CR",
    "Casalmoro - MN",
    "Casalnoceto - AL",
    "Casalnuovo di Napoli - NA",
    "Casalnuovo Monterotaro - FG",
    "Casaloldo - MN",
    "Casalpusterlengo - LO",
    "Casalromano - MN",
    "Casalserugo - PD",
    "Casaluce - CE",
    "Casalvecchio di Puglia - FG",
    "Casalvecchio Siculo - ME",
    "Casalvieri - FR",
    "Casalvolone - NO",
    "Casalzuigno - VA",
    "Casamarciano - NA",
    "Casamassima - BA",
    "Casamicciola Terme - NA",
    "Casandrino - NA",
    "Casanova Elvo - VC",
    "Casanova Lerrone - SV",
    "Casanova Lonati - PV",
    "Casape - RM",
    "Casapesenna - CE",
    "Casapinta - BI",
    "Casaprota - RI",
    "Casapulla - CE",
    "Casarano - LE",
    "Casargo - LC",
    "Casarile - MI",
    "Casarsa della Delizia - PN",
    "Casarza Ligure - GE",
    "Casasco - AL",
    "Casasco d'Intelvi - CO",
    "Casatenovo - LC",
    "Casatisma - PV",
    "Casavatore - NA",
    "Casazza - BG",
    "Cascia - PG",
    "Casciago - VA",
    "Casciana Terme - PI",
    "Cascina - PI",
    "Cascinette d'Ivrea - TO",
    "Casei Gerola - PV",
    "Caselette - TO",
    "Casella - GE",
    "Caselle in Pittari - SA",
    "Caselle Landi - LO",
    "Caselle Lurani - LO",
    "Caselle Torinese - TO",
    "Caserta - CE",
    "Casier - TV",
    "Casignana - RC",
    "Casina - RE",
    "Casirate d'Adda - BG",
    "Caslino d'Erba - CO",
    "Casnate con Bernate - CO",
    "Casnigo - BG",
    "Casola di Napoli - NA",
    "Casola in Lunigiana - MS",
    "Casola Valsenio - RA",
    "Casole Bruzio - CS",
    "Casole d'Elsa - SI",
    "Casoli - CH",
    "Casorate Primo - PV",
    "Casorate Sempione - VA",
    "Casorezzo - MI",
    "Casoria - NA",
    "Casorzo - AT",
    "Casperia - RI",
    "Caspoggio - SO",
    "Cassacco - UD",
    "Cassago Brianza - LC",
    "Cassano all'Ionio - CS",
    "Cassano d'Adda - MI",
    "Cassano delle Murge - BA",
    "Cassano Irpino - AV",
    "Cassano Magnago - VA",
    "Cassano Spinola - AL",
    "Cassano Valcuvia - VA",
    "Cassaro - SR",
    "Cassiglio - BG",
    "Cassina de' Pecchi - MI",
    "Cassina Rizzardi - CO",
    "Cassina Valsassina - LC",
    "Cassinasco - AT",
    "Cassine - AL",
    "Cassinelle - AL",
    "Cassinetta di Lugagnano - MI",
    "Cassino - FR",
    "Cassola - VI",
    "Cassolnovo - PV",
    "Castagnaro - VR",
    "Castagneto Carducci - LI",
    "Castagneto Po - TO",
    "Castagnito - CN",
    "Castagnole delle Lanze - AT",
    "Castagnole Monferrato - AT",
    "Castagnole Piemonte - TO",
    "Castana - PV",
    "Castano Primo - MI",
    "Casteggio - PV",
    "Castegnato - BS",
    "Castegnero - VI",
    "Castel Baronia - AV",
    "Castel Boglione - AT",
    "Castel Bolognese - RA",
    "Castel Campagnano - CE",
    "Castel Castagna - TE",
    "Castel Colonna - AN",
    "Castel Condino - TN",
    "Castel d'Aiano - BO",
    "Castel d'Ario - MN",
    "Castel d'Azzano - VR",
    "Castel del Giudice - IS",
    "Castel del Monte - AQ",
    "Castel del Piano - GR",
    "Castel del Rio - BO",
    "Castel di Casio - BO",
    "Castel di Ieri - AQ",
    "Castel di Iudica - CT",
    "Castel di Lama - AP",
    "Castel di Lucio - ME",
    "Castel di Sangro - AQ",
    "Castel di Sasso - CE",
    "Castel di Tora - RI",
    "Castel Focognano - AR",
    "Castel Frentano - CH",
    "Castel Gabbiano - CR",
    "Castel Gandolfo - RM",
    "Castel Giorgio - TR",
    "Castel Goffredo - MN",
    "Castel Guelfo di Bologna - BO",
    "Castel Madama - RM",
    "Castel Maggiore - BO",
    "Castel Mella - BS",
    "Castel Morrone - CE",
    "Castel Ritaldi - PG",
    "Castel Rocchero - AT",
    "Castel Rozzone - BG",
    "Castel San Giorgio - SA",
    "Castel San Giovanni - PC",
    "Castel San Lorenzo - SA",
    "Castel San Niccolò - AR",
    "Castel San Pietro Romano - RM",
    "Castel San Pietro Terme - BO",
    "Castel San Vincenzo - IS",
    "Castel Sant'Angelo - RI",
    "Castel Sant'Elia - VT",
    "Castel Viscardo - TR",
    "Castel Vittorio - IM",
    "Castel Volturno - CE",
    "Castelbaldo - PD",
    "Castelbelforte - MN",
    "Castelbellino - AN",
    "Castelbello-Ciardes - BZ",
    "Castelbianco - SV",
    "Castelbottaccio - CB",
    "Castelbuono - PA",
    "Castelcivita - SA",
    "Castelcovati - BS",
    "Castelcucco - TV",
    "Casteldaccia - PA",
    "Casteldelci - RN",
    "Casteldelfino - CN",
    "Casteldidone - CR",
    "Castelfidardo - AN",
    "Castelfiorentino - FI",
    "Castelfondo - TN",
    "Castelforte - LT",
    "Castelfranci - AV",
    "Castelfranco di Sopra - AR",
    "Castelfranco di Sotto - PI",
    "Castelfranco Emilia - MO",
    "Castelfranco in Miscano - BN",
    "Castelfranco Veneto - TV",
    "Castelgomberto - VI",
    "Castelgrande - PZ",
    "Castelguglielmo - RO",
    "Castelguidone - CH",
    "Castellabate - SA",
    "Castellafiume - AQ",
    "Castell'Alfero - AT",
    "Castellalto - TE",
    "Castellammare del Golfo - TP",
    "Castellammare di Stabia - NA",
    "Castellamonte - TO",
    "Castellana Grotte - BA",
    "Castellana Sicula - PA",
    "Castellaneta - TA",
    "Castellania - AL",
    "Castellanza - VA",
    "Castellar - CN",
    "Castellar Guidobono - AL",
    "Castellarano - RE",
    "Castellaro - IM",
    "Castell'Arquato - PC",
    "Castellavazzo - BL",
    "Castell'Azzara - GR",
    "Castellazzo Bormida - AL",
    "Castellazzo Novarese - NO",
    "Castelleone - CR",
    "Castelleone di Suasa - AN",
    "Castellero - AT",
    "Castelletto Cervo - BI",
    "Castelletto d'Erro - AL",
    "Castelletto di Branduzzo - PV",
    "Castelletto d'Orba - AL",
    "Castelletto Merli - AL",
    "Castelletto Molina - AT",
    "Castelletto Monferrato - AL",
    "Castelletto Sopra Ticino - NO",
    "Castelletto Stura - CN",
    "Castelletto Uzzone - CN",
    "Castelli - TE",
    "Castelli Calepio - BG",
    "Castellina in Chianti - SI",
    "Castellina Marittima - PI",
    "Castellinaldo - CN",
    "Castellino del Biferno - CB",
    "Castellino Tanaro - CN",
    "Castelliri - FR",
    "Castello Cabiaglio - VA",
    "Castello d'Agogna - PV",
    "Castello d'Argile - BO",
    "Castello del Matese - CE",
    "Castello dell'Acqua - SO",
    "Castello di Annone - AT",
    "Castello di Brianza - LC",
    "Castello di Cisterna - NA",
    "Castello di Godego - TV",
    "Castello di Serravalle - BO",
    "Castello Tesino - TN",
    "Castello-Molina di Fiemme - TN",
    "Castellucchio - MN",
    "Castelluccio dei Sauri - FG",
    "Castelluccio Inferiore - PZ",
    "Castelluccio Superiore - PZ",
    "Castelluccio Valmaggiore - FG",
    "Castell'Umberto - ME",
    "Castelmagno - CN",
    "Castelmarte - CO",
    "Castelmassa - RO",
    "Castelmauro - CB",
    "Castelmezzano - PZ",
    "Castelmola - ME",
    "Castelnovetto - PV",
    "Castelnovo Bariano - RO",
    "Castelnovo del Friuli - PN",
    "Castelnovo di Sotto - RE",
    "Castelnovo ne' Monti - RE",
    "Castelnuovo - TN",
    "Castelnuovo Belbo - AT",
    "Castelnuovo Berardenga - SI",
    "Castelnuovo Bocca d'Adda - LO",
    "Castelnuovo Bormida - AL",
    "Castelnuovo Bozzente - CO",
    "Castelnuovo Calcea - AT",
    "Castelnuovo Cilento - SA",
    "Castelnuovo del Garda - VR",
    "Castelnuovo della Daunia - FG",
    "Castelnuovo di Ceva - CN",
    "Castelnuovo di Conza - SA",
    "Castelnuovo di Farfa - RI",
    "Castelnuovo di Garfagnana - LU",
    "Castelnuovo di Porto - RM",
    "Castelnuovo di Val di Cecina - PI",
    "Castelnuovo Don Bosco - AT",
    "Castelnuovo Magra - SP",
    "Castelnuovo Nigra - TO",
    "Castelnuovo Parano - FR",
    "Castelnuovo Rangone - MO",
    "Castelnuovo Scrivia - AL",
    "Castelpagano - BN",
    "Castelpetroso - IS",
    "Castelpizzuto - IS",
    "Castelplanio - AN",
    "Castelpoto - BN",
    "Castelraimondo - MC",
    "Castelrotto - BZ",
    "Castelsantangelo sul Nera - MC",
    "Castelsaraceno - PZ",
    "Castelsardo - SS",
    "Castelseprio - VA",
    "Castelsilano - KR",
    "Castelspina - AL",
    "Casteltermini - AG",
    "Castelveccana - VA",
    "Castelvecchio Calvisio - AQ",
    "Castelvecchio di Rocca Barbena - SV",
    "Castelvecchio Subequo - AQ",
    "Castelvenere - BN",
    "Castelverde - CR",
    "Castelverrino - IS",
    "Castelvetere in Val Fortore - BN",
    "Castelvetere sul Calore - AV",
    "Castelvetrano - TP",
    "Castelvetro di Modena - MO",
    "Castelvetro Piacentino - PC",
    "Castelvisconti - CR",
    "Castenaso - BO",
    "Castenedolo - BS",
    "Castiadas - CA",
    "Castiglion Fibocchi - AR",
    "Castiglion Fiorentino - AR",
    "Castiglione a Casauria - PE",
    "Castiglione Chiavarese - GE",
    "Castiglione Cosentino - CS",
    "Castiglione d'Adda - LO",
    "Castiglione dei Pepoli - BO",
    "Castiglione del Genovesi - SA",
    "Castiglione del Lago - PG",
    "Castiglione della Pescaia - GR",
    "Castiglione delle Stiviere - MN",
    "Castiglione di Garfagnana - LU",
    "Castiglione di Sicilia - CT",
    "Castiglione d'Intelvi - CO",
    "Castiglione d'Orcia - SI",
    "Castiglione Falletto - CN",
    "Castiglione in Teverina - VT",
    "Castiglione Messer Marino - CH",
    "Castiglione Messer Raimondo - TE",
    "Castiglione Olona - VA",
    "Castiglione Tinella - CN",
    "Castiglione Torinese - TO",
    "Castignano - AP",
    "Castilenti - TE",
    "Castino - CN",
    "Castione Andevenno - SO",
    "Castione della Presolana - BG",
    "Castions di Strada - UD",
    "Castiraga Vidardo - LO",
    "Casto - BS",
    "Castorano - AP",
    "Castrezzato - BS",
    "Castri di Lecce - LE",
    "Castrignano de' Greci - LE",
    "Castrignano del Capo - LE",
    "Castro - BG",
    "Castro - LE",
    "Castro dei Volsci - FR",
    "Castrocaro Terme e Terra del Sole - FC",
    "Castrocielo - FR",
    "Castrofilippo - AG",
    "Castrolibero - CS",
    "Castronno - VA",
    "Castronovo di Sicilia - PA",
    "Castronuovo di Sant'Andrea - PZ",
    "Castropignano - CB",
    "Castroreale - ME",
    "Castroregio - CS",
    "Castrovillari - CS",
    "Catania - CT",
    "Catanzaro - CZ",
    "Catenanuova - EN",
    "Catignano - PE",
    "Cattolica - RN",
    "Cattolica Eraclea - AG",
    "Caulonia - RC",
    "Cautano - BN",
    "Cava de' Tirreni - SA",
    "Cava Manara - PV",
    "Cavacurta - LO",
    "Cavaglià - BI",
    "Cavaglietto - NO",
    "Cavaglio d'Agogna - NO",
    "Cavaglio-Spoccia - VB",
    "Cavagnolo - TO",
    "Cavaion Veronese - VR",
    "Cavalese - TN",
    "Cavallasca - CO",
    "Cavallerleone - CN",
    "Cavallermaggiore - CN",
    "Cavallino - LE",
    "Cavallino-Treporti - VE",
    "Cavallirio - NO",
    "Cavareno - TN",
    "Cavargna - CO",
    "Cavaria con Premezzo - VA",
    "Cavarzere - VE",
    "Cavaso del Tomba - TV",
    "Cavasso Nuovo - PN",
    "Cavatore - AL",
    "Cavazzo Carnico - UD",
    "Cave - RM",
    "Cavedago - TN",
    "Cavedine - TN",
    "Cavenago d'Adda - LO",
    "Cavenago di Brianza - MB",
    "Cavernago - BG",
    "Cavezzo - MO",
    "Cavizzana - TN",
    "Cavour - TO",
    "Cavriago - RE",
    "Cavriana - MN",
    "Cavriglia - AR",
    "Cazzago Brabbia - VA",
    "Cazzago San Martino - BS",
    "Cazzano di Tramigna - VR",
    "Cazzano Sant'Andrea - BG",
    "Ceccano - FR",
    "Cecima - PV",
    "Cecina - LI",
    "Cedegolo - BS",
    "Cedrasco - SO",
    "Cefalà Diana - PA",
    "Cefalù - PA",
    "Ceggia - VE",
    "Ceglie Messapica - BR",
    "Celano - AQ",
    "Celenza sul Trigno - CH",
    "Celenza Valfortore - FG",
    "Celico - CS",
    "Cella Dati - CR",
    "Cella Monte - AL",
    "Cellamare - BA",
    "Cellara - CS",
    "Cellarengo - AT",
    "Cellatica - BS",
    "Celle di Bulgheria - SA",
    "Celle di Macra - CN",
    "Celle di San Vito - FG",
    "Celle Enomondo - AT",
    "Celle Ligure - SV",
    "Celleno - VT",
    "Cellere - VT",
    "Cellino Attanasio - TE",
    "Cellino San Marco - BR",
    "Cellio - VC",
    "Cellole - CE",
    "Cembra - TN",
    "Cenadi - CZ",
    "Cenate Sopra - BG",
    "Cenate Sotto - BG",
    "Cencenighe Agordino - BL",
    "Cene - BG",
    "Ceneselli - RO",
    "Cengio - SV",
    "Centa San Nicolò - TN",
    "Centallo - CN",
    "Cento - FE",
    "Centola - SA",
    "Centrache - CZ",
    "Centuripe - EN",
    "Cepagatti - PE",
    "Ceppaloni - BN",
    "Ceppo Morelli - VB",
    "Ceprano - FR",
    "Cerami - EN",
    "Ceranesi - GE",
    "Cerano - NO",
    "Cerano d'Intelvi - CO",
    "Ceranova - PV",
    "Ceraso - SA",
    "Cercemaggiore - CB",
    "Cercenasco - TO",
    "Cercepiccola - CB",
    "Cerchiara di Calabria - CS",
    "Cerchio - AQ",
    "Cercino - SO",
    "Cercivento - UD",
    "Cercola - NA",
    "Cerda - PA",
    "Cerea - VR",
    "Ceregnano - RO",
    "Cerenzia - KR",
    "Ceres - TO",
    "Ceresara - MN",
    "Cereseto - AL",
    "Ceresole Alba - CN",
    "Ceresole Reale - TO",
    "Cerete - BG",
    "Ceretto Lomellina - PV",
    "Cergnago - PV",
    "Ceriale - SV",
    "Ceriana - IM",
    "Ceriano Laghetto - MB",
    "Cerignale - PC",
    "Cerignola - FG",
    "Cerisano - CS",
    "Cermenate - CO",
    "Cermes - BZ",
    "Cermignano - TE",
    "Cernobbio - CO",
    "Cernusco Lombardone - LC",
    "Cernusco sul Naviglio - MI",
    "Cerreto Castello - BI",
    "Cerreto d'Asti - AT",
    "Cerreto d'Esi - AN",
    "Cerreto di Spoleto - PG",
    "Cerreto Grue - AL",
    "Cerreto Guidi - FI",
    "Cerreto Laziale - RM",
    "Cerreto Sannita - BN",
    "Cerretto Langhe - CN",
    "Cerrina Monferrato - AL",
    "Cerrione - BI",
    "Cerro al Lambro - MI",
    "Cerro al Volturno - IS",
    "Cerro Maggiore - MI",
    "Cerro Tanaro - AT",
    "Cerro Veronese - VR",
    "Cersosimo - PZ",
    "Certaldo - FI",
    "Certosa di Pavia - PV",
    "Cerva - CZ",
    "Cervara di Roma - RM",
    "Cervarese Santa Croce - PD",
    "Cervaro - FR",
    "Cervasca - CN",
    "Cervatto - VC",
    "Cerveno - BS",
    "Cervere - CN",
    "Cervesina - PV",
    "Cerveteri - RM",
    "Cervia - RA",
    "Cervicati - CS",
    "Cervignano d'Adda - LO",
    "Cervignano del Friuli - UD",
    "Cervinara - AV",
    "Cervino - CE",
    "Cervo - IM",
    "Cerzeto - CS",
    "Cesa - CE",
    "Cesana Brianza - LC",
    "Cesana Torinese - TO",
    "Cesano Boscone - MI",
    "Cesano Maderno - MB",
    "Cesara - VB",
    "Cesarò - ME",
    "Cesate - MI",
    "Cesena - FC",
    "Cesenatico - FC",
    "Cesinali - AV",
    "Cesio - IM",
    "Cesiomaggiore - BL",
    "Cessalto - TV",
    "Cessaniti - VV",
    "Cessapalombo - MC",
    "Cessole - AT",
    "Cetara - SA",
    "Ceto - BS",
    "Cetona - SI",
    "Cetraro - CS",
    "Ceva - CN",
    "Cevo - BS",
    "Challand-Saint-Anselme - AO",
    "Challand-Saint-Victor - AO",
    "Chambave - AO",
    "Chamois - AO",
    "Champdepraz - AO",
    "Champorcher - AO",
    "Charvensod - AO",
    "Chatillon - AO",
    "Cherasco - CN",
    "Cheremule - SS",
    "Chialamberto - TO",
    "Chiampo - VI",
    "Chianche - AV",
    "Chianciano Terme - SI",
    "Chianni - PI",
    "Chianocco - TO",
    "Chiaramonte Gulfi - RG",
    "Chiaramonti - SS",
    "Chiarano - TV",
    "Chiaravalle - AN",
    "Chiaravalle Centrale - CZ",
    "Chiari - BS",
    "Chiaromonte - PZ",
    "Chiauci - IS",
    "Chiavari - GE",
    "Chiavenna - SO",
    "Chiaverano - TO",
    "Chienes - BZ",
    "Chieri - TO",
    "Chies d'Alpago - BL",
    "Chiesa in Valmalenco - SO",
    "Chiesanuova - TO",
    "Chiesina Uzzanese - PT",
    "Chieti - CH",
    "Chieuti - FG",
    "Chieve - CR",
    "Chignolo d'Isola - BG",
    "Chignolo Po - PV",
    "Chioggia - VE",
    "Chiomonte - TO",
    "Chions - PN",
    "Chiopris-Viscone - UD",
    "Chitignano - AR",
    "Chiuduno - BG",
    "Chiuppano - VI",
    "Chiuro - SO",
    "Chiusa - BZ",
    "Chiusa di Pesio - CN",
    "Chiusa di San Michele - TO",
    "Chiusa Sclafani - PA",
    "Chiusaforte - UD",
    "Chiusanico - IM",
    "Chiusano d'Asti - AT",
    "Chiusano di San Domenico - AV",
    "Chiusavecchia - IM",
    "Chiusdino - SI",
    "Chiusi - SI",
    "Chiusi della Verna - AR",
    "Chivasso - TO",
    "Ciampino - RM",
    "Cianciana - AG",
    "Cibiana di Cadore - BL",
    "Cicagna - GE",
    "Cicala - CZ",
    "Cicciano - NA",
    "Cicerale - SA",
    "Ciciliano - RM",
    "Cicognolo - CR",
    "Ciconio - TO",
    "Cigliano - VC",
    "Cigliè - CN",
    "Cigognola - PV",
    "Cigole - BS",
    "Cilavegna - PV",
    "Cimadolmo - TV",
    "Cimbergo - BS",
    "Cimego - TN",
    "Ciminà - RC",
    "Ciminna - PA",
    "Cimitile - NA",
    "Cimolais - PN",
    "Cimone - TN",
    "Cinaglio - AT",
    "Cineto Romano - RM",
    "Cingia de' Botti - CR",
    "Cingoli - MC",
    "Cinigiano - GR",
    "Cinisello Balsamo - MI",
    "Cinisi - PA",
    "Cino - SO",
    "Cinquefrondi - RC",
    "Cintano - TO",
    "Cinte Tesino - TN",
    "Cinto Caomaggiore - VE",
    "Cinto Euganeo - PD",
    "Cinzano - TO",
    "Ciorlano - CE",
    "Cipressa - IM",
    "Circello - BN",
    "Ciriè - TO",
    "Cirigliano - MT",
    "Cirimido - CO",
    "Cirò - KR",
    "Cirò Marina - KR",
    "Cis - TN",
    "Cisano Bergamasco - BG",
    "Cisano sul Neva - SV",
    "Ciserano - BG",
    "Cislago - VA",
    "Cisliano - MI",
    "Cismon del Grappa - VI",
    "Cison di Valmarino - TV",
    "Cissone - CN",
    "Cisterna d'Asti - AT",
    "Cisterna di Latina - LT",
    "Cisternino - BR",
    "Citerna - PG",
    "Città della Pieve - PG",
    "Città di Castello - PG",
    "Città Sant'Angelo - PE",
    "Cittadella - PD",
    "Cittaducale - RI",
    "Cittanova - RC",
    "Cittareale - RI",
    "Cittiglio - VA",
    "Civate - LC",
    "Civenna - CO",
    "Civezza - IM",
    "Civezzano - TN",
    "Civiasco - VC",
    "Cividale del Friuli - UD",
    "Cividate al Piano - BG",
    "Cividate Camuno - BS",
    "Civita - CS",
    "Civita Castellana - VT",
    "Civita d'Antino - AQ",
    "Civitacampomarano - CB",
    "Civitaluparella - CH",
    "Civitanova del Sannio - IS",
    "Civitanova Marche - MC",
    "Civitaquana - PE",
    "Civitavecchia - RM",
    "Civitella Alfedena - AQ",
    "Civitella Casanova - PE",
    "Civitella d'Agliano - VT",
    "Civitella del Tronto - TE",
    "Civitella di Romagna - FC",
    "Civitella in Val di Chiana - AR",
    "Civitella Messer Raimondo - CH",
    "Civitella Paganico - GR",
    "Civitella Roveto - AQ",
    "Civitella San Paolo - RM",
    "Civo - SO",
    "Claino con Osteno - CO",
    "Claut - PN",
    "Clauzetto - PN",
    "Clavesana - CN",
    "Claviere - TO",
    "Cles - TN",
    "Cleto - CS",
    "Clivio - VA",
    "Cloz - TN",
    "Clusone - BG",
    "Coassolo Torinese - TO",
    "Coazze - TO",
    "Coazzolo - AT",
    "Coccaglio - BS",
    "Cocconato - AT",
    "Cocquio-Trevisago - VA",
    "Cocullo - AQ",
    "Codevigo - PD",
    "Codevilla - PV",
    "Codigoro - FE",
    "Codognè - TV",
    "Codogno - LO",
    "Codroipo - UD",
    "Codrongianos - SS",
    "Coggiola - BI",
    "Cogliate - MB",
    "Cogne - AO",
    "Cogoleto - GE",
    "Cogollo del Cengio - VI",
    "Cogorno - GE",
    "Colazza - NO",
    "Colbordolo - PU",
    "Colere - BG",
    "Colfelice - FR",
    "Coli - PC",
    "Colico - LC",
    "Collagna - RE",
    "Collalto Sabino - RI",
    "Collarmele - AQ",
    "Collazzone - PG",
    "Colle Brianza - LC",
    "Colle d'Anchise - CB",
    "Colle di Tora - RI",
    "Colle di Val d'Elsa - SI",
    "Colle San Magno - FR",
    "Colle Sannita - BN",
    "Colle Santa Lucia - BL",
    "Colle Umberto - TV",
    "Collebeato - BS",
    "Collecchio - PR",
    "Collecorvino - PE",
    "Colledara - TE",
    "Colledimacine - CH",
    "Colledimezzo - CH",
    "Colleferro - RM",
    "Collegiove - RI",
    "Collegno - TO",
    "Collelongo - AQ",
    "Collepardo - FR",
    "Collepasso - LE",
    "Collepietro - AQ",
    "Colleretto Castelnuovo - TO",
    "Colleretto Giacosa - TO",
    "Collesalvetti - LI",
    "Collesano - PA",
    "Colletorto - CB",
    "Collevecchio - RI",
    "Colli a Volturno - IS",
    "Colli del Tronto - AP",
    "Colli sul Velino - RI",
    "Colliano - SA",
    "Collinas - VS",
    "Collio - BS",
    "Collobiano - VC",
    "Colloredo di Monte Albano - UD",
    "Colmurano - MC",
    "Colobraro - MT",
    "Cologna Veneta - VR",
    "Cologne - BS",
    "Cologno al Serio - BG",
    "Cologno Monzese - MI",
    "Colognola ai Colli - VR",
    "Colonna - RM",
    "Colonnella - TE",
    "Colonno - CO",
    "Colorina - SO",
    "Colorno - PR",
    "Colosimi - CS",
    "Colturano - MI",
    "Colzate - BG",
    "Comabbio - VA",
    "Comacchio - FE",
    "Comano - MS",
    "Comano Terme - TN",
    "Comazzo - LO",
    "Comeglians - UD",
    "Comelico Superiore - BL",
    "Comerio - VA",
    "Comezzano-Cizzago - BS",
    "Comignago - NO",
    "Comiso - RG",
    "Comitini - AG",
    "Comiziano - NA",
    "Commessaggio - MN",
    "Commezzadura - TN",
    "Como - CO",
    "Compiano - PR",
    "Comun Nuovo - BG",
    "Comunanza - AP",
    "Cona - VE",
    "Conca Casale - IS",
    "Conca dei Marini - SA",
    "Conca della Campania - CE",
    "Concamarise - VR",
    "Concerviano - RI",
    "Concesio - BS",
    "Conco - VI",
    "Concordia Sagittaria - VE",
    "Concordia sulla Secchia - MO",
    "Concorezzo - MB",
    "Condino - TN",
    "Condofuri - RC",
    "Condove - TO",
    "Condrò - ME",
    "Conegliano - TV",
    "Confienza - PV",
    "Configni - RI",
    "Conflenti - CZ",
    "Coniolo - AL",
    "Conselice - RA",
    "Conselve - PD",
    "Contessa Entellina - PA",
    "Contigliano - RI",
    "Contrada - AV",
    "Controguerra - TE",
    "Controne - SA",
    "Contursi Terme - SA",
    "Conversano - BA",
    "Conza della Campania - AV",
    "Conzano - AL",
    "Copertino - LE",
    "Copiano - PV",
    "Copparo - FE",
    "Corana - PV",
    "Corato - BA",
    "Corbara - SA",
    "Corbetta - MI",
    "Corbola - RO",
    "Corchiano - VT",
    "Corciano - PG",
    "Cordenons - PN",
    "Cordignano - TV",
    "Cordovado - PN",
    "Coredo - TN",
    "Coreglia Antelminelli - LU",
    "Coreglia Ligure - GE",
    "Coreno Ausonio - FR",
    "Corfinio - AQ",
    "Cori - LT",
    "Coriano - RN",
    "Corigliano Calabro - CS",
    "Corigliano d'Otranto - LE",
    "Corinaldo - AN",
    "Corio - TO",
    "Corleone - PA",
    "Corleto Monforte - SA",
    "Corleto Perticara - PZ",
    "Cormano - MI",
    "Cormons - GO",
    "Corna Imagna - BG",
    "Cornalba - BG",
    "Cornale - PV",
    "Cornaredo - MI",
    "Cornate d'Adda - MB",
    "Cornedo all'Isarco - BZ",
    "Cornedo Vicentino - VI",
    "Cornegliano Laudense - LO",
    "Corneliano d'Alba - CN",
    "Corniglio - PR",
    "Corno di Rosazzo - UD",
    "Corno Giovine - LO",
    "Cornovecchio - LO",
    "Cornuda - TV",
    "Correggio - RE",
    "Correzzana - MB",
    "Correzzola - PD",
    "Corrido - CO",
    "Corridonia - MC",
    "Corropoli - TE",
    "Corsano - LE",
    "Corsico - MI",
    "Corsione - AT",
    "Cortaccia sulla Strada del Vino - BZ",
    "Cortale - CZ",
    "Cortandone - AT",
    "Cortanze - AT",
    "Cortazzone - AT",
    "Corte Brugnatella - PC",
    "Corte de' Cortesi con Cignone - CR",
    "Corte de' Frati - CR",
    "Corte Franca - BS",
    "Corte Palasio - LO",
    "Cortemaggiore - PC",
    "Cortemilia - CN",
    "Corteno Golgi - BS",
    "Cortenova - LC",
    "Cortenuova - BG",
    "Corteolona - PV",
    "Cortiglione - AT",
    "Cortina d'Ampezzo - BL",
    "Cortina sulla Strada del Vino - BZ",
    "Cortino - TE",
    "Cortona - AR",
    "Corvara - PE",
    "Corvara in Badia - BZ",
    "Corvino San Quirico - PV",
    "Corzano - BS",
    "Coseano - UD",
    "Cosenza - CS",
    "Cosio d'Arroscia - IM",
    "Cosio Valtellino - SO",
    "Cosoleto - RC",
    "Cossano Belbo - CN",
    "Cossano Canavese - TO",
    "Cossato - BI",
    "Cosseria - SV",
    "Cossignano - AP",
    "Cossogno - VB",
    "Cossoine - SS",
    "Cossombrato - AT",
    "Costa de' Nobili - PV",
    "Costa di Mezzate - BG",
    "Costa di Rovigo - RO",
    "Costa Masnaga - LC",
    "Costa Serina - BG",
    "Costa Valle Imagna - BG",
    "Costa Vescovato - AL",
    "Costa Volpino - BG",
    "Costabissara - VI",
    "Costacciaro - PG",
    "Costanzana - VC",
    "Costarainera - IM",
    "Costermano - VR",
    "Costigliole d'Asti - AT",
    "Costigliole Saluzzo - CN",
    "Cotignola - RA",
    "Cotronei - KR",
    "Cottanello - RI",
    "Courmayeur - AO",
    "Covo - BG",
    "Cozzo - PV",
    "Craco - MT",
    "Crandola Valsassina - LC",
    "Cravagliana - VC",
    "Cravanzana - CN",
    "Craveggia - VB",
    "Creazzo - VI",
    "Crecchio - CH",
    "Credaro - BG",
    "Credera Rubbiano - CR",
    "Crema - CR",
    "Cremella - LC",
    "Cremenaga - VA",
    "Cremeno - LC",
    "Cremia - CO",
    "Cremolino - AL",
    "Cremona - CR",
    "Cremosano - CR",
    "Crescentino - VC",
    "Crespadoro - VI",
    "Crespano del Grappa - TV",
    "Crespellano - BO",
    "Crespiatica - LO",
    "Crespina - PI",
    "Crespino - RO",
    "Cressa - NO",
    "Crevacuore - BI",
    "Crevalcore - BO",
    "Crevoladossola - VB",
    "Crispano - NA",
    "Crispiano - TA",
    "Crissolo - CN",
    "Crocefieschi - GE",
    "Crocetta del Montello - TV",
    "Crodo - VB",
    "Crognaleto - TE",
    "Cropalati - CS",
    "Cropani - CZ",
    "Crosa - BI",
    "Crosia - CS",
    "Crosio della Valle - VA",
    "Crotone - KR",
    "Crotta d'Adda - CR",
    "Crova - VC",
    "Croviana - TN",
    "Crucoli - KR",
    "Cuasso al Monte - VA",
    "Cuccaro Monferrato - AL",
    "Cuccaro Vetere - SA",
    "Cucciago - CO",
    "Cuceglio - TO",
    "Cuggiono - MI",
    "Cugliate-Fabiasco - VA",
    "Cuglieri - OR",
    "Cugnoli - PE",
    "Cumiana - TO",
    "Cumignano sul Naviglio - CR",
    "Cunardo - VA",
    "Cuneo - CN",
    "Cunevo - TN",
    "Cunico - AT",
    "Cuorgnè - TO",
    "Cupello - CH",
    "Cupra Marittima - AP",
    "Cupramontana - AN",
    "Cura Carpignano - PV",
    "Curcuris - OR",
    "Cureggio - NO",
    "Curiglia con Monteviasco - VA",
    "Curinga - CZ",
    "Curino - BI",
    "Curno - BG",
    "Curon Venosta - BZ",
    "Cursi - LE",
    "Cursolo-Orasso - VB",
    "Curtarolo - PD",
    "Curtatone - MN",
    "Curti - CE",
    "Cusago - MI",
    "Cusano Milanino - MI",
    "Cusano Mutri - BN",
    "Cusino - CO",
    "Cusio - BG",
    "Custonaci - TP",
    "Cutigliano - PT",
    "Cutro - KR",
    "Cutrofiano - LE",
    "Cuveglio - VA",
    "Cuvio - VA",
    "Daiano - TN",
    "Dairago - MI",
    "Dalmine - BG",
    "Dambel - TN",
    "Danta di Cadore - BL",
    "Daone - TN",
    "Darè - TN",
    "Darfo Boario Terme - BS",
    "Dasà - VV",
    "Davagna - GE",
    "Daverio - VA",
    "Davoli - CZ",
    "Dazio - SO",
    "Decimomannu - CA",
    "Decimoputzu - CA",
    "Decollatura - CZ",
    "Dego - SV",
    "Deiva Marina - SP",
    "Delebio - SO",
    "Delia - CL",
    "Delianuova - RC",
    "Deliceto - FG",
    "Dello - BS",
    "Demonte - CN",
    "Denice - AL",
    "Denno - TN",
    "Dernice - AL",
    "Derovere - CR",
    "Deruta - PG",
    "Dervio - LC",
    "Desana - VC",
    "Desenzano del Garda - BS",
    "Desio - MB",
    "Desulo - NU",
    "Diamante - CS",
    "Diano Arentino - IM",
    "Diano Castello - IM",
    "Diano d'Alba - CN",
    "Diano Marina - IM",
    "Diano San Pietro - IM",
    "Dicomano - FI",
    "Dignano - UD",
    "Dimaro - TN",
    "Dinami - VV",
    "Dipignano - CS",
    "Diso - LE",
    "Divignano - NO",
    "Dizzasco - CO",
    "Dobbiaco - BZ",
    "Doberdò del Lago - GO",
    "Dogliani - CN",
    "Dogliola - CH",
    "Dogna - UD",
    "Dolcè - VR",
    "Dolceacqua - IM",
    "Dolcedo - IM",
    "Dolegna del Collio - GO",
    "Dolianova - CA",
    "Dolo - VE",
    "Dolzago - LC",
    "Domanico - CS",
    "Domaso - CO",
    "Domegge di Cadore - BL",
    "Domicella - AV",
    "Domodossola - VB",
    "Domus De Maria - CA",
    "Domusnovas - CI",
    "Don - TN",
    "Donato - BI",
    "Dongo - CO",
    "Donnas - AO",
    "Donori - CA",
    "Dorgali - NU",
    "Dorio - LC",
    "Dormelletto - NO",
    "Dorno - PV",
    "Dorsino - TN",
    "Dorzano - BI",
    "Dosolo - MN",
    "Dossena - BG",
    "Dosso del Liro - CO",
    "Doues - AO",
    "Dovadola - FC",
    "Dovera - CR",
    "Dozza - BO",
    "Dragoni - CE",
    "Drapia - VV",
    "Drena - TN",
    "Drenchia - UD",
    "Dresano - MI",
    "Drezzo - CO",
    "Drizzona - CR",
    "Dro - TN",
    "Dronero - CN",
    "Druento - TO",
    "Druogno - VB",
    "Dualchi - NU",
    "Dubino - SO",
    "Due Carrare - PD",
    "Dueville - VI",
    "Dugenta - BN",
    "Duino-Aurisina - TS",
    "Dumenza - VA",
    "Duno - VA",
    "Durazzano - BN",
    "Duronia - CB",
    "Dusino San Michele - AT",
    "Eboli - SA",
    "Edolo - BS",
    "Egna - BZ",
    "Elice - PE",
    "Elini - OG",
    "Ello - LC",
    "Elmas - CA",
    "Elva - CN",
    "Emarèse - AO",
    "Empoli - FI",
    "Endine Gaiano - BG",
    "Enego - VI",
    "Enemonzo - UD",
    "Enna - EN",
    "Entracque - CN",
    "Entratico - BG",
    "Envie - CN",
    "Episcopia - PZ",
    "Eraclea - VE",
    "Erba - CO",
    "Erbè - VR",
    "Erbezzo - VR",
    "Erbusco - BS",
    "Erchie - BR",
    "Ercolano - NA",
    "Erice - TP",
    "Erli - SV",
    "Erto e Casso - PN",
    "Erula - SS",
    "Erve - LC",
    "Esanatoglia - MC",
    "Escalaplano - CA",
    "Escolca - CA",
    "Esine - BS",
    "Esino Lario - LC",
    "Esperia - FR",
    "Esporlatu - SS",
    "Este - PD",
    "Esterzili - CA",
    "Etroubles - AO",
    "Eupilio - CO",
    "Exilles - TO",
    "Fabbrica Curone - AL",
    "Fabbriche di Vallico - LU",
    "Fabbrico - RE",
    "Fabriano - AN",
    "Fabrica di Roma - VT",
    "Fabrizia - VV",
    "Fabro - TR",
    "Faedis - UD",
    "Faedo - TN",
    "Faedo Valtellino - SO",
    "Faenza - RA",
    "Faeto - FG",
    "Fagagna - UD",
    "Faggeto Lario - CO",
    "Faggiano - TA",
    "Fagnano Alto - AQ",
    "Fagnano Castello - CS",
    "Fagnano Olona - VA",
    "Fai della Paganella - TN",
    "Faicchio - BN",
    "Falcade - BL",
    "Falciano del Massico - CE",
    "Falconara Albanese - CS",
    "Falconara Marittima - AN",
    "Falcone - ME",
    "Faleria - VT",
    "Falerna - CZ",
    "Falerone - FM",
    "Fallo - CH",
    "Falmenta - VB",
    "Faloppio - CO",
    "Falvaterra - FR",
    "Falzes - BZ",
    "Fanano - MO",
    "Fanna - PN",
    "Fano - PU",
    "Fano Adriano - TE",
    "Fara Filiorum Petri - CH",
    "Fara Gera d'Adda - BG",
    "Fara in Sabina - RI",
    "Fara Novarese - NO",
    "Fara Olivana con Sola - BG",
    "Fara San Martino - CH",
    "Fara Vicentino - VI",
    "Fardella - PZ",
    "Farigliano - CN",
    "Farindola - PE",
    "Farini - PC",
    "Farnese - VT",
    "Farra d'Alpago - BL",
    "Farra di Soligo - TV",
    "Farra d'Isonzo - GO",
    "Fasano - BR",
    "Fascia - GE",
    "Fauglia - PI",
    "Faule - CN",
    "Favale di Malvaro - GE",
    "Favara - AG",
    "Faver - TN",
    "Favignana - TP",
    "Favria - TO",
    "Feisoglio - CN",
    "Feletto - TO",
    "Felino - PR",
    "Felitto - SA",
    "Felizzano - AL",
    "Felonica - MN",
    "Feltre - BL",
    "Fenegrò - CO",
    "Fenestrelle - TO",
    "Fénis - AO",
    "Ferentillo - TR",
    "Ferentino - FR",
    "Ferla - SR",
    "Fermignano - PU",
    "Fermo - FM",
    "Ferno - VA",
    "Feroleto Antico - CZ",
    "Feroleto della Chiesa - RC",
    "Ferrandina - MT",
    "Ferrara - FE",
    "Ferrara di Monte Baldo - VR",
    "Ferrazzano - CB",
    "Ferrera di Varese - VA",
    "Ferrera Erbognone - PV",
    "Ferrere - AT",
    "Ferriere - PC",
    "Ferruzzano - RC",
    "Fiamignano - RI",
    "Fiano - TO",
    "Fiano Romano - RM",
    "Fiastra - MC",
    "Fiavè - TN",
    "Ficarazzi - PA",
    "Ficarolo - RO",
    "Ficarra - ME",
    "Ficulle - TR",
    "Fidenza - PR",
    "Fiè allo Sciliar - BZ",
    "Fiera di Primiero - TN",
    "Fierozzo - TN",
    "Fiesco - CR",
    "Fiesole - FI",
    "Fiesse - BS",
    "Fiesso d'Artico - VE",
    "Fiesso Umbertiano - RO",
    "Figino Serenza - CO",
    "Figline Valdarno - FI",
    "Figline Vegliaturo - CS",
    "Filacciano - RM",
    "Filadelfia - VV",
    "Filago - BG",
    "Filandari - VV",
    "Filattiera - MS",
    "Filettino - FR",
    "Filetto - CH",
    "Filiano - PZ",
    "Filighera - PV",
    "Filignano - IS",
    "Filogaso - VV",
    "Filottrano - AN",
    "Finale Emilia - MO",
    "Finale Ligure - SV",
    "Fino del Monte - BG",
    "Fino Mornasco - CO",
    "Fiorano al Serio - BG",
    "Fiorano Canavese - TO",
    "Fiorano Modenese - MO",
    "Fiordimonte - MC",
    "Fiorenzuola d'Arda - PC",
    "Firenze - FI",
    "Firenzuola - FI",
    "Firmo - CS",
    "Fisciano - SA",
    "Fiuggi - FR",
    "Fiumalbo - MO",
    "Fiumara - RC",
    "Fiume Veneto - PN",
    "Fiumedinisi - ME",
    "Fiumefreddo Bruzio - CS",
    "Fiumefreddo di Sicilia - CT",
    "Fiumicello - UD",
    "Fiumicino - RM",
    "Fiuminata - MC",
    "Fivizzano - MS",
    "Flaibano - UD",
    "Flavon - TN",
    "Flero - BS",
    "Floresta - ME",
    "Floridia - SR",
    "Florinas - SS",
    "Flumeri - AV",
    "Fluminimaggiore - CI",
    "Flussio - OR",
    "Fobello - VC",
    "Foggia - FG",
    "Foglianise - BN",
    "Fogliano Redipuglia - GO",
    "Foglizzo - TO",
    "Foiano della Chiana - AR",
    "Foiano di Val Fortore - BN",
    "Folgaria - TN",
    "Folignano - AP",
    "Foligno - PG",
    "Follina - TV",
    "Follo - SP",
    "Follonica - GR",
    "Fombio - LO",
    "Fondachelli-Fantina - ME",
    "Fondi - LT",
    "Fondo - TN",
    "Fonni - NU",
    "Fontainemore - AO",
    "Fontana Liri - FR",
    "Fontanafredda - PN",
    "Fontanarosa - AV",
    "Fontanelice - BO",
    "Fontanella - BG",
    "Fontanellato - PR",
    "Fontanelle - TV",
    "Fontaneto d'Agogna - NO",
    "Fontanetto Po - VC",
    "Fontanigorda - GE",
    "Fontanile - AT",
    "Fontaniva - PD",
    "Fonte - TV",
    "Fonte Nuova - RM",
    "Fontecchio - AQ",
    "Fontechiari - FR",
    "Fontegreca - CE",
    "Fonteno - BG",
    "Fontevivo - PR",
    "Fonzaso - BL",
    "Foppolo - BG",
    "Forano - RI",
    "Force - AP",
    "Forchia - BN",
    "Forcola - SO",
    "Fordongianus - OR",
    "Forenza - PZ",
    "Foresto Sparso - BG",
    "Forgaria nel Friuli - UD",
    "Forino - AV",
    "Forio - NA",
    "Forlì - FC",
    "Forlì del Sannio - IS",
    "Forlimpopoli - FC",
    "Formazza - VB",
    "Formello - RM",
    "Formia - LT",
    "Formicola - CE",
    "Formigara - CR",
    "Formigine - MO",
    "Formigliana - VC",
    "Formignana - FE",
    "Fornace - TN",
    "Fornelli - IS",
    "Forni Avoltri - UD",
    "Forni di Sopra - UD",
    "Forni di Sotto - UD",
    "Forno Canavese - TO",
    "Forno di Zoldo - BL",
    "Fornovo di Taro - PR",
    "Fornovo San Giovanni - BG",
    "Forte dei Marmi - LU",
    "Fortezza - BZ",
    "Fortunago - PV",
    "Forza d'Agrò - ME",
    "Fosciandora - LU",
    "Fosdinovo - MS",
    "Fossa - AQ",
    "Fossacesia - CH",
    "Fossalta di Piave - VE",
    "Fossalta di Portogruaro - VE",
    "Fossalto - CB",
    "Fossano - CN",
    "Fossato di Vico - PG",
    "Fossato Serralta - CZ",
    "Fossò - VE",
    "Fossombrone - PU",
    "Foza - VI",
    "Frabosa Soprana - CN",
    "Frabosa Sottana - CN",
    "Fraconalto - AL",
    "Fragagnano - TA",
    "Fragneto L'Abate - BN",
    "Fragneto Monforte - BN",
    "Fraine - CH",
    "Framura - SP",
    "Francavilla al Mare - CH",
    "Francavilla Angitola - VV",
    "Francavilla Bisio - AL",
    "Francavilla d'Ete - FM",
    "Francavilla di Sicilia - ME",
    "Francavilla Fontana - BR",
    "Francavilla in Sinni - PZ",
    "Francavilla Marittima - CS",
    "Francica - VV",
    "Francofonte - SR",
    "Francolise - CE",
    "Frascaro - AL",
    "Frascarolo - PV",
    "Frascati - RM",
    "Frascineto - CS",
    "Frassilongo - TN",
    "Frassinelle Polesine - RO",
    "Frassinello Monferrato - AL",
    "Frassineto Po - AL",
    "Frassinetto - TO",
    "Frassino - CN",
    "Frassinoro - MO",
    "Frasso Sabino - RI",
    "Frasso Telesino - BN",
    "Fratta Polesine - RO",
    "Fratta Todina - PG",
    "Frattamaggiore - NA",
    "Frattaminore - NA",
    "Fratte Rosa - PU",
    "Frazzanò - ME",
    "Fregona - TV",
    "Fresagrandinaria - CH",
    "Fresonara - AL",
    "Frigento - AV",
    "Frignano - CE",
    "Frinco - AT",
    "Frisa - CH",
    "Frisanco - PN",
    "Front - TO",
    "Frontino - PU",
    "Frontone - PU",
    "Frosinone - FR",
    "Frosolone - IS",
    "Frossasco - TO",
    "Frugarolo - AL",
    "Fubine - AL",
    "Fucecchio - FI",
    "Fuipiano Valle Imagna - BG",
    "Fumane - VR",
    "Fumone - FR",
    "Funes - BZ",
    "Furci - CH",
    "Furci Siculo - ME",
    "Furnari - ME",
    "Furore - SA",
    "Furtei - VS",
    "Fuscaldo - CS",
    "Fusignano - RA",
    "Fusine - SO",
    "Futani - SA",
    "Gabbioneta-Binanuova - CR",
    "Gabiano - AL",
    "Gabicce Mare - PU",
    "Gaby - AO",
    "Gadesco-Pieve Delmona - CR",
    "Gadoni - NU",
    "Gaeta - LT",
    "Gaggi - ME",
    "Gaggiano - MI",
    "Gaggio Montano - BO",
    "Gaglianico - BI",
    "Gagliano Aterno - AQ",
    "Gagliano Castelferrato - EN",
    "Gagliano del Capo - LE",
    "Gagliato - CZ",
    "Gagliole - MC",
    "Gaiarine - TV",
    "Gaiba - RO",
    "Gaiola - CN",
    "Gaiole in Chianti - SI",
    "Gairo - OG",
    "Gais - BZ",
    "Galati Mamertino - ME",
    "Galatina - LE",
    "Galatone - LE",
    "Galatro - RC",
    "Galbiate - LC",
    "Galeata - FC",
    "Galgagnano - LO",
    "Gallarate - VA",
    "Gallese - VT",
    "Galliate - NO",
    "Galliate Lombardo - VA",
    "Galliavola - PV",
    "Gallicano - LU",
    "Gallicano nel Lazio - RM",
    "Gallicchio - PZ",
    "Galliera - BO",
    "Galliera Veneta - PD",
    "Gallinaro - FR",
    "Gallio - VI",
    "Gallipoli - LE",
    "Gallo Matese - CE",
    "Gallodoro - ME",
    "Galluccio - CE",
    "Galtellì - NU",
    "Galzignano Terme - PD",
    "Gamalero - AL",
    "Gambara - BS",
    "Gambarana - PV",
    "Gambasca - CN",
    "Gambassi Terme - FI",
    "Gambatesa - CB",
    "Gambellara - VI",
    "Gamberale - CH",
    "Gambettola - FC",
    "Gambolò - PV",
    "Gambugliano - VI",
    "Gandellino - BG",
    "Gandino - BG",
    "Gandosso - BG",
    "Gangi - PA",
    "Garaguso - MT",
    "Garbagna - AL",
    "Garbagna Novarese - NO",
    "Garbagnate Milanese - MI",
    "Garbagnate Monastero - LC",
    "Garda - VR",
    "Gardone Riviera - BS",
    "Gardone Val Trompia - BS",
    "Garessio - CN",
    "Gargallo - NO",
    "Gargazzone - BZ",
    "Gargnano - BS",
    "Garlasco - PV",
    "Garlate - LC",
    "Garlenda - SV",
    "Garniga Terme - TN",
    "Garzeno - CO",
    "Garzigliana - TO",
    "Gasperina - CZ",
    "Gassino Torinese - TO",
    "Gattatico - RE",
    "Gatteo - FC",
    "Gattico - NO",
    "Gattinara - VC",
    "Gavardo - BS",
    "Gavazzana - AL",
    "Gavello - RO",
    "Gaverina Terme - BG",
    "Gavi - AL",
    "Gavignano - RM",
    "Gavirate - VA",
    "Gavoi - NU",
    "Gavorrano - GR",
    "Gazoldo degli Ippoliti - MN",
    "Gazzada Schianno - VA",
    "Gazzaniga - BG",
    "Gazzo - PD",
    "Gazzo Veronese - VR",
    "Gazzola - PC",
    "Gazzuolo - MN",
    "Gela - CL",
    "Gemmano - RN",
    "Gemona del Friuli - UD",
    "Gemonio - VA",
    "Genazzano - RM",
    "Genga - AN",
    "Genivolta - CR",
    "Genola - CN",
    "Genoni - OR",
    "Genova - GE",
    "Genuri - VS",
    "Genzano di Lucania - PZ",
    "Genzano di Roma - RM",
    "Genzone - PV",
    "Gera Lario - CO",
    "Gerace - RC",
    "Geraci Siculo - PA",
    "Gerano - RM",
    "Gerenzago - PV",
    "Gerenzano - VA",
    "Gergei - CA",
    "Germagnano - TO",
    "Germagno - VB",
    "Germignaga - VA",
    "Gerocarne - VV",
    "Gerola Alta - SO",
    "Gerosa - BG",
    "Gerre de' Caprioli - CR",
    "Gesico - CA",
    "Gessate - MI",
    "Gessopalena - CH",
    "Gesturi - VS",
    "Gesualdo - AV",
    "Ghedi - BS",
    "Ghemme - NO",
    "Ghiffa - VB",
    "Ghilarza - OR",
    "Ghisalba - BG",
    "Ghislarengo - VC",
    "Giacciano con Baruchella - RO",
    "Giaglione - TO",
    "Gianico - BS",
    "Giano dell'Umbria - PG",
    "Giano Vetusto - CE",
    "Giardinello - PA",
    "Giardini-Naxos - ME",
    "Giarole - AL",
    "Giarratana - RG",
    "Giarre - CT",
    "Giave - SS",
    "Giaveno - TO",
    "Giavera del Montello - TV",
    "Giba - CI",
    "Gibellina - TP",
    "Gifflenga - BI",
    "Giffone - RC",
    "Giffoni Sei Casali - SA",
    "Giffoni Valle Piana - SA",
    "Gignese - VB",
    "Gignod - AO",
    "Gildone - CB",
    "Gimigliano - CZ",
    "Ginestra - PZ",
    "Ginestra degli Schiavoni - BN",
    "Ginosa - TA",
    "Gioi - SA",
    "Gioia dei Marsi - AQ",
    "Gioia del Colle - BA",
    "Gioia Sannitica - CE",
    "Gioia Tauro - RC",
    "Gioiosa Ionica - RC",
    "Gioiosa Marea - ME",
    "Giove - TR",
    "Giovinazzo - BA",
    "Giovo - TN",
    "Girasole - OG",
    "Girifalco - CZ",
    "Gironico - CO",
    "Gissi - CH",
    "Giuggianello - LE",
    "Giugliano in Campania - NA",
    "Giuliana - PA",
    "Giuliano di Roma - FR",
    "Giuliano Teatino - CH",
    "Giulianova - TE",
    "Giuncugnano - LU",
    "Giungano - SA",
    "Giurdignano - LE",
    "Giussago - PV",
    "Giussano - MB",
    "Giustenice - SV",
    "Giustino - TN",
    "Giusvalla - SV",
    "Givoletto - TO",
    "Gizzeria - CZ",
    "Glorenza - BZ",
    "Godega di Sant'Urbano - TV",
    "Godiasco - PV",
    "Godrano - PA",
    "Goito - MN",
    "Golasecca - VA",
    "Golferenzo - PV",
    "Golfo Aranci - OT",
    "Gombito - CR",
    "Gonars - UD",
    "Goni - CA",
    "Gonnesa - CI",
    "Gonnoscodina - OR",
    "Gonnosfanadiga - VS",
    "Gonnosnò - OR",
    "Gonnostramatza - OR",
    "Gonzaga - MN",
    "Gordona - SO",
    "Gorga - RM",
    "Gorgo al Monticano - TV",
    "Gorgoglione - MT",
    "Gorgonzola - MI",
    "Goriano Sicoli - AQ",
    "Gorizia - GO",
    "Gorla Maggiore - VA",
    "Gorla Minore - VA",
    "Gorlago - BG",
    "Gorle - BG",
    "Gornate-Olona - VA",
    "Gorno - BG",
    "Goro - FE",
    "Gorreto - GE",
    "Gorzegno - CN",
    "Gosaldo - BL",
    "Gossolengo - PC",
    "Gottasecca - CN",
    "Gottolengo - BS",
    "Govone - CN",
    "Gozzano - NO",
    "Gradara - PU",
    "Gradisca d'Isonzo - GO",
    "Grado - GO",
    "Gradoli - VT",
    "Graffignana - LO",
    "Graffignano - VT",
    "Graglia - BI",
    "Gragnano - NA",
    "Gragnano Trebbiense - PC",
    "Grammichele - CT",
    "Grana - AT",
    "Granaglione - BO",
    "Granarolo dell'Emilia - BO",
    "Grancona - VI",
    "Grandate - CO",
    "Grandola ed Uniti - CO",
    "Graniti - ME",
    "Granozzo con Monticello - NO",
    "Grantola - VA",
    "Grantorto - PD",
    "Granze - PD",
    "Grassano - MT",
    "Grassobbio - BG",
    "Gratteri - PA",
    "Grauno - TN",
    "Gravedona ed Uniti - CO",
    "Gravellona Lomellina - PV",
    "Gravellona Toce - VB",
    "Gravere - TO",
    "Gravina di Catania - CT",
    "Gravina in Puglia - BA",
    "Grazzanise - CE",
    "Grazzano Badoglio - AT",
    "Greccio - RI",
    "Greci - AV",
    "Greggio - VC",
    "Gremiasco - AL",
    "Gressan - AO",
    "Gressoney-la-Trinitè - AO",
    "Gressoney-Saint-Jean - AO",
    "Greve in Chianti - FI",
    "Grezzago - MI",
    "Grezzana - VR",
    "Griante - CO",
    "Gricignano di Aversa - CE",
    "Grignasco - NO",
    "Grigno - TN",
    "Grimacco - UD",
    "Grimaldi - CS",
    "Grinzane Cavour - CN",
    "Grisignano di Zocco - VI",
    "Grisolia - CS",
    "Grizzana Morandi - BO",
    "Grognardo - AL",
    "Gromo - BG",
    "Grondona - AL",
    "Grone - BG",
    "Grontardo - CR",
    "Gropello Cairoli - PV",
    "Gropparello - PC",
    "Groscavallo - TO",
    "Grosio - SO",
    "Grosotto - SO",
    "Grosseto - GR",
    "Grosso - TO",
    "Grottaferrata - RM",
    "Grottaglie - TA",
    "Grottaminarda - AV",
    "Grottammare - AP",
    "Grottazzolina - FM",
    "Grotte - AG",
    "Grotte di Castro - VT",
    "Grotteria - RC",
    "Grottole - MT",
    "Grottolella - AV",
    "Gruaro - VE",
    "Grugliasco - TO",
    "Grumello Cremonese ed Uniti - CR",
    "Grumello del Monte - BG",
    "Grumento Nova - PZ",
    "Grumes - TN",
    "Grumo Appula - BA",
    "Grumo Nevano - NA",
    "Grumolo delle Abbadesse - VI",
    "Guagnano - LE",
    "Gualdo - MC",
    "Gualdo Cattaneo - PG",
    "Gualdo Tadino - PG",
    "Gualtieri - RE",
    "Gualtieri Sicaminò - ME",
    "Guamaggiore - CA",
    "Guanzate - CO",
    "Guarcino - FR",
    "Guarda Veneta - RO",
    "Guardabosone - VC",
    "Guardamiglio - LO",
    "Guardavalle - CZ",
    "Guardea - TR",
    "Guardia Lombardi - AV",
    "Guardia Perticara - PZ",
    "Guardia Piemontese - CS",
    "Guardia Sanframondi - BN",
    "Guardiagrele - CH",
    "Guardialfiera - CB",
    "Guardiaregia - CB",
    "Guardistallo - PI",
    "Guarene - CN",
    "Guasila - CA",
    "Guastalla - RE",
    "Guazzora - AL",
    "Gubbio - PG",
    "Gudo Visconti - MI",
    "Guglionesi - CB",
    "Guidizzolo - MN",
    "Guidonia Montecelio - RM",
    "Guiglia - MO",
    "Guilmi - CH",
    "Gurro - VB",
    "Guspini - VS",
    "Gussago - BS",
    "Gussola - CR",
    "Hone - AO",
    "Idro - BS",
    "Iglesias - CI",
    "Igliano - CN",
    "Ilbono - OG",
    "Illasi - VR",
    "Illorai - SS",
    "Imbersago - LC",
    "Imer - TN",
    "Imola - BO",
    "Imperia - IM",
    "Impruneta - FI",
    "Inarzo - VA",
    "Incisa in Val d'Arno - FI",
    "Incisa Scapaccino - AT",
    "Incudine - BS",
    "Induno Olona - VA",
    "Ingria - TO",
    "Intragna - VB",
    "Introbio - LC",
    "Introd - AO",
    "Introdacqua - AQ",
    "Introzzo - LC",
    "Inverigo - CO",
    "Inverno e Monteleone - PV",
    "Inverso Pinasca - TO",
    "Inveruno - MI",
    "Invorio - NO",
    "Inzago - MI",
    "Ionadi - VV",
    "Irgoli - NU",
    "Irma - BS",
    "Irsina - MT",
    "Isasca - CN",
    "Isca sullo Ionio - CZ",
    "Ischia - NA",
    "Ischia di Castro - VT",
    "Ischitella - FG",
    "Iseo - BS",
    "Isera - TN",
    "Isernia - IS",
    "Isili - CA",
    "Isnello - PA",
    "Isola d'Asti - AT",
    "Isola del Cantone - GE",
    "Isola del Giglio - GR",
    "Isola del Gran Sasso d'Italia - TE",
    "Isola del Liri - FR",
    "Isola del Piano - PU",
    "Isola della Scala - VR",
    "Isola delle Femmine - PA",
    "Isola di Capo Rizzuto - KR",
    "Isola di Fondra - BG",
    "Isola Dovarese - CR",
    "Isola Rizza - VR",
    "Isola Sant'Antonio - AL",
    "Isola Vicentina - VI",
    "Isolabella - TO",
    "Isolabona - IM",
    "Isole Tremiti - FG",
    "Isorella - BS",
    "Ispani - SA",
    "Ispica - RG",
    "Ispra - VA",
    "Issiglio - TO",
    "Issime - AO",
    "Isso - BG",
    "Issogne - AO",
    "Istrana - TV",
    "Itala - ME",
    "Itri - LT",
    "Ittireddu - SS",
    "Ittiri - SS",
    "Ivano-Fracena - TN",
    "Ivrea - TO",
    "Izano - CR",
    "Jacurso - CZ",
    "Jelsi - CB",
    "Jenne - RM",
    "Jerago con Orago - VA",
    "Jerzu - OG",
    "Jesi - AN",
    "Jesolo - VE",
    "Jolanda di Savoia - FE",
    "Joppolo - VV",
    "Joppolo Giancaxio - AG",
    "Jovencan - AO",
    "La Cassa - TO",
    "La Loggia - TO",
    "La Maddalena - OT",
    "La Magdeleine - AO",
    "La Morra - CN",
    "La Salle - AO",
    "La Spezia - SP",
    "La Thuile - AO",
    "La Valle - BZ",
    "La Valle Agordina - BL",
    "Labico - RM",
    "Labro - RI",
    "Lacchiarella - MI",
    "Lacco Ameno - NA",
    "Lacedonia - AV",
    "Laces - BZ",
    "Laconi - OR",
    "Ladispoli - RM",
    "Laerru - SS",
    "Laganadi - RC",
    "Laghi - VI",
    "Laglio - CO",
    "Lagnasco - CN",
    "Lago - CS",
    "Lagonegro - PZ",
    "Lagosanto - FE",
    "Lagundo - BZ",
    "Laigueglia - SV",
    "Lainate - MI",
    "Laino - CO",
    "Laino Borgo - CS",
    "Laino Castello - CS",
    "Laion - BZ",
    "Laives - BZ",
    "Lajatico - PI",
    "Lallio - BG",
    "Lama dei Peligni - CH",
    "Lama Mocogno - MO",
    "Lambrugo - CO",
    "Lamezia Terme - CZ",
    "Lamon - BL",
    "Lampedusa e Linosa - AG",
    "Lamporecchio - PT",
    "Lamporo - VC",
    "Lana - BZ",
    "Lanciano - CH",
    "Landiona - NO",
    "Landriano - PV",
    "Langhirano - PR",
    "Langosco - PV",
    "Lanusei - OG",
    "Lanuvio - RM",
    "Lanzada - SO",
    "Lanzo d'Intelvi - CO",
    "Lanzo Torinese - TO",
    "Lapedona - FM",
    "Lapio - AV",
    "Lappano - CS",
    "L'Aquila - AQ",
    "Larciano - PT",
    "Lardaro - TN",
    "Lardirago - PV",
    "Lari - PI",
    "Lariano - RM",
    "Larino - CB",
    "Las Plassas - VS",
    "Lasa - BZ",
    "Lascari - PA",
    "Lasino - TN",
    "Lasnigo - CO",
    "Lastebasse - VI",
    "Lastra a Signa - FI",
    "Latera - VT",
    "Laterina - AR",
    "Laterza - TA",
    "Latiano - BR",
    "Latina - LT",
    "Latisana - UD",
    "Latronico - PZ",
    "Lattarico - CS",
    "Lauco - UD",
    "Laureana Cilento - SA",
    "Laureana di Borrello - RC",
    "Lauregno - BZ",
    "Laurenzana - PZ",
    "Lauria - PZ",
    "Lauriano - TO",
    "Laurino - SA",
    "Laurito - SA",
    "Lauro - AV",
    "Lavagna - GE",
    "Lavagno - VR",
    "Lavarone - TN",
    "Lavello - PZ",
    "Lavena Ponte Tresa - VA",
    "Laveno-Mombello - VA",
    "Lavenone - BS",
    "Laviano - SA",
    "Lavis - TN",
    "Lazise - VR",
    "Lazzate - MB",
    "Lecce - LE",
    "Lecce nei Marsi - AQ",
    "Lecco - LC",
    "Ledro - TN",
    "Leffe - BG",
    "Leggiuno - VA",
    "Legnago - VR",
    "Legnano - MI",
    "Legnaro - PD",
    "Lei - NU",
    "Leini - TO",
    "Leivi - GE",
    "Lemie - TO",
    "Lendinara - RO",
    "Leni - ME",
    "Lenna - BG",
    "Lenno - CO",
    "Leno - BS",
    "Lenola - LT",
    "Lenta - VC",
    "Lentate sul Seveso - MB",
    "Lentella - CH",
    "Lentiai - BL",
    "Lentini - SR",
    "Leonessa - RI",
    "Leonforte - EN",
    "Leporano - TA",
    "Lequile - LE",
    "Lequio Berria - CN",
    "Lequio Tanaro - CN",
    "Lercara Friddi - PA",
    "Lerici - SP",
    "Lerma - AL",
    "Lesa - NO",
    "Lesegno - CN",
    "Lesignano de' Bagni - PR",
    "Lesina - FG",
    "Lesmo - MB",
    "Lessolo - TO",
    "Lessona - BI",
    "Lestizza - UD",
    "Letino - CE",
    "Letojanni - ME",
    "Lettere - NA",
    "Lettomanoppello - PE",
    "Lettopalena - CH",
    "Levanto - SP",
    "Levate - BG",
    "Leverano - LE",
    "Levice - CN",
    "Levico Terme - TN",
    "Levone - TO",
    "Lezzeno - CO",
    "Liberi - CE",
    "Librizzi - ME",
    "Licata - AG",
    "Licciana Nardi - MS",
    "Licenza - RM",
    "Licodia Eubea - CT",
    "Lierna - LC",
    "Lignana - VC",
    "Lignano Sabbiadoro - UD",
    "Ligonchio - RE",
    "Ligosullo - UD",
    "Lillianes - AO",
    "Limana - BL",
    "Limatola - BN",
    "Limbadi - VV",
    "Limbiate - MB",
    "Limena - PD",
    "Limido Comasco - CO",
    "Limina - ME",
    "Limone Piemonte - CN",
    "Limone sul Garda - BS",
    "Limosano - CB",
    "Linarolo - PV",
    "Linguaglossa - CT",
    "Lioni - AV",
    "Lipari - ME",
    "Lipomo - CO",
    "Lirio - PV",
    "Liscate - MI",
    "Liscia - CH",
    "Lisciano Niccone - PG",
    "Lisignago - TN",
    "Lisio - CN",
    "Lissone - MB",
    "Liveri - NA",
    "Livigno - SO",
    "Livinallongo del Col di Lana - BL",
    "Livo - TN",
    "Livo - CO",
    "Livorno - LI",
    "Livorno Ferraris - VC",
    "Livraga - LO",
    "Lizzanello - LE",
    "Lizzano - TA",
    "Lizzano in Belvedere - BO",
    "Loano - SV",
    "Loazzolo - AT",
    "Locana - TO",
    "Locate di Triulzi - MI",
    "Locate Varesino - CO",
    "Locatello - BG",
    "Loceri - OG",
    "Locorotondo - BA",
    "Locri - RC",
    "Loculi - NU",
    "Lodè - NU",
    "Lodi - LO",
    "Lodi Vecchio - LO",
    "Lodine - NU",
    "Lodrino - BS",
    "Lograto - BS",
    "Loiano - BO",
    "Loiri Porto San Paolo - OT",
    "Lomagna - LC",
    "Lomazzo - CO",
    "Lombardore - TO",
    "Lombriasco - TO",
    "Lomello - PV",
    "Lona-Lases - TN",
    "Lonate Ceppino - VA",
    "Lonate Pozzolo - VA",
    "Lonato del Garda - BS",
    "Londa - FI",
    "Longano - IS",
    "Longare - VI",
    "Longarone - BL",
    "Longhena - BS",
    "Longi - ME",
    "Longiano - FC",
    "Longobardi - CS",
    "Longobucco - CS",
    "Longone al Segrino - CO",
    "Longone Sabino - RI",
    "Lonigo - VI",
    "Loranzè - TO",
    "Loreggia - PD",
    "Loreglia - VB",
    "Lorenzago di Cadore - BL",
    "Lorenzana - PI",
    "Loreo - RO",
    "Loreto - AN",
    "Loreto Aprutino - PE",
    "Loria - TV",
    "Loro Ciuffenna - AR",
    "Loro Piceno - MC",
    "Lorsica - GE",
    "Losine - BS",
    "Lotzorai - OG",
    "Lovere - BG",
    "Lovero - SO",
    "Lozio - BS",
    "Lozza - VA",
    "Lozzo Atestino - PD",
    "Lozzo di Cadore - BL",
    "Lozzolo - VC",
    "Lu - AL",
    "Lubriano - VT",
    "Lucca - LU",
    "Lucca Sicula - AG",
    "Lucera - FG",
    "Lucignano - AR",
    "Lucinasco - IM",
    "Lucito - CB",
    "Luco dei Marsi - AQ",
    "Lucoli - AQ",
    "Lugagnano Val D'Arda - PC",
    "Lugnacco - TO",
    "Lugnano in Teverina - TR",
    "Lugo - RA",
    "Lugo di Vicenza - VI",
    "Luino - VA",
    "Luisago - CO",
    "Lula - NU",
    "Lumarzo - GE",
    "Lumezzane - BS",
    "Lunamatrona - VS",
    "Lunano - PU",
    "Lungavilla - PV",
    "Lungro - CS",
    "Luogosano - AV",
    "Luogosanto - OT",
    "Lupara - CB",
    "Lurago d'Erba - CO",
    "Lurago Marinone - CO",
    "Lurano - BG",
    "Luras - OT",
    "Lurate Caccivio - CO",
    "Lusciano - CE",
    "Luserna - TN",
    "Luserna San Giovanni - TO",
    "Lusernetta - TO",
    "Lusevera - UD",
    "Lusia - RO",
    "Lusiana - VI",
    "Lusigliè - TO",
    "Luson - BZ",
    "Lustra - SA",
    "Luvinate - VA",
    "Luzzana - BG",
    "Luzzara - RE",
    "Luzzi - CS",
    "Maccagno - VA",
    "Maccastorna - LO",
    "Macchia d'Isernia - IS",
    "Macchia Valfortore - CB",
    "Macchiagodena - IS",
    "Macello - TO",
    "Macerata - MC",
    "Macerata Campania - CE",
    "Macerata Feltria - PU",
    "Macherio - MB",
    "Maclodio - BS",
    "Macomer - NU",
    "Macra - CN",
    "Macugnaga - VB",
    "Maddaloni - CE",
    "Madesimo - SO",
    "Madignano - CR",
    "Madone - BG",
    "Madonna del Sasso - VB",
    "Maenza - LT",
    "Mafalda - CB",
    "Magasa - BS",
    "Magenta - MI",
    "Maggiora - NO",
    "Magherno - PV",
    "Magione - PG",
    "Magisano - CZ",
    "Magliano Alfieri - CN",
    "Magliano Alpi - CN",
    "Magliano de' Marsi - AQ",
    "Magliano di Tenna - FM",
    "Magliano in Toscana - GR",
    "Magliano Romano - RM",
    "Magliano Sabina - RI",
    "Magliano Vetere - SA",
    "Maglie - LE",
    "Magliolo - SV",
    "Maglione - TO",
    "Magnacavallo - MN",
    "Magnago - MI",
    "Magnano - BI",
    "Magnano in Riviera - UD",
    "Magomadas - OR",
    "Magrè sulla Strada del Vino - BZ",
    "Magreglio - CO",
    "Maida - CZ",
    "Maierà - CS",
    "Maierato - VV",
    "Maiolati Spontini - AN",
    "Maiolo - RN",
    "Maiori - SA",
    "Mairago - LO",
    "Mairano - BS",
    "Maissana - SP",
    "Majano - UD",
    "Malagnino - CR",
    "Malalbergo - BO",
    "Malborghetto Valbruna - UD",
    "Malcesine - VR",
    "Malè - TN",
    "Malegno - BS",
    "Maleo - LO",
    "Malesco - VB",
    "Maletto - CT",
    "Malfa - ME",
    "Malgesso - VA",
    "Malgrate - LC",
    "Malito - CS",
    "Mallare - SV",
    "Malles Venosta - BZ",
    "Malnate - VA",
    "Malo - VI",
    "Malonno - BS",
    "Malosco - TN",
    "Maltignano - AP",
    "Malvagna - ME",
    "Malvicino - AL",
    "Malvito - CS",
    "Mammola - RC",
    "Mamoiada - NU",
    "Manciano - GR",
    "Mandanici - ME",
    "Mandas - CA",
    "Mandatoriccio - CS",
    "Mandela - RM",
    "Mandello del Lario - LC",
    "Mandello Vitta - NO",
    "Manduria - TA",
    "Manerba del Garda - BS",
    "Manerbio - BS",
    "Manfredonia - FG",
    "Mango - CN",
    "Mangone - CS",
    "Maniace - CT",
    "Maniago - PN",
    "Manocalzati - AV",
    "Manoppello - PE",
    "Mansuè - TV",
    "Manta - CN",
    "Mantello - SO",
    "Mantova - MN",
    "Manzano - UD",
    "Manziana - RM",
    "Mapello - BG",
    "Mara - SS",
    "Maracalagonis - CA",
    "Maranello - MO",
    "Marano di Napoli - NA",
    "Marano di Valpolicella - VR",
    "Marano Equo - RM",
    "Marano Lagunare - UD",
    "Marano Marchesato - CS",
    "Marano Principato - CS",
    "Marano sul Panaro - MO",
    "Marano Ticino - NO",
    "Marano Vicentino - VI",
    "Maranzana - AT",
    "Maratea - PZ",
    "Marcallo con Casone - MI",
    "Marcaria - MN",
    "Marcedusa - CZ",
    "Marcellina - RM",
    "Marcellinara - CZ",
    "Marcetelli - RI",
    "Marcheno - BS",
    "Marchirolo - VA",
    "Marciana - LI",
    "Marciana Marina - LI",
    "Marcianise - CE",
    "Marciano della Chiana - AR",
    "Marcignago - PV",
    "Marcon - VE",
    "Marebbe - BZ",
    "Marene - CN",
    "Mareno di Piave - TV",
    "Marentino - TO",
    "Maretto - AT",
    "Margarita - CN",
    "Margherita di Savoia - BT",
    "Margno - LC",
    "Mariana Mantovana - MN",
    "Mariano Comense - CO",
    "Mariano del Friuli - GO",
    "Marianopoli - CL",
    "Mariglianella - NA",
    "Marigliano - NA",
    "Marina di Gioiosa Ionica - RC",
    "Marineo - PA",
    "Marino - RM",
    "Marlengo - BZ",
    "Marliana - PT",
    "Marmentino - BS",
    "Marmirolo - MN",
    "Marmora - CN",
    "Marnate - VA",
    "Marone - BS",
    "Maropati - RC",
    "Marostica - VI",
    "Marradi - FI",
    "Marrubiu - OR",
    "Marsaglia - CN",
    "Marsala - TP",
    "Marsciano - PG",
    "Marsico Nuovo - PZ",
    "Marsicovetere - PZ",
    "Marta - VT",
    "Martano - LE",
    "Martellago - VE",
    "Martello - BZ",
    "Martignacco - UD",
    "Martignana di Po - CR",
    "Martignano - LE",
    "Martina Franca - TA",
    "Martinengo - BG",
    "Martiniana Po - CN",
    "Martinsicuro - TE",
    "Martirano - CZ",
    "Martirano Lombardo - CZ",
    "Martis - SS",
    "Martone - RC",
    "Marudo - LO",
    "Maruggio - TA",
    "Marzabotto - BO",
    "Marzano - PV",
    "Marzano Appio - CE",
    "Marzano di Nola - AV",
    "Marzi - CS",
    "Marzio - VA",
    "Masainas - CI",
    "Masate - MI",
    "Mascali - CT",
    "Mascalucia - CT",
    "Maschito - PZ",
    "Masciago Primo - VA",
    "Maser - TV",
    "Masera - VB",
    "Maserà di Padova - PD",
    "Maserada sul Piave - TV",
    "Masi - PD",
    "Masi Torello - FE",
    "Masio - AL",
    "Maslianico - CO",
    "Mason Vicentino - VI",
    "Masone - GE",
    "Massa - MS",
    "Massa d'Albe - AQ",
    "Massa di Somma - NA",
    "Massa e Cozzile - PT",
    "Massa Fermana - FM",
    "Massa Fiscaglia - FE",
    "Massa Lombarda - RA",
    "Massa Lubrense - NA",
    "Massa Marittima - GR",
    "Massa Martana - PG",
    "Massafra - TA",
    "Massalengo - LO",
    "Massanzago - PD",
    "Massarosa - LU",
    "Massazza - BI",
    "Massello - TO",
    "Masserano - BI",
    "Massignano - AP",
    "Massimeno - TN",
    "Massimino - SV",
    "Massino Visconti - NO",
    "Massiola - VB",
    "Masullas - OR",
    "Matelica - MC",
    "Matera - MT",
    "Mathi - TO",
    "Matino - LE",
    "Matrice - CB",
    "Mattie - TO",
    "Mattinata - FG",
    "Mazara del Vallo - TP",
    "Mazzano - BS",
    "Mazzano Romano - RM",
    "Mazzarino - CL",
    "Mazzarrà Sant'Andrea - ME",
    "Mazzarrone - CT",
    "Mazzè - TO",
    "Mazzin - TN",
    "Mazzo di Valtellina - SO",
    "Meana di Susa - TO",
    "Meana Sardo - NU",
    "Meda - MB",
    "Mede - PV",
    "Medea - GO",
    "Medesano - PR",
    "Medicina - BO",
    "Mediglia - MI",
    "Medolago - BG",
    "Medole - MN",
    "Medolla - MO",
    "Meduna di Livenza - TV",
    "Meduno - PN",
    "Megliadino San Fidenzio - PD",
    "Megliadino San Vitale - PD",
    "Meina - NO",
    "Mel - BL",
    "Melara - RO",
    "Melazzo - AL",
    "Meldola - FC",
    "Mele - GE",
    "Melegnano - MI",
    "Melendugno - LE",
    "Meleti - LO",
    "Melfi - PZ",
    "Melicuccà - RC",
    "Melicucco - RC",
    "Melilli - SR",
    "Melissa - KR",
    "Melissano - LE",
    "Melito di Napoli - NA",
    "Melito di Porto Salvo - RC",
    "Melito Irpino - AV",
    "Melizzano - BN",
    "Melle - CN",
    "Mello - SO",
    "Melpignano - LE",
    "Meltina - BZ",
    "Melzo - MI",
    "Menaggio - CO",
    "Menarola - SO",
    "Menconico - PV",
    "Mendatica - IM",
    "Mendicino - CS",
    "Menfi - AG",
    "Mentana - RM",
    "Meolo - VE",
    "Merana - AL",
    "Merano - BZ",
    "Merate - LC",
    "Mercallo - VA",
    "Mercatello sul Metauro - PU",
    "Mercatino Conca - PU",
    "Mercato San Severino - SA",
    "Mercato Saraceno - FC",
    "Mercenasco - TO",
    "Mercogliano - AV",
    "Mereto di Tomba - UD",
    "Mergo - AN",
    "Mergozzo - VB",
    "Merì - ME",
    "Merlara - PD",
    "Merlino - LO",
    "Merone - CO",
    "Mesagne - BR",
    "Mese - SO",
    "Mesenzana - VA",
    "Mesero - MI",
    "Mesola - FE",
    "Mesoraca - KR",
    "Messina - ME",
    "Mestrino - PD",
    "Meta - NA",
    "Meugliano - TO",
    "Mezzago - MB",
    "Mezzana - TN",
    "Mezzana Bigli - PV",
    "Mezzana Mortigliengo - BI",
    "Mezzana Rabattone - PV",
    "Mezzane di Sotto - VR",
    "Mezzanego - GE",
    "Mezzani - PR",
    "Mezzanino - PV",
    "Mezzano - TN",
    "Mezzegra - CO",
    "Mezzenile - TO",
    "Mezzocorona - TN",
    "Mezzojuso - PA",
    "Mezzoldo - BG",
    "Mezzolombardo - TN",
    "Mezzomerico - NO",
    "Miagliano - BI",
    "Miane - TV",
    "Miasino - NO",
    "Miazzina - VB",
    "Micigliano - RI",
    "Miggiano - LE",
    "Miglianico - CH",
    "Migliarino - FE",
    "Migliaro - FE",
    "Miglierina - CZ",
    "Miglionico - MT",
    "Mignanego - GE",
    "Mignano Monte Lungo - CE",
    "Milano - MI",
    "Milazzo - ME",
    "Milena - CL",
    "Mileto - VV",
    "Milis - OR",
    "Militello in Val di Catania - CT",
    "Militello Rosmarino - ME",
    "Millesimo - SV",
    "Milo - CT",
    "Milzano - BS",
    "Mineo - CT",
    "Minerbe - VR",
    "Minerbio - BO",
    "Minervino di Lecce - LE",
    "Minervino Murge - BT",
    "Minori - SA",
    "Minturno - LT",
    "Minucciano - LU",
    "Mioglia - SV",
    "Mira - VE",
    "Mirabella Eclano - AV",
    "Mirabella Imbaccari - CT",
    "Mirabello - FE",
    "Mirabello Monferrato - AL",
    "Mirabello Sannitico - CB",
    "Miradolo Terme - PV",
    "Miranda - IS",
    "Mirandola - MO",
    "Mirano - VE",
    "Mirto - ME",
    "Misano Adriatico - RN",
    "Misano di Gera d'Adda - BG",
    "Misilmeri - PA",
    "Misinto - MB",
    "Missaglia - LC",
    "Missanello - PZ",
    "Misterbianco - CT",
    "Mistretta - ME",
    "Moasca - AT",
    "Moconesi - GE",
    "Modena - MO",
    "Modica - RG",
    "Modigliana - FC",
    "Modolo - OR",
    "Modugno - BA",
    "Moena - TN",
    "Moggio - LC",
    "Moggio Udinese - UD",
    "Moglia - MN",
    "Mogliano - MC",
    "Mogliano Veneto - TV",
    "Mogorella - OR",
    "Mogoro - OR",
    "Moiano - BN",
    "Moimacco - UD",
    "Moio Alcantara - ME",
    "Moio de' Calvi - BG",
    "Moio della Civitella - SA",
    "Moiola - CN",
    "Mola di Bari - BA",
    "Molare - AL",
    "Molazzana - LU",
    "Molfetta - BA",
    "Molina Aterno - AQ",
    "Molinara - BN",
    "Molinella - BO",
    "Molini di Triora - IM",
    "Molino dei Torti - AL",
    "Molise - CB",
    "Moliterno - PZ",
    "Mollia - VC",
    "Molochio - RC",
    "Molteno - LC",
    "Moltrasio - CO",
    "Molvena - VI",
    "Molveno - TN",
    "Mombaldone - AT",
    "Mombarcaro - CN",
    "Mombaroccio - PU",
    "Mombaruzzo - AT",
    "Mombasiglio - CN",
    "Mombello di Torino - TO",
    "Mombello Monferrato - AL",
    "Mombercelli - AT",
    "Momo - NO",
    "Mompantero - TO",
    "Mompeo - RI",
    "Momperone - AL",
    "Monacilioni - CB",
    "Monale - AT",
    "Monasterace - RC",
    "Monastero Bormida - AT",
    "Monastero di Lanzo - TO",
    "Monastero di Vasco - CN",
    "Monasterolo Casotto - CN",
    "Monasterolo del Castello - BG",
    "Monasterolo di Savigliano - CN",
    "Monastier di Treviso - TV",
    "Monastir - CA",
    "Moncalieri - TO",
    "Moncalvo - AT",
    "Moncenisio - TO",
    "Moncestino - AL",
    "Monchiero - CN",
    "Monchio delle Corti - PR",
    "Monclassico - TN",
    "Moncrivello - VC",
    "Moncucco Torinese - AT",
    "Mondaino - RN",
    "Mondavio - PU",
    "Mondolfo - PU",
    "Mondovì - CN",
    "Mondragone - CE",
    "Moneglia - GE",
    "Monesiglio - CN",
    "Monfalcone - GO",
    "Monforte d'Alba - CN",
    "Monforte San Giorgio - ME",
    "Monfumo - TV",
    "Mongardino - AT",
    "Monghidoro - BO",
    "Mongiana - VV",
    "Mongiardino Ligure - AL",
    "Mongiuffi Melia - ME",
    "Mongrando - BI",
    "Mongrassano - CS",
    "Monguelfo-Tesido - BZ",
    "Monguzzo - CO",
    "Moniga del Garda - BS",
    "Monleale - AL",
    "Monno - BS",
    "Monopoli - BA",
    "Monreale - PA",
    "Monrupino - TS",
    "Monsampietro Morico - FM",
    "Monsampolo del Tronto - AP",
    "Monsano - AN",
    "Monselice - PD",
    "Monserrato - CA",
    "Monsummano Terme - PT",
    "Montà - CN",
    "Montabone - AT",
    "Montacuto - AL",
    "Montafia - AT",
    "Montagano - CB",
    "Montagna - BZ",
    "Montagna in Valtellina - SO",
    "Montagnana - PD",
    "Montagnareale - ME",
    "Montagne - TN",
    "Montaguto - AV",
    "Montaione - FI",
    "Montalbano Elicona - ME",
    "Montalbano Jonico - MT",
    "Montalcino - SI",
    "Montaldeo - AL",
    "Montaldo Bormida - AL",
    "Montaldo di Mondovì - CN",
    "Montaldo Roero - CN",
    "Montaldo Scarampi - AT",
    "Montaldo Torinese - TO",
    "Montale - PT",
    "Montalenghe - TO",
    "Montallegro - AG",
    "Montalto delle Marche - AP",
    "Montalto di Castro - VT",
    "Montalto Dora - TO",
    "Montalto Ligure - IM",
    "Montalto Pavese - PV",
    "Montalto Uffugo - CS",
    "Montanaro - TO",
    "Montanaso Lombardo - LO",
    "Montanera - CN",
    "Montano Antilia - SA",
    "Montano Lucino - CO",
    "Montappone - FM",
    "Montaquila - IS",
    "Montasola - RI",
    "Montauro - CZ",
    "Montazzoli - CH",
    "Monte Argentario - GR",
    "Monte Castello di Vibio - PG",
    "Monte Cavallo - MC",
    "Monte Cerignone - PU",
    "Monte Colombo - RN",
    "Monte Compatri - RM",
    "Monte Cremasco - CR",
    "Monte di Malo - VI",
    "Monte di Procida - NA",
    "Monte Giberto - FM",
    "Monte Grimano Terme - PU",
    "Monte Isola - BS",
    "Monte Marenzo - LC",
    "Monte Porzio - PU",
    "Monte Porzio Catone - RM",
    "Monte Rinaldo - FM",
    "Monte Roberto - AN",
    "Monte Romano - VT",
    "Monte San Biagio - LT",
    "Monte San Giacomo - SA",
    "Monte San Giovanni Campano - FR",
    "Monte San Giovanni in Sabina - RI",
    "Monte San Giusto - MC",
    "Monte San Martino - MC",
    "Monte San Pietrangeli - FM",
    "Monte San Pietro - BO",
    "Monte San Savino - AR",
    "Monte San Vito - AN",
    "Monte Santa Maria Tiberina - PG",
    "Monte Sant'Angelo - FG",
    "Monte Urano - FM",
    "Monte Vidon Combatte - FM",
    "Monte Vidon Corrado - FM",
    "Montebello della Battaglia - PV",
    "Montebello di Bertona - PE",
    "Montebello Ionico - RC",
    "Montebello sul Sangro - CH",
    "Montebello Vicentino - VI",
    "Montebelluna - TV",
    "Montebruno - GE",
    "Montebuono - RI",
    "Montecalvo in Foglia - PU",
    "Montecalvo Irpino - AV",
    "Montecalvo Versiggia - PV",
    "Montecarlo - LU",
    "Montecarotto - AN",
    "Montecassiano - MC",
    "Montecastello - AL",
    "Montecastrilli - TR",
    "Montecatini Val di Cecina - PI",
    "Montecatini-Terme - PT",
    "Montecchia di Crosara - VR",
    "Montecchio - TR",
    "Montecchio Emilia - RE",
    "Montecchio Maggiore - VI",
    "Montecchio Precalcino - VI",
    "Montechiaro d'Acqui - AL",
    "Montechiaro d'Asti - AT",
    "Montechiarugolo - PR",
    "Monteciccardo - PU",
    "Montecilfone - CB",
    "Montecopiolo - PU",
    "Montecorice - SA",
    "Montecorvino Pugliano - SA",
    "Montecorvino Rovella - SA",
    "Montecosaro - MC",
    "Montecrestese - VB",
    "Montecreto - MO",
    "Montedinove - AP",
    "Montedoro - CL",
    "Montefalcione - AV",
    "Montefalco - PG",
    "Montefalcone Appennino - FM",
    "Montefalcone di Val Fortore - BN",
    "Montefalcone nel Sannio - CB",
    "Montefano - MC",
    "Montefelcino - PU",
    "Monteferrante - CH",
    "Montefiascone - VT",
    "Montefino - TE",
    "Montefiore Conca - RN",
    "Montefiore dell'Aso - AP",
    "Montefiorino - MO",
    "Monteflavio - RM",
    "Monteforte Cilento - SA",
    "Monteforte d'Alpone - VR",
    "Monteforte Irpino - AV",
    "Montefortino - FM",
    "Montefranco - TR",
    "Montefredane - AV",
    "Montefusco - AV",
    "Montegabbione - TR",
    "Montegalda - VI",
    "Montegaldella - VI",
    "Montegallo - AP",
    "Montegioco - AL",
    "Montegiordano - CS",
    "Montegiorgio - FM",
    "Montegranaro - FM",
    "Montegridolfo - RN",
    "Montegrino Valtravaglia - VA",
    "Montegrosso d'Asti - AT",
    "Montegrosso Pian Latte - IM",
    "Montegrotto Terme - PD",
    "Monteiasi - TA",
    "Montelabbate - PU",
    "Montelanico - RM",
    "Montelapiano - CH",
    "Monteleone di Fermo - FM",
    "Monteleone di Puglia - FG",
    "Monteleone di Spoleto - PG",
    "Monteleone d'Orvieto - TR",
    "Monteleone Rocca Doria - SS",
    "Monteleone Sabino - RI",
    "Montelepre - PA",
    "Montelibretti - RM",
    "Montella - AV",
    "Montello - BG",
    "Montelongo - CB",
    "Montelparo - FM",
    "Montelupo Albese - CN",
    "Montelupo Fiorentino - FI",
    "Montelupone - MC",
    "Montemaggiore al Metauro - PU",
    "Montemaggiore Belsito - PA",
    "Montemagno - AT",
    "Montemale di Cuneo - CN",
    "Montemarano - AV",
    "Montemarciano - AN",
    "Montemarzino - AL",
    "Montemesola - TA",
    "Montemezzo - CO",
    "Montemignaio - AR",
    "Montemiletto - AV",
    "Montemilone - PZ",
    "Montemitro - CB",
    "Montemonaco - AP",
    "Montemurlo - PO",
    "Montemurro - PZ",
    "Montenars - UD",
    "Montenero di Bisaccia - CB",
    "Montenero Sabino - RI",
    "Montenero Val Cocchiara - IS",
    "Montenerodomo - CH",
    "Monteodorisio - CH",
    "Montepaone - CZ",
    "Monteparano - TA",
    "Monteprandone - AP",
    "Montepulciano - SI",
    "Monterado - AN",
    "Monterchi - AR",
    "Montereale - AQ",
    "Montereale Valcellina - PN",
    "Monterenzio - BO",
    "Monteriggioni - SI",
    "Monteroduni - IS",
    "Monteroni d'Arbia - SI",
    "Monteroni di Lecce - LE",
    "Monterosi - VT",
    "Monterosso al Mare - SP",
    "Monterosso Almo - RG",
    "Monterosso Calabro - VV",
    "Monterosso Grana - CN",
    "Monterotondo - RM",
    "Monterotondo Marittimo - GR",
    "Monterubbiano - FM",
    "Montesano Salentino - LE",
    "Montesano sulla Marcellana - SA",
    "Montesarchio - BN",
    "Montescaglioso - MT",
    "Montescano - PV",
    "Montescheno - VB",
    "Montescudaio - PI",
    "Montescudo - RN",
    "Montese - MO",
    "Montesegale - PV",
    "Montesilvano - PE",
    "Montespertoli - FI",
    "Monteu da Po - TO",
    "Monteu Roero - CN",
    "Montevago - AG",
    "Montevarchi - AR",
    "Montevecchia - LC",
    "Monteveglio - BO",
    "Monteverde - AV",
    "Monteverdi Marittimo - PI",
    "Monteviale - VI",
    "Montezemolo - CN",
    "Monti - OT",
    "Montiano - FC",
    "Monticelli Brusati - BS",
    "Monticelli d'Ongina - PC",
    "Monticelli Pavese - PV",
    "Monticello Brianza - LC",
    "Monticello Conte Otto - VI",
    "Monticello d'Alba - CN",
    "Montichiari - BS",
    "Monticiano - SI",
    "Montieri - GR",
    "Montiglio Monferrato - AT",
    "Montignoso - MS",
    "Montirone - BS",
    "Montjovet - AO",
    "Montodine - CR",
    "Montoggio - GE",
    "Montone - PG",
    "Montopoli di Sabina - RI",
    "Montopoli in Val d'Arno - PI",
    "Montorfano - CO",
    "Montorio al Vomano - TE",
    "Montorio nei Frentani - CB",
    "Montorio Romano - RM",
    "Montoro Inferiore - AV",
    "Montoro Superiore - AV",
    "Montorso Vicentino - VI",
    "Montottone - FM",
    "Montresta - OR",
    "Montù Beccaria - PV",
    "Monvalle - VA",
    "Monza - MB",
    "Monzambano - MN",
    "Monzuno - BO",
    "Morano Calabro - CS",
    "Morano sul Po - AL",
    "Moransengo - AT",
    "Moraro - GO",
    "Morazzone - VA",
    "Morbegno - SO",
    "Morbello - AL",
    "Morciano di Leuca - LE",
    "Morciano di Romagna - RN",
    "Morcone - BN",
    "Mordano - BO",
    "Morengo - BG",
    "Mores - SS",
    "Moresco - FM",
    "Moretta - CN",
    "Morfasso - PC",
    "Morgano - TV",
    "Morgex - AO",
    "Morgongiori - OR",
    "Mori - TN",
    "Moriago della Battaglia - TV",
    "Moricone - RM",
    "Morigerati - SA",
    "Morimondo - MI",
    "Morino - AQ",
    "Moriondo Torinese - TO",
    "Morlupo - RM",
    "Mormanno - CS",
    "Mornago - VA",
    "Mornese - AL",
    "Mornico al Serio - BG",
    "Mornico Losana - PV",
    "Morolo - FR",
    "Morozzo - CN",
    "Morra De Sanctis - AV",
    "Morro d'Alba - AN",
    "Morro d'Oro - TE",
    "Morro Reatino - RI",
    "Morrone del Sannio - CB",
    "Morrovalle - MC",
    "Morsano al Tagliamento - PN",
    "Morsasco - AL",
    "Mortara - PV",
    "Mortegliano - UD",
    "Morterone - LC",
    "Moruzzo - UD",
    "Moscazzano - CR",
    "Moschiano - AV",
    "Mosciano Sant'Angelo - TE",
    "Moscufo - PE",
    "Moso in Passiria - BZ",
    "Mossa - GO",
    "Mossano - VI",
    "Mosso - BI",
    "Motta Baluffi - CR",
    "Motta Camastra - ME",
    "Motta d'Affermo - ME",
    "Motta de' Conti - VC",
    "Motta di Livenza - TV",
    "Motta Montecorvino - FG",
    "Motta San Giovanni - RC",
    "Motta Santa Lucia - CZ",
    "Motta Sant'Anastasia - CT",
    "Motta Visconti - MI",
    "Mottafollone - CS",
    "Mottalciata - BI",
    "Motteggiana - MN",
    "Mottola - TA",
    "Mozzagrogna - CH",
    "Mozzanica - BG",
    "Mozzate - CO",
    "Mozzecane - VR",
    "Mozzo - BG",
    "Muccia - MC",
    "Muggia - TS",
    "Muggiò - MB",
    "Mugnano del Cardinale - AV",
    "Mugnano di Napoli - NA",
    "Mulazzano - LO",
    "Mulazzo - MS",
    "Mura - BS",
    "Muravera - CA",
    "Murazzano - CN",
    "Murello - CN",
    "Murialdo - SV",
    "Murisengo - AL",
    "Murlo - SI",
    "Muro Leccese - LE",
    "Muro Lucano - PZ",
    "Muros - SS",
    "Muscoline - BS",
    "Musei - CI",
    "Musile di Piave - VE",
    "Musso - CO",
    "Mussolente - VI",
    "Mussomeli - CL",
    "Muzzana del Turgnano - UD",
    "Muzzano - BI",
    "Nago-Torbole - TN",
    "Nalles - BZ",
    "Nanno - TN",
    "Nanto - VI",
    "Napoli - NA",
    "Narbolia - OR",
    "Narcao - CI",
    "Nardò - LE",
    "Nardodipace - VV",
    "Narni - TR",
    "Naro - AG",
    "Narzole - CN",
    "Nasino - SV",
    "Naso - ME",
    "Naturno - BZ",
    "Nave - BS",
    "Nave San Rocco - TN",
    "Navelli - AQ",
    "Naz-Sciaves - BZ",
    "Nazzano - RM",
    "Ne - GE",
    "Nebbiuno - NO",
    "Negrar - VR",
    "Neirone - GE",
    "Neive - CN",
    "Nembro - BG",
    "Nemi - RM",
    "Nemoli - PZ",
    "Neoneli - OR",
    "Nepi - VT",
    "Nereto - TE",
    "Nerola - RM",
    "Nervesa della Battaglia - TV",
    "Nerviano - MI",
    "Nespolo - RI",
    "Nesso - CO",
    "Netro - BI",
    "Nettuno - RM",
    "Neviano - LE",
    "Neviano degli Arduini - PR",
    "Neviglie - CN",
    "Niardo - BS",
    "Nibbiano - PC",
    "Nibbiola - NO",
    "Nibionno - LC",
    "Nichelino - TO",
    "Nicolosi - CT",
    "Nicorvo - PV",
    "Nicosia - EN",
    "Nicotera - VV",
    "Niella Belbo - CN",
    "Niella Tanaro - CN",
    "Nimis - UD",
    "Niscemi - CL",
    "Nissoria - EN",
    "Nizza di Sicilia - ME",
    "Nizza Monferrato - AT",
    "Noale - VE",
    "Noasca - TO",
    "Nocara - CS",
    "Nocciano - PE",
    "Nocera Inferiore - SA",
    "Nocera Superiore - SA",
    "Nocera Terinese - CZ",
    "Nocera Umbra - PG",
    "Noceto - PR",
    "Noci - BA",
    "Nociglia - LE",
    "Noepoli - PZ",
    "Nogara - VR",
    "Nogaredo - TN",
    "Nogarole Rocca - VR",
    "Nogarole Vicentino - VI",
    "Noicàttaro - BA",
    "Nola - NA",
    "Nole - TO",
    "Noli - SV",
    "Nomaglio - TO",
    "Nomi - TN",
    "Nonantola - MO",
    "None - TO",
    "Nonio - VB",
    "Noragugume - NU",
    "Norbello - OR",
    "Norcia - PG",
    "Norma - LT",
    "Nosate - MI",
    "Notaresco - TE",
    "Noto - SR",
    "Nova Levante - BZ",
    "Nova Milanese - MB",
    "Nova Ponente - BZ",
    "Nova Siri - MT",
    "Novafeltria - RN",
    "Novaledo - TN",
    "Novalesa - TO",
    "Novara - NO",
    "Novara di Sicilia - ME",
    "Novate Mezzola - SO",
    "Novate Milanese - MI",
    "Nove - VI",
    "Novedrate - CO",
    "Novellara - RE",
    "Novello - CN",
    "Noventa di Piave - VE",
    "Noventa Padovana - PD",
    "Noventa Vicentina - VI",
    "Novi di Modena - MO",
    "Novi Ligure - AL",
    "Novi Velia - SA",
    "Noviglio - MI",
    "Novoli - LE",
    "Nucetto - CN",
    "Nughedu San Nicolò - SS",
    "Nughedu Santa Vittoria - OR",
    "Nule - SS",
    "Nulvi - SS",
    "Numana - AN",
    "Nuoro - NU",
    "Nurachi - OR",
    "Nuragus - CA",
    "Nurallao - CA",
    "Nuraminis - CA",
    "Nureci - OR",
    "Nurri - CA",
    "Nus - AO",
    "Nusco - AV",
    "Nuvolento - BS",
    "Nuvolera - BS",
    "Nuxis - CI",
    "Occhieppo Inferiore - BI",
    "Occhieppo Superiore - BI",
    "Occhiobello - RO",
    "Occimiano - AL",
    "Ocre - AQ",
    "Odalengo Grande - AL",
    "Odalengo Piccolo - AL",
    "Oderzo - TV",
    "Odolo - BS",
    "Ofena - AQ",
    "Offagna - AN",
    "Offanengo - CR",
    "Offida - AP",
    "Offlaga - BS",
    "Oggebbio - VB",
    "Oggiona con Santo Stefano - VA",
    "Oggiono - LC",
    "Oglianico - TO",
    "Ogliastro Cilento - SA",
    "Olbia - OT",
    "Olcenengo - VC",
    "Oldenico - VC",
    "Oleggio - NO",
    "Oleggio Castello - NO",
    "Olevano di Lomellina - PV",
    "Olevano Romano - RM",
    "Olevano sul Tusciano - SA",
    "Olgiate Comasco - CO",
    "Olgiate Molgora - LC",
    "Olgiate Olona - VA",
    "Olginate - LC",
    "Oliena - NU",
    "Oliva Gessi - PV",
    "Olivadi - CZ",
    "Oliveri - ME",
    "Oliveto Citra - SA",
    "Oliveto Lario - LC",
    "Oliveto Lucano - MT",
    "Olivetta San Michele - IM",
    "Olivola - AL",
    "Ollastra - OR",
    "Ollolai - NU",
    "Ollomont - AO",
    "Olmedo - SS",
    "Olmeneta - CR",
    "Olmo al Brembo - BG",
    "Olmo Gentile - AT",
    "Oltre il Colle - BG",
    "Oltressenda Alta - BG",
    "Oltrona di San Mamette - CO",
    "Olzai - NU",
    "Ome - BS",
    "Omegna - VB",
    "Omignano - SA",
    "Onanì - NU",
    "Onano - VT",
    "Oncino - CN",
    "Oneta - BG",
    "Onifai - NU",
    "Oniferi - NU",
    "Ono San Pietro - BS",
    "Onore - BG",
    "Onzo - SV",
    "Opera - MI",
    "Opi - AQ",
    "Oppeano - VR",
    "Oppido Lucano - PZ",
    "Oppido Mamertina - RC",
    "Ora - BZ",
    "Orani - NU",
    "Oratino - CB",
    "Orbassano - TO",
    "Orbetello - GR",
    "Orciano di Pesaro - PU",
    "Orciano Pisano - PI",
    "Orco Feglino - SV",
    "Ordona - FG",
    "Orero - GE",
    "Orgiano - VI",
    "Orgosolo - NU",
    "Oria - BR",
    "Oricola - AQ",
    "Origgio - VA",
    "Orino - VA",
    "Orio al Serio - BG",
    "Orio Canavese - TO",
    "Orio Litta - LO",
    "Oriolo - CS",
    "Oriolo Romano - VT",
    "Oristano - OR",
    "Ormea - CN",
    "Ormelle - TV",
    "Ornago - MB",
    "Ornavasso - VB",
    "Ornica - BG",
    "Orosei - NU",
    "Orotelli - NU",
    "Orria - SA",
    "Orroli - CA",
    "Orsago - TV",
    "Orsara Bormida - AL",
    "Orsara di Puglia - FG",
    "Orsenigo - CO",
    "Orsogna - CH",
    "Orsomarso - CS",
    "Orta di Atella - CE",
    "Orta Nova - FG",
    "Orta San Giulio - NO",
    "Ortacesus - CA",
    "Orte - VT",
    "Ortelle - LE",
    "Ortezzano - FM",
    "Ortignano Raggiolo - AR",
    "Ortisei - BZ",
    "Ortona - CH",
    "Ortona dei Marsi - AQ",
    "Ortonovo - SP",
    "Ortovero - SV",
    "Ortucchio - AQ",
    "Ortueri - NU",
    "Orune - NU",
    "Orvieto - TR",
    "Orvinio - RI",
    "Orzinuovi - BS",
    "Orzivecchi - BS",
    "Osasco - TO",
    "Osasio - TO",
    "Oschiri - OT",
    "Osidda - NU",
    "Osiglia - SV",
    "Osilo - SS",
    "Osimo - AN",
    "Osini - OG",
    "Osio Sopra - BG",
    "Osio Sotto - BG",
    "Osmate - VA",
    "Osnago - LC",
    "Osoppo - UD",
    "Ospedaletti - IM",
    "Ospedaletto - TN",
    "Ospedaletto d'Alpinolo - AV",
    "Ospedaletto Euganeo - PD",
    "Ospedaletto Lodigiano - LO",
    "Ospitale di Cadore - BL",
    "Ospitaletto - BS",
    "Ossago Lodigiano - LO",
    "Ossana - TN",
    "Ossi - SS",
    "Ossimo - BS",
    "Ossona - MI",
    "Ossuccio - CO",
    "Ostana - CN",
    "Ostellato - FE",
    "Ostiano - CR",
    "Ostiglia - MN",
    "Ostra - AN",
    "Ostra Vetere - AN",
    "Ostuni - BR",
    "Otranto - LE",
    "Otricoli - TR",
    "Ottana - NU",
    "Ottati - SA",
    "Ottaviano - NA",
    "Ottiglio - AL",
    "Ottobiano - PV",
    "Ottone - PC",
    "Oulx - TO",
    "Ovada - AL",
    "Ovaro - UD",
    "Oviglio - AL",
    "Ovindoli - AQ",
    "Ovodda - NU",
    "Oyace - AO",
    "Ozegna - TO",
    "Ozieri - SS",
    "Ozzano dell'Emilia - BO",
    "Ozzano Monferrato - AL",
    "Ozzero - MI",
    "Pabillonis - VS",
    "Pace del Mela - ME",
    "Paceco - TP",
    "Pacentro - AQ",
    "Pachino - SR",
    "Paciano - PG",
    "Padenghe sul Garda - BS",
    "Padergnone - TN",
    "Paderna - AL",
    "Paderno d'Adda - LC",
    "Paderno del Grappa - TV",
    "Paderno Dugnano - MI",
    "Paderno Franciacorta - BS",
    "Paderno Ponchielli - CR",
    "Padova - PD",
    "Padria - SS",
    "Padru - OT",
    "Padula - SA",
    "Paduli - BN",
    "Paesana - CN",
    "Paese - TV",
    "Pagani - SA",
    "Paganico Sabino - RI",
    "Pagazzano - BG",
    "Pagliara - ME",
    "Paglieta - CH",
    "Pagnacco - UD",
    "Pagno - CN",
    "Pagnona - LC",
    "Pago del Vallo di Lauro - AV",
    "Pago Veiano - BN",
    "Paisco Loveno - BS",
    "Paitone - BS",
    "Paladina - BG",
    "Palagano - MO",
    "Palagianello - TA",
    "Palagiano - TA",
    "Palagonia - CT",
    "Palaia - PI",
    "Palanzano - PR",
    "Palata - CB",
    "Palau - OT",
    "Palazzago - BG",
    "Palazzo Adriano - PA",
    "Palazzo Canavese - TO",
    "Palazzo Pignano - CR",
    "Palazzo San Gervasio - PZ",
    "Palazzolo Acreide - SR",
    "Palazzolo dello Stella - UD",
    "Palazzolo sull'Oglio - BS",
    "Palazzolo Vercellese - VC",
    "Palazzuolo sul Senio - FI",
    "Palena - CH",
    "Palermiti - CZ",
    "Palermo - PA",
    "Palestrina - RM",
    "Palestro - PV",
    "Paliano - FR",
    "Palizzi - RC",
    "Pallagorio - KR",
    "Pallanzeno - VB",
    "Pallare - SV",
    "Palma Campania - NA",
    "Palma di Montechiaro - AG",
    "Palmanova - UD",
    "Palmariggi - LE",
    "Palmas Arborea - OR",
    "Palmi - RC",
    "Palmiano - AP",
    "Palmoli - CH",
    "Palo del Colle - BA",
    "Palombara Sabina - RM",
    "Palombaro - CH",
    "Palomonte - SA",
    "Palosco - BG",
    "Palù - VR",
    "Palù del Fersina - TN",
    "Paludi - CS",
    "Paluzza - UD",
    "Pamparato - CN",
    "Pancalieri - TO",
    "Pancarana - PV",
    "Panchià - TN",
    "Pandino - CR",
    "Panettieri - CS",
    "Panicale - PG",
    "Pannarano - BN",
    "Panni - FG",
    "Pantelleria - TP",
    "Pantigliate - MI",
    "Paola - CS",
    "Paolisi - BN",
    "Papasidero - CS",
    "Papozze - RO",
    "Parabiago - MI",
    "Parabita - LE",
    "Paratico - BS",
    "Parcines - BZ",
    "Parè - CO",
    "Parella - TO",
    "Parenti - CS",
    "Parete - CE",
    "Pareto - AL",
    "Parghelia - VV",
    "Parlasco - LC",
    "Parma - PR",
    "Parodi Ligure - AL",
    "Paroldo - CN",
    "Parolise - AV",
    "Parona - PV",
    "Parrano - TR",
    "Parre - BG",
    "Partanna - TP",
    "Partinico - PA",
    "Paruzzaro - NO",
    "Parzanica - BG",
    "Pasian di Prato - UD",
    "Pasiano di Pordenone - PN",
    "Paspardo - BS",
    "Passerano Marmorito - AT",
    "Passignano sul Trasimeno - PG",
    "Passirano - BS",
    "Pastena - FR",
    "Pastorano - CE",
    "Pastrengo - VR",
    "Pasturana - AL",
    "Pasturo - LC",
    "Paterno - PZ",
    "Paternò - CT",
    "Paterno Calabro - CS",
    "Paternopoli - AV",
    "Patrica - FR",
    "Pattada - SS",
    "Patti - ME",
    "Patù - LE",
    "Pau - OR",
    "Paularo - UD",
    "Pauli Arbarei - VS",
    "Paulilatino - OR",
    "Paullo - MI",
    "Paupisi - BN",
    "Pavarolo - TO",
    "Pavia - PV",
    "Pavia di Udine - UD",
    "Pavone Canavese - TO",
    "Pavone del Mella - BS",
    "Pavullo nel Frignano - MO",
    "Pazzano - RC",
    "Peccioli - PI",
    "Pecco - TO",
    "Pecetto di Valenza - AL",
    "Pecetto Torinese - TO",
    "Pecorara - PC",
    "Pedace - CS",
    "Pedara - CT",
    "Pedaso - FM",
    "Pedavena - BL",
    "Pedemonte - VI",
    "Pederobba - TV",
    "Pedesina - SO",
    "Pedivigliano - CS",
    "Pedrengo - BG",
    "Peglio - CO",
    "Peglio - PU",
    "Pegognaga - MN",
    "Peia - BG",
    "Peio - TN",
    "Pelago - FI",
    "Pella - NO",
    "Pellegrino Parmense - PR",
    "Pellezzano - SA",
    "Pellio Intelvi - CO",
    "Pellizzano - TN",
    "Pelugo - TN",
    "Penango - AT",
    "Penna in Teverina - TR",
    "Penna San Giovanni - MC",
    "Penna Sant'Andrea - TE",
    "Pennabilli - RN",
    "Pennadomo - CH",
    "Pennapiedimonte - CH",
    "Penne - PE",
    "Pentone - CZ",
    "Perano - CH",
    "Perarolo di Cadore - BL",
    "Perca - BZ",
    "Percile - RM",
    "Perdasdefogu - OG",
    "Perdaxius - CI",
    "Perdifumo - SA",
    "Perego - LC",
    "Pereto - AQ",
    "Perfugas - SS",
    "Pergine Valdarno - AR",
    "Pergine Valsugana - TN",
    "Pergola - PU",
    "Perinaldo - IM",
    "Perito - SA",
    "Perledo - LC",
    "Perletto - CN",
    "Perlo - CN",
    "Perloz - AO",
    "Pernumia - PD",
    "Pero - MI",
    "Perosa Argentina - TO",
    "Perosa Canavese - TO",
    "Perrero - TO",
    "Persico Dosimo - CR",
    "Pertengo - VC",
    "Pertica Alta - BS",
    "Pertica Bassa - BS",
    "Pertosa - SA",
    "Pertusio - TO",
    "Perugia - PG",
    "Pesaro - PU",
    "Pescaglia - LU",
    "Pescantina - VR",
    "Pescara - PE",
    "Pescarolo ed Uniti - CR",
    "Pescasseroli - AQ",
    "Pescate - LC",
    "Pesche - IS",
    "Peschici - FG",
    "Peschiera Borromeo - MI",
    "Peschiera del Garda - VR",
    "Pescia - PT",
    "Pescina - AQ",
    "Pesco Sannita - BN",
    "Pescocostanzo - AQ",
    "Pescolanciano - IS",
    "Pescopagano - PZ",
    "Pescopennataro - IS",
    "Pescorocchiano - RI",
    "Pescosansonesco - PE",
    "Pescosolido - FR",
    "Pessano con Bornago - MI",
    "Pessina Cremonese - CR",
    "Pessinetto - TO",
    "Petacciato - CB",
    "Petilia Policastro - KR",
    "Petina - SA",
    "Petralia Soprana - PA",
    "Petralia Sottana - PA",
    "Petrella Salto - RI",
    "Petrella Tifernina - CB",
    "Petriano - PU",
    "Petriolo - MC",
    "Petritoli - FM",
    "Petrizzi - CZ",
    "Petronà - CZ",
    "Petrosino - TP",
    "Petruro Irpino - AV",
    "Pettenasco - NO",
    "Pettinengo - BI",
    "Pettineo - ME",
    "Pettoranello del Molise - IS",
    "Pettorano sul Gizio - AQ",
    "Pettorazza Grimani - RO",
    "Peveragno - CN",
    "Pezzana - VC",
    "Pezzaze - BS",
    "Pezzolo Valle Uzzone - CN",
    "Piacenza - PC",
    "Piacenza d'Adige - PD",
    "Piadena - CR",
    "Piagge - PU",
    "Piaggine - SA",
    "Pian Camuno - BS",
    "Pian di Sco - AR",
    "Piana Crixia - SV",
    "Piana degli Albanesi - PA",
    "Piana di Monte Verna - CE",
    "Piancastagnaio - SI",
    "Piancogno - BS",
    "Piandimeleto - PU",
    "Piane Crati - CS",
    "Pianella - PE",
    "Pianello del Lario - CO",
    "Pianello Val Tidone - PC",
    "Pianengo - CR",
    "Pianezza - TO",
    "Pianezze - VI",
    "Pianfei - CN",
    "Pianico - BG",
    "Pianiga - VE",
    "Piano di Sorrento - NA",
    "Pianopoli - CZ",
    "Pianoro - BO",
    "Piansano - VT",
    "Piantedo - SO",
    "Piario - BG",
    "Piasco - CN",
    "Piateda - SO",
    "Piatto - BI",
    "Piazza al Serchio - LU",
    "Piazza Armerina - EN",
    "Piazza Brembana - BG",
    "Piazzatorre - BG",
    "Piazzola sul Brenta - PD",
    "Piazzolo - BG",
    "Picciano - PE",
    "Picerno - PZ",
    "Picinisco - FR",
    "Pico - FR",
    "Piea - AT",
    "Piedicavallo - BI",
    "Piedimonte Etneo - CT",
    "Piedimonte Matese - CE",
    "Piedimonte San Germano - FR",
    "Piedimulera - VB",
    "Piegaro - PG",
    "Pienza - SI",
    "Pieranica - CR",
    "Pietra de' Giorgi - PV",
    "Pietra Ligure - SV",
    "Pietra Marazzi - AL",
    "Pietrabbondante - IS",
    "Pietrabruna - IM",
    "Pietracamela - TE",
    "Pietracatella - CB",
    "Pietracupa - CB",
    "Pietradefusi - AV",
    "Pietraferrazzana - CH",
    "Pietrafitta - CS",
    "Pietragalla - PZ",
    "Pietralunga - PG",
    "Pietramelara - CE",
    "Pietramontecorvino - FG",
    "Pietranico - PE",
    "Pietrapaola - CS",
    "Pietrapertosa - PZ",
    "Pietraperzia - EN",
    "Pietraporzio - CN",
    "Pietraroja - BN",
    "Pietrarubbia - PU",
    "Pietrasanta - LU",
    "Pietrastornina - AV",
    "Pietravairano - CE",
    "Pietrelcina - BN",
    "Pieve a Nievole - PT",
    "Pieve Albignola - PV",
    "Pieve d'Alpago - BL",
    "Pieve del Cairo - PV",
    "Pieve di Bono - TN",
    "Pieve di Cadore - BL",
    "Pieve di Cento - BO",
    "Pieve di Coriano - MN",
    "Pieve di Soligo - TV",
    "Pieve di Teco - IM",
    "Pieve d'Olmi - CR",
    "Pieve Emanuele - MI",
    "Pieve Fissiraga - LO",
    "Pieve Fosciana - LU",
    "Pieve Ligure - GE",
    "Pieve Porto Morone - PV",
    "Pieve San Giacomo - CR",
    "Pieve Santo Stefano - AR",
    "Pieve Tesino - TN",
    "Pieve Torina - MC",
    "Pieve Vergonte - VB",
    "Pievebovigliana - MC",
    "Pievepelago - MO",
    "Piglio - FR",
    "Pigna - IM",
    "Pignataro Interamna - FR",
    "Pignataro Maggiore - CE",
    "Pignola - PZ",
    "Pignone - SP",
    "Pigra - CO",
    "Pila - VC",
    "Pimentel - CA",
    "Pimonte - NA",
    "Pinarolo Po - PV",
    "Pinasca - TO",
    "Pincara - RO",
    "Pinerolo - TO",
    "Pineto - TE",
    "Pino d'Asti - AT",
    "Pino sulla Sponda del Lago Maggiore - VA",
    "Pino Torinese - TO",
    "Pinzano al Tagliamento - PN",
    "Pinzolo - TN",
    "Piobbico - PU",
    "Piobesi d'Alba - CN",
    "Piobesi Torinese - TO",
    "Piode - VC",
    "Pioltello - MI",
    "Piombino - LI",
    "Piombino Dese - PD",
    "Pioraco - MC",
    "Piossasco - TO",
    "Piovà Massaia - AT",
    "Piove di Sacco - PD",
    "Piovene Rocchette - VI",
    "Piovera - AL",
    "Piozzano - PC",
    "Piozzo - CN",
    "Piraino - ME",
    "Pisa - PI",
    "Pisano - NO",
    "Piscina - TO",
    "Piscinas - CI",
    "Pisciotta - SA",
    "Pisogne - BS",
    "Pisoniano - RM",
    "Pisticci - MT",
    "Pistoia - PT",
    "Piteglio - PT",
    "Pitigliano - GR",
    "Piubega - MN",
    "Piuro - SO",
    "Piverone - TO",
    "Pizzale - PV",
    "Pizzighettone - CR",
    "Pizzo - VV",
    "Pizzoferrato - CH",
    "Pizzoli - AQ",
    "Pizzone - IS",
    "Pizzoni - VV",
    "Placanica - RC",
    "Plataci - CS",
    "Platania - CZ",
    "Platì - RC",
    "Plaus - BZ",
    "Plesio - CO",
    "Ploaghe - SS",
    "Plodio - SV",
    "Pocapaglia - CN",
    "Pocenia - UD",
    "Podenzana - MS",
    "Podenzano - PC",
    "Pofi - FR",
    "Poggiardo - LE",
    "Poggibonsi - SI",
    "Poggio a Caiano - PO",
    "Poggio Berni - RN",
    "Poggio Bustone - RI",
    "Poggio Catino - RI",
    "Poggio Imperiale - FG",
    "Poggio Mirteto - RI",
    "Poggio Moiano - RI",
    "Poggio Nativo - RI",
    "Poggio Picenze - AQ",
    "Poggio Renatico - FE",
    "Poggio Rusco - MN",
    "Poggio San Lorenzo - RI",
    "Poggio San Marcello - AN",
    "Poggio San Vicino - MC",
    "Poggio Sannita - IS",
    "Poggiodomo - PG",
    "Poggiofiorito - CH",
    "Poggiomarino - NA",
    "Poggioreale - TP",
    "Poggiorsini - BA",
    "Poggiridenti - SO",
    "Pogliano Milanese - MI",
    "Pognana Lario - CO",
    "Pognano - BG",
    "Pogno - NO",
    "Poirino - TO",
    "Pojana Maggiore - VI",
    "Polaveno - BS",
    "Polcenigo - PN",
    "Polesella - RO",
    "Polesine Parmense - PR",
    "Poli - RM",
    "Polia - VV",
    "Policoro - MT",
    "Polignano a Mare - BA",
    "Polinago - MO",
    "Polino - TR",
    "Polistena - RC",
    "Polizzi Generosa - PA",
    "Polla - SA",
    "Pollein - AO",
    "Pollena Trocchia - NA",
    "Pollenza - MC",
    "Pollica - SA",
    "Pollina - PA",
    "Pollone - BI",
    "Pollutri - CH",
    "Polonghera - CN",
    "Polpenazze del Garda - BS",
    "Polverara - PD",
    "Polverigi - AN",
    "Pomarance - PI",
    "Pomaretto - TO",
    "Pomarico - MT",
    "Pomaro Monferrato - AL",
    "Pomarolo - TN",
    "Pombia - NO",
    "Pomezia - RM",
    "Pomigliano d'Arco - NA",
    "Pompei - NA",
    "Pompeiana - IM",
    "Pompiano - BS",
    "Pomponesco - MN",
    "Pompu - OR",
    "Poncarale - BS",
    "Ponderano - BI",
    "Ponna - CO",
    "Ponsacco - PI",
    "Ponso - PD",
    "Pontassieve - FI",
    "Pontboset - AO",
    "Pont-Canavese - TO",
    "Ponte - BN",
    "Ponte Buggianese - PT",
    "Ponte dell'Olio - PC",
    "Ponte di Legno - BS",
    "Ponte di Piave - TV",
    "Ponte Gardena - BZ",
    "Ponte in Valtellina - SO",
    "Ponte Lambro - CO",
    "Ponte nelle Alpi - BL",
    "Ponte Nizza - PV",
    "Ponte Nossa - BG",
    "Ponte San Nicolò - PD",
    "Ponte San Pietro - BG",
    "Pontebba - UD",
    "Pontecagnano Faiano - SA",
    "Pontecchio Polesine - RO",
    "Pontechianale - CN",
    "Pontecorvo - FR",
    "Pontecurone - AL",
    "Pontedassio - IM",
    "Pontedera - PI",
    "Pontelandolfo - BN",
    "Pontelatone - CE",
    "Pontelongo - PD",
    "Pontenure - PC",
    "Ponteranica - BG",
    "Pontestura - AL",
    "Pontevico - BS",
    "Pontey - AO",
    "Ponti - AL",
    "Ponti sul Mincio - MN",
    "Pontida - BG",
    "Pontinia - LT",
    "Pontinvrea - SV",
    "Pontirolo Nuovo - BG",
    "Pontoglio - BS",
    "Pontremoli - MS",
    "Pont-Saint-Martin - AO",
    "Ponza - LT",
    "Ponzano di Fermo - FM",
    "Ponzano Monferrato - AL",
    "Ponzano Romano - RM",
    "Ponzano Veneto - TV",
    "Ponzone - AL",
    "Popoli - PE",
    "Poppi - AR",
    "Porano - TR",
    "Porcari - LU",
    "Porcia - PN",
    "Pordenone - PN",
    "Porlezza - CO",
    "Pornassio - IM",
    "Porpetto - UD",
    "Porretta Terme - BO",
    "Portacomaro - AT",
    "Portalbera - PV",
    "Porte - TO",
    "Portici - NA",
    "Portico di Caserta - CE",
    "Portico e San Benedetto - FC",
    "Portigliola - RC",
    "Porto Azzurro - LI",
    "Porto Ceresio - VA",
    "Porto Cesareo - LE",
    "Porto Empedocle - AG",
    "Porto Mantovano - MN",
    "Porto Recanati - MC",
    "Porto San Giorgio - FM",
    "Porto Sant'Elpidio - FM",
    "Porto Tolle - RO",
    "Porto Torres - SS",
    "Porto Valtravaglia - VA",
    "Porto Viro - RO",
    "Portobuffolè - TV",
    "Portocannone - CB",
    "Portoferraio - LI",
    "Portofino - GE",
    "Portogruaro - VE",
    "Portomaggiore - FE",
    "Portopalo di Capo Passero - SR",
    "Portoscuso - CI",
    "Portovenere - SP",
    "Portula - BI",
    "Posada - NU",
    "Posina - VI",
    "Positano - SA",
    "Possagno - TV",
    "Posta - RI",
    "Posta Fibreno - FR",
    "Postal - BZ",
    "Postalesio - SO",
    "Postiglione - SA",
    "Postua - VC",
    "Potenza - PZ",
    "Potenza Picena - MC",
    "Pove del Grappa - VI",
    "Povegliano - TV",
    "Povegliano Veronese - VR",
    "Poviglio - RE",
    "Povoletto - UD",
    "Pozza di Fassa - TN",
    "Pozzaglia Sabina - RI",
    "Pozzaglio ed Uniti - CR",
    "Pozzallo - RG",
    "Pozzilli - IS",
    "Pozzo d'Adda - MI",
    "Pozzol Groppo - AL",
    "Pozzolengo - BS",
    "Pozzoleone - VI",
    "Pozzolo Formigaro - AL",
    "Pozzomaggiore - SS",
    "Pozzonovo - PD",
    "Pozzuoli - NA",
    "Pozzuolo del Friuli - UD",
    "Pozzuolo Martesana - MI",
    "Pradalunga - BG",
    "Pradamano - UD",
    "Pradleves - CN",
    "Pragelato - TO",
    "Praia a Mare - CS",
    "Praiano - SA",
    "Pralboino - BS",
    "Prali - TO",
    "Pralormo - TO",
    "Pralungo - BI",
    "Pramaggiore - VE",
    "Pramollo - TO",
    "Prarolo - VC",
    "Prarostino - TO",
    "Prasco - AL",
    "Prascorsano - TO",
    "Praso - TN",
    "Prata Camportaccio - SO",
    "Prata d'Ansidonia - AQ",
    "Prata di Pordenone - PN",
    "Prata di Principato Ultra - AV",
    "Prata Sannita - CE",
    "Pratella - CE",
    "Pratiglione - TO",
    "Prato - PO",
    "Prato allo Stelvio - BZ",
    "Prato Carnico - UD",
    "Prato Sesia - NO",
    "Pratola Peligna - AQ",
    "Pratola Serra - AV",
    "Pratovecchio - AR",
    "Pravisdomini - PN",
    "Pray - BI",
    "Prazzo - CN",
    "Precenicco - UD",
    "Preci - PG",
    "Predappio - FC",
    "Predazzo - TN",
    "Predoi - BZ",
    "Predore - BG",
    "Predosa - AL",
    "Preganziol - TV",
    "Pregnana Milanese - MI",
    "Prelà - IM",
    "Premana - LC",
    "Premariacco - UD",
    "Premeno - VB",
    "Premia - VB",
    "Premilcuore - FC",
    "Premolo - BG",
    "Premosello-Chiovenda - VB",
    "Preone - UD",
    "Preore - TN",
    "Prepotto - UD",
    "Prè-Saint-Didier - AO",
    "Preseglie - BS",
    "Presenzano - CE",
    "Presezzo - BG",
    "Presicce - LE",
    "Pressana - VR",
    "Prestine - BS",
    "Pretoro - CH",
    "Prevalle - BS",
    "Prezza - AQ",
    "Prezzo - TN",
    "Priero - CN",
    "Prignano Cilento - SA",
    "Prignano sulla Secchia - MO",
    "Primaluna - LC",
    "Priocca - CN",
    "Priola - CN",
    "Priolo Gargallo - SR",
    "Priverno - LT",
    "Prizzi - PA",
    "Proceno - VT",
    "Procida - NA",
    "Propata - GE",
    "Proserpio - CO",
    "Prossedi - LT",
    "Provaglio d'Iseo - BS",
    "Provaglio Val Sabbia - BS",
    "Proves - BZ",
    "Provvidenti - CB",
    "Prunetto - CN",
    "Puegnago sul Garda - BS",
    "Puglianello - BN",
    "Pula - CA",
    "Pulfero - UD",
    "Pulsano - TA",
    "Pumenengo - BG",
    "Puos d'Alpago - BL",
    "Pusiano - CO",
    "Putifigari - SS",
    "Putignano - BA",
    "Quadrelle - AV",
    "Quadri - CH",
    "Quagliuzzo - TO",
    "Qualiano - NA",
    "Quaranti - AT",
    "Quaregna - BI",
    "Quargnento - AL",
    "Quarna Sopra - VB",
    "Quarna Sotto - VB",
    "Quarona - VC",
    "Quarrata - PT",
    "Quart - AO",
    "Quarto - NA",
    "Quarto d'Altino - VE",
    "Quartu Sant'Elena - CA",
    "Quartucciu - CA",
    "Quassolo - TO",
    "Quattordio - AL",
    "Quattro Castella - RE",
    "Quero - BL",
    "Quiliano - SV",
    "Quincinetto - TO",
    "Quindici - AV",
    "Quingentole - MN",
    "Quintano - CR",
    "Quinto di Treviso - TV",
    "Quinto Vercellese - VC",
    "Quinto Vicentino - VI",
    "Quinzano d'Oglio - BS",
    "Quistello - MN",
    "Quittengo - BI",
    "Rabbi - TN",
    "Racale - LE",
    "Racalmuto - AG",
    "Racconigi - CN",
    "Raccuja - ME",
    "Racines - BZ",
    "Radda in Chianti - SI",
    "Raddusa - CT",
    "Radicofani - SI",
    "Radicondoli - SI",
    "Raffadali - AG",
    "Ragalna - CT",
    "Ragogna - UD",
    "Ragoli - TN",
    "Ragusa - RG",
    "Raiano - AQ",
    "Ramacca - CT",
    "Ramiseto - RE",
    "Ramponio Verna - CO",
    "Rancio Valcuvia - VA",
    "Ranco - VA",
    "Randazzo - CT",
    "Ranica - BG",
    "Ranzanico - BG",
    "Ranzo - IM",
    "Rapagnano - FM",
    "Rapallo - GE",
    "Rapino - CH",
    "Rapolano Terme - SI",
    "Rapolla - PZ",
    "Rapone - PZ",
    "Rassa - VC",
    "Rasun Anterselva - BZ",
    "Rasura - SO",
    "Ravanusa - AG",
    "Ravarino - MO",
    "Ravascletto - UD",
    "Ravello - SA",
    "Ravenna - RA",
    "Raveo - UD",
    "Raviscanina - CE",
    "Re - VB",
    "Rea - PV",
    "Realmonte - AG",
    "Reana del Rojale - UD",
    "Reano - TO",
    "Recale - CE",
    "Recanati - MC",
    "Recco - GE",
    "Recetto - NO",
    "Recoaro Terme - VI",
    "Redavalle - PV",
    "Redondesco - MN",
    "Refrancore - AT",
    "Refrontolo - TV",
    "Regalbuto - EN",
    "Reggello - FI",
    "Reggio Calabria - RC",
    "Reggio Emilia - RE",
    "Reggiolo - RE",
    "Reino - BN",
    "Reitano - ME",
    "Remanzacco - UD",
    "Remedello - BS",
    "Renate - MB",
    "Rende - CS",
    "Renon - BZ",
    "Resana - TV",
    "Rescaldina - MI",
    "Resia - UD",
    "Resiutta - UD",
    "Resuttano - CL",
    "Retorbido - PV",
    "Revello - CN",
    "Revere - MN",
    "Revigliasco d'Asti - AT",
    "Revine Lago - TV",
    "Revò - TN",
    "Rezzago - CO",
    "Rezzato - BS",
    "Rezzo - IM",
    "Rezzoaglio - GE",
    "Rhemes-Notre-Dame - AO",
    "Rhemes-Saint-Georges - AO",
    "Rho - MI",
    "Riace - RC",
    "Rialto - SV",
    "Riano - RM",
    "Riardo - CE",
    "Ribera - AG",
    "Ribordone - TO",
    "Ricadi - VV",
    "Ricaldone - AL",
    "Riccia - CB",
    "Riccione - RN",
    "Riccò del Golfo di Spezia - SP",
    "Ricengo - CR",
    "Ricigliano - SA",
    "Riese Pio X - TV",
    "Riesi - CL",
    "Rieti - RI",
    "Rifiano - BZ",
    "Rifreddo - CN",
    "Rignano Flaminio - RM",
    "Rignano Garganico - FG",
    "Rignano sull'Arno - FI",
    "Rigolato - UD",
    "Rima San Giuseppe - VC",
    "Rimasco - VC",
    "Rimella - VC",
    "Rimini - RN",
    "Rio di Pusteria - BZ",
    "Rio Marina - LI",
    "Rio nell'Elba - LI",
    "Rio Saliceto - RE",
    "Riofreddo - RM",
    "Riola Sardo - OR",
    "Riolo Terme - RA",
    "Riolunato - MO",
    "Riomaggiore - SP",
    "Rionero in Vulture - PZ",
    "Rionero Sannitico - IS",
    "Ripa Teatina - CH",
    "Ripabottoni - CB",
    "Ripacandida - PZ",
    "Ripalimosani - CB",
    "Ripalta Arpina - CR",
    "Ripalta Cremasca - CR",
    "Ripalta Guerina - CR",
    "Riparbella - PI",
    "Ripatransone - AP",
    "Ripe - AN",
    "Ripe San Ginesio - MC",
    "Ripi - FR",
    "Riposto - CT",
    "Rittana - CN",
    "Riva del Garda - TN",
    "Riva di Solto - BG",
    "Riva Ligure - IM",
    "Riva Presso Chieri - TO",
    "Riva Valdobbia - VC",
    "Rivalba - TO",
    "Rivalta Bormida - AL",
    "Rivalta di Torino - TO",
    "Rivamonte Agordino - BL",
    "Rivanazzano Terme - PV",
    "Rivara - TO",
    "Rivarolo Canavese - TO",
    "Rivarolo del Re ed Uniti - CR",
    "Rivarolo Mantovano - MN",
    "Rivarone - AL",
    "Rivarossa - TO",
    "Rive - VC",
    "Rive D'Arcano - UD",
    "Rivello - PZ",
    "Rivergaro - PC",
    "Rivignano - UD",
    "Rivisondoli - AQ",
    "Rivodutri - RI",
    "Rivoli - TO",
    "Rivoli Veronese - VR",
    "Rivolta d'Adda - CR",
    "Rizziconi - RC",
    "Ro - FE",
    "Roana - VI",
    "Roaschia - CN",
    "Roascio - CN",
    "Roasio - VC",
    "Roatto - AT",
    "Robassomero - TO",
    "Robbiate - LC",
    "Robbio - PV",
    "Robecchetto con Induno - MI",
    "Robecco d'Oglio - CR",
    "Robecco Pavese - PV",
    "Robecco sul Naviglio - MI",
    "Robella - AT",
    "Robilante - CN",
    "Roburent - CN",
    "Rocca Canavese - TO",
    "Rocca Canterano - RM",
    "Rocca Cigliè - CN",
    "Rocca d'Arazzo - AT",
    "Rocca d'Arce - FR",
    "Rocca de' Baldi - CN",
    "Rocca de' Giorgi - PV",
    "Rocca D'Evandro - CE",
    "Rocca di Botte - AQ",
    "Rocca di Cambio - AQ",
    "Rocca di Cave - RM",
    "Rocca di Mezzo - AQ",
    "Rocca di Neto - KR",
    "Rocca di Papa - RM",
    "Rocca Grimalda - AL",
    "Rocca Imperiale - CS",
    "Rocca Massima - LT",
    "Rocca Pia - AQ",
    "Rocca Pietore - BL",
    "Rocca Priora - RM",
    "Rocca San Casciano - FC",
    "Rocca San Felice - AV",
    "Rocca San Giovanni - CH",
    "Rocca Santa Maria - TE",
    "Rocca Santo Stefano - RM",
    "Rocca Sinibalda - RI",
    "Rocca Susella - PV",
    "Roccabascerana - AV",
    "Roccabernarda - KR",
    "Roccabianca - PR",
    "Roccabruna - CN",
    "Roccacasale - AQ",
    "Roccadaspide - SA",
    "Roccafiorita - ME",
    "Roccafluvione - AP",
    "Roccaforte del Greco - RC",
    "Roccaforte Ligure - AL",
    "Roccaforte Mondovì - CN",
    "Roccaforzata - TA",
    "Roccafranca - BS",
    "Roccagiovine - RM",
    "Roccagloriosa - SA",
    "Roccagorga - LT",
    "Roccalbegna - GR",
    "Roccalumera - ME",
    "Roccamandolfi - IS",
    "Roccamena - PA",
    "Roccamonfina - CE",
    "Roccamontepiano - CH",
    "Roccamorice - PE",
    "Roccanova - PZ",
    "Roccantica - RI",
    "Roccapalumba - PA",
    "Roccapiemonte - SA",
    "Roccarainola - NA",
    "Roccaraso - AQ",
    "Roccaromana - CE",
    "Roccascalegna - CH",
    "Roccasecca - FR",
    "Roccasecca dei Volsci - LT",
    "Roccasicura - IS",
    "Roccasparvera - CN",
    "Roccaspinalveti - CH",
    "Roccastrada - GR",
    "Roccavaldina - ME",
    "Roccaverano - AT",
    "Roccavignale - SV",
    "Roccavione - CN",
    "Roccavivara - CB",
    "Roccella Ionica - RC",
    "Roccella Valdemone - ME",
    "Rocchetta a Volturno - IS",
    "Rocchetta Belbo - CN",
    "Rocchetta di Vara - SP",
    "Rocchetta e Croce - CE",
    "Rocchetta Ligure - AL",
    "Rocchetta Nervina - IM",
    "Rocchetta Palafea - AT",
    "Rocchetta Sant'Antonio - FG",
    "Rocchetta Tanaro - AT",
    "Rodano - MI",
    "Roddi - CN",
    "Roddino - CN",
    "Rodello - CN",
    "Rodengo - BZ",
    "Rodengo Saiano - BS",
    "Rodero - CO",
    "Rodi Garganico - FG",
    "Rodì Milici - ME",
    "Rodigo - MN",
    "Roè Volciano - BS",
    "Rofrano - SA",
    "Rogeno - LC",
    "Roggiano Gravina - CS",
    "Roghudi - RC",
    "Rogliano - CS",
    "Rognano - PV",
    "Rogno - BG",
    "Rogolo - SO",
    "Roiate - RM",
    "Roio del Sangro - CH",
    "Roisan - AO",
    "Roletto - TO",
    "Rolo - RE",
    "Roma - RM",
    "Romagnano al Monte - SA",
    "Romagnano Sesia - NO",
    "Romagnese - PV",
    "Romallo - TN",
    "Romana - SS",
    "Romanengo - CR",
    "Romano Canavese - TO",
    "Romano d'Ezzelino - VI",
    "Romano di Lombardia - BG",
    "Romans d'Isonzo - GO",
    "Rombiolo - VV",
    "Romeno - TN",
    "Romentino - NO",
    "Rometta - ME",
    "Ronago - CO",
    "Roncà - VR",
    "Roncade - TV",
    "Roncadelle - BS",
    "Roncaro - PV",
    "Roncegno Terme - TN",
    "Roncello - MB",
    "Ronchi dei Legionari - GO",
    "Ronchi Valsugana - TN",
    "Ronchis - UD",
    "Ronciglione - VT",
    "Ronco all'Adige - VR",
    "Ronco Biellese - BI",
    "Ronco Briantino - MB",
    "Ronco Canavese - TO",
    "Ronco Scrivia - GE",
    "Roncobello - BG",
    "Roncoferraro - MN",
    "Roncofreddo - FC",
    "Roncola - BG",
    "Roncone - TN",
    "Rondanina - GE",
    "Rondissone - TO",
    "Ronsecco - VC",
    "Ronzo-Chienis - TN",
    "Ronzone - TN",
    "Roppolo - BI",
    "Rorà - TO",
    "Rosà - VI",
    "Rosarno - RC",
    "Rosasco - PV",
    "Rosate - MI",
    "Rosazza - BI",
    "Rosciano - PE",
    "Roscigno - SA",
    "Rose - CS",
    "Rosello - CH",
    "Roseto Capo Spulico - CS",
    "Roseto degli Abruzzi - TE",
    "Roseto Valfortore - FG",
    "Rosignano Marittimo - LI",
    "Rosignano Monferrato - AL",
    "Rosolina - RO",
    "Rosolini - SR",
    "Rosora - AN",
    "Rossa - VC",
    "Rossana - CN",
    "Rossano - CS",
    "Rossano Veneto - VI",
    "Rossiglione - GE",
    "Rosta - TO",
    "Rota d'Imagna - BG",
    "Rota Greca - CS",
    "Rotella - AP",
    "Rotello - CB",
    "Rotonda - PZ",
    "Rotondella - MT",
    "Rotondi - AV",
    "Rottofreno - PC",
    "Rotzo - VI",
    "Roure - TO",
    "Rovagnate - LC",
    "Rovasenda - VC",
    "Rovato - BS",
    "Rovegno - GE",
    "Rovellasca - CO",
    "Rovello Porro - CO",
    "Roverbella - MN",
    "Roverchiara - VR",
    "Roverè della Luna - TN",
    "Roverè Veronese - VR",
    "Roveredo di Guà - VR",
    "Roveredo in Piano - PN",
    "Rovereto - TN",
    "Rovescala - PV",
    "Rovetta - BG",
    "Roviano - RM",
    "Rovigo - RO",
    "Rovito - CS",
    "Rovolon - PD",
    "Rozzano - MI",
    "Rubano - PD",
    "Rubiana - TO",
    "Rubiera - RE",
    "Ruda - UD",
    "Rudiano - BS",
    "Rueglio - TO",
    "Ruffano - LE",
    "Ruffia - CN",
    "Ruffrè-Mendola - TN",
    "Rufina - FI",
    "Ruinas - OR",
    "Ruino - PV",
    "Rumo - TN",
    "Ruoti - PZ",
    "Russi - RA",
    "Rutigliano - BA",
    "Rutino - SA",
    "Ruviano - CE",
    "Ruvo del Monte - PZ",
    "Ruvo di Puglia - BA",
    "Sabaudia - LT",
    "Sabbia - VC",
    "Sabbio Chiese - BS",
    "Sabbioneta - MN",
    "Sacco - SA",
    "Saccolongo - PD",
    "Sacile - PN",
    "Sacrofano - RM",
    "Sadali - CA",
    "Sagama - OR",
    "Sagliano Micca - BI",
    "Sagrado - GO",
    "Sagron Mis - TN",
    "Saint-Christophe - AO",
    "Saint-Denis - AO",
    "Saint-Marcel - AO",
    "Saint-Nicolas - AO",
    "Saint-Oyen - AO",
    "Saint-Pierre - AO",
    "Saint-Rhémy-en-Bosses - AO",
    "Saint-Vincent - AO",
    "Sala Baganza - PR",
    "Sala Biellese - BI",
    "Sala Bolognese - BO",
    "Sala Comacina - CO",
    "Sala Consilina - SA",
    "Sala Monferrato - AL",
    "Salandra - MT",
    "Salaparuta - TP",
    "Salara - RO",
    "Salasco - VC",
    "Salassa - TO",
    "Salbertrand - TO",
    "Salcedo - VI",
    "Salcito - CB",
    "Sale - AL",
    "Sale delle Langhe - CN",
    "Sale Marasino - BS",
    "Sale San Giovanni - CN",
    "Salemi - TP",
    "Salento - SA",
    "Salerano Canavese - TO",
    "Salerano sul Lambro - LO",
    "Salerno - SA",
    "Saletto - PD",
    "Salgareda - TV",
    "Sali Vercellese - VC",
    "Salice Salentino - LE",
    "Saliceto - CN",
    "Salisano - RI",
    "Salizzole - VR",
    "Salle - PE",
    "Salmour - CN",
    "Salò - BS",
    "Salorno - BZ",
    "Salsomaggiore Terme - PR",
    "Saltara - PU",
    "Saltrio - VA",
    "Saludecio - RN",
    "Saluggia - VC",
    "Salussola - BI",
    "Saluzzo - CN",
    "Salve - LE",
    "Salvirola - CR",
    "Salvitelle - SA",
    "Salza di Pinerolo - TO",
    "Salza Irpina - AV",
    "Salzano - VE",
    "Samarate - VA",
    "Samassi - VS",
    "Samatzai - CA",
    "Sambuca di Sicilia - AG",
    "Sambuca Pistoiese - PT",
    "Sambuci - RM",
    "Sambuco - CN",
    "Sammichele di Bari - BA",
    "Samo - RC",
    "Samolaco - SO",
    "Samone - TO",
    "Samone - TN",
    "Sampeyre - CN",
    "Samugheo - OR",
    "San Bartolomeo al Mare - IM",
    "San Bartolomeo in Galdo - BN",
    "San Bartolomeo Val Cavargna - CO",
    "San Basile - CS",
    "San Basilio - CA",
    "San Bassano - CR",
    "San Bellino - RO",
    "San Benedetto Belbo - CN",
    "San Benedetto dei Marsi - AQ",
    "San Benedetto del Tronto - AP",
    "San Benedetto in Perillis - AQ",
    "San Benedetto Po - MN",
    "San Benedetto Ullano - CS",
    "San Benedetto Val di Sambro - BO",
    "San Benigno Canavese - TO",
    "San Bernardino Verbano - VB",
    "San Biagio della Cima - IM",
    "San Biagio di Callalta - TV",
    "San Biagio Platani - AG",
    "San Biagio Saracinisco - FR",
    "San Biase - CB",
    "San Bonifacio - VR",
    "San Buono - CH",
    "San Calogero - VV",
    "San Candido - BZ",
    "San Canzian d'Isonzo - GO",
    "San Carlo Canavese - TO",
    "San Casciano dei Bagni - SI",
    "San Casciano in Val di Pesa - FI",
    "San Cassiano - LE",
    "San Cataldo - CL",
    "San Cesareo - RM",
    "San Cesario di Lecce - LE",
    "San Cesario sul Panaro - MO",
    "San Chirico Nuovo - PZ",
    "San Chirico Raparo - PZ",
    "San Cipirello - PA",
    "San Cipriano d'Aversa - CE",
    "San Cipriano Picentino - SA",
    "San Cipriano Po - PV",
    "San Clemente - RN",
    "San Colombano al Lambro - MI",
    "San Colombano Belmonte - TO",
    "San Colombano Certenoli - GE",
    "San Cono - CT",
    "San Cosmo Albanese - CS",
    "San Costantino Albanese - PZ",
    "San Costantino Calabro - VV",
    "San Costanzo - PU",
    "San Cristoforo - AL",
    "San Damiano al Colle - PV",
    "San Damiano d'Asti - AT",
    "San Damiano Macra - CN",
    "San Daniele del Friuli - UD",
    "San Daniele Po - CR",
    "San Demetrio Corone - CS",
    "San Demetrio ne' Vestini - AQ",
    "San Didero - TO",
    "San Donà di Piave - VE",
    "San Donaci - BR",
    "San Donato di Lecce - LE",
    "San Donato di Ninea - CS",
    "San Donato Milanese - MI",
    "San Donato Val di Comino - FR",
    "San Dorligo della Valle - Dolina - TS",
    "San Fedele Intelvi - CO",
    "San Fele - PZ",
    "San Felice a Cancello - CE",
    "San Felice Circeo - LT",
    "San Felice del Benaco - BS",
    "San Felice del Molise - CB",
    "San Felice sul Panaro - MO",
    "San Ferdinando - RC",
    "San Ferdinando di Puglia - BT",
    "San Fermo della Battaglia - CO",
    "San Fili - CS",
    "San Filippo del Mela - ME",
    "San Fior - TV",
    "San Fiorano - LO",
    "San Floriano del Collio - GO",
    "San Floro - CZ",
    "San Francesco al Campo - TO",
    "San Fratello - ME",
    "San Gavino Monreale - VS",
    "San Gemini - TR",
    "San Genesio Atesino - BZ",
    "San Genesio ed Uniti - PV",
    "San Gennaro Vesuviano - NA",
    "San Germano Chisone - TO",
    "San Germano dei Berici - VI",
    "San Germano Vercellese - VC",
    "San Gervasio Bresciano - BS",
    "San Giacomo degli Schiavoni - CB",
    "San Giacomo delle Segnate - MN",
    "San Giacomo Filippo - SO",
    "San Giacomo Vercellese - VC",
    "San Gillio - TO",
    "San Gimignano - SI",
    "San Ginesio - MC",
    "San Giorgio a Cremano - NA",
    "San Giorgio a Liri - FR",
    "San Giorgio Albanese - CS",
    "San Giorgio Canavese - TO",
    "San Giorgio del Sannio - BN",
    "San Giorgio della Richinvelda - PN",
    "San Giorgio delle Pertiche - PD",
    "San Giorgio di Lomellina - PV",
    "San Giorgio di Mantova - MN",
    "San Giorgio di Nogaro - UD",
    "San Giorgio di Pesaro - PU",
    "San Giorgio di Piano - BO",
    "San Giorgio in Bosco - PD",
    "San Giorgio Ionico - TA",
    "San Giorgio La Molara - BN",
    "San Giorgio Lucano - MT",
    "San Giorgio Monferrato - AL",
    "San Giorgio Morgeto - RC",
    "San Giorgio Piacentino - PC",
    "San Giorgio Scarampi - AT",
    "San Giorgio su Legnano - MI",
    "San Giorio di Susa - TO",
    "San Giovanni a Piro - SA",
    "San Giovanni al Natisone - UD",
    "San Giovanni Bianco - BG",
    "San Giovanni d'Asso - SI",
    "San Giovanni del Dosso - MN",
    "San Giovanni di Gerace - RC",
    "San Giovanni Gemini - AG",
    "San Giovanni Ilarione - VR",
    "San Giovanni in Croce - CR",
    "San Giovanni in Fiore - CS",
    "San Giovanni in Galdo - CB",
    "San Giovanni in Marignano - RN",
    "San Giovanni in Persiceto - BO",
    "San Giovanni Incarico - FR",
    "San Giovanni la Punta - CT",
    "San Giovanni Lipioni - CH",
    "San Giovanni Lupatoto - VR",
    "San Giovanni Rotondo - FG",
    "San Giovanni Suergiu - CI",
    "San Giovanni Teatino - CH",
    "San Giovanni Valdarno - AR",
    "San Giuliano del Sannio - CB",
    "San Giuliano di Puglia - CB",
    "San Giuliano Milanese - MI",
    "San Giuliano Terme - PI",
    "San Giuseppe Jato - PA",
    "San Giuseppe Vesuviano - NA",
    "San Giustino - PG",
    "San Giusto Canavese - TO",
    "San Godenzo - FI",
    "San Gregorio da Sassola - RM",
    "San Gregorio di Catania - CT",
    "San Gregorio d'Ippona - VV",
    "San Gregorio Magno - SA",
    "San Gregorio Matese - CE",
    "San Gregorio nelle Alpi - BL",
    "San Lazzaro di Savena - BO",
    "San Leo - RN",
    "San Leonardo - UD",
    "San Leonardo in Passiria - BZ",
    "San Leucio del Sannio - BN",
    "San Lorenzello - BN",
    "San Lorenzo - RC",
    "San Lorenzo al Mare - IM",
    "San Lorenzo Bellizzi - CS",
    "San Lorenzo del Vallo - CS",
    "San Lorenzo di Sebato - BZ",
    "San Lorenzo in Banale - TN",
    "San Lorenzo in Campo - PU",
    "San Lorenzo Isontino - GO",
    "San Lorenzo Maggiore - BN",
    "San Lorenzo Nuovo - VT",
    "San Luca - RC",
    "San Lucido - CS",
    "San Lupo - BN",
    "San Mango d'Aquino - CZ",
    "San Mango Piemonte - SA",
    "San Mango sul Calore - AV",
    "San Marcellino - CE",
    "San Marcello - AN",
    "San Marcello Pistoiese - PT",
    "San Marco Argentano - CS",
    "San Marco D'Alunzio - ME",
    "San Marco dei Cavoti - BN",
    "San Marco Evangelista - CE",
    "San Marco in Lamis - FG",
    "San Marco la Catola - FG",
    "San Martino al Tagliamento - PN",
    "San Martino Alfieri - AT",
    "San Martino Buon Albergo - VR",
    "San Martino Canavese - TO",
    "San Martino d'Agri - PZ",
    "San Martino dall'Argine - MN",
    "San Martino del Lago - CR",
    "San Martino di Finita - CS",
    "San Martino di Lupari - PD",
    "San Martino di Venezze - RO",
    "San Martino in Badia - BZ",
    "San Martino in Passiria - BZ",
    "San Martino in Pensilis - CB",
    "San Martino in Rio - RE",
    "San Martino in Strada - LO",
    "San Martino Sannita - BN",
    "San Martino Siccomario - PV",
    "San Martino sulla Marrucina - CH",
    "San Martino Valle Caudina - AV",
    "San Marzano di San Giuseppe - TA",
    "San Marzano Oliveto - AT",
    "San Marzano sul Sarno - SA",
    "San Massimo - CB",
    "San Maurizio Canavese - TO",
    "San Maurizio d'Opaglio - NO",
    "San Mauro Castelverde - PA",
    "San Mauro Cilento - SA",
    "San Mauro di Saline - VR",
    "San Mauro Forte - MT",
    "San Mauro La Bruca - SA",
    "San Mauro Marchesato - KR",
    "San Mauro Pascoli - FC",
    "San Mauro Torinese - TO",
    "San Michele al Tagliamento - VE",
    "San Michele all'Adige - TN",
    "San Michele di Ganzaria - CT",
    "San Michele di Serino - AV",
    "San Michele Mondovì - CN",
    "San Michele Salentino - BR",
    "San Miniato - PI",
    "San Nazario - VI",
    "San Nazzaro - BN",
    "San Nazzaro Sesia - NO",
    "San Nazzaro Val Cavargna - CO",
    "San Nicandro Garganico - FG",
    "San Nicola Arcella - CS",
    "San Nicola Baronia - AV",
    "San Nicola da Crissa - VV",
    "San Nicola dell'Alto - KR",
    "San Nicola la Strada - CE",
    "San Nicola Manfredi - BN",
    "San Nicolò d'Arcidano - OR",
    "San Nicolò di Comelico - BL",
    "San Nicolò Gerrei - CA",
    "San Pancrazio - BZ",
    "San Pancrazio Salentino - BR",
    "San Paolo - BS",
    "San Paolo Albanese - PZ",
    "San Paolo Bel Sito - NA",
    "San Paolo Cervo - BI",
    "San Paolo d'Argon - BG",
    "San Paolo di Civitate - FG",
    "San Paolo di Jesi - AN",
    "San Paolo Solbrito - AT",
    "San Pellegrino Terme - BG",
    "San Pier d'Isonzo - GO",
    "San Pier Niceto - ME",
    "San Piero a Sieve - FI",
    "San Piero Patti - ME",
    "San Pietro a Maida - CZ",
    "San Pietro al Natisone - UD",
    "San Pietro al Tanagro - SA",
    "San Pietro Apostolo - CZ",
    "San Pietro Avellana - IS",
    "San Pietro Clarenza - CT",
    "San Pietro di Cadore - BL",
    "San Pietro di Caridà - RC",
    "San Pietro di Feletto - TV",
    "San Pietro di Morubio - VR",
    "San Pietro in Amantea - CS",
    "San Pietro in Cariano - VR",
    "San Pietro in Casale - BO",
    "San Pietro in Cerro - PC",
    "San Pietro in Gu - PD",
    "San Pietro in Guarano - CS",
    "San Pietro in Lama - LE",
    "San Pietro Infine - CE",
    "San Pietro Mosezzo - NO",
    "San Pietro Mussolino - VI",
    "San Pietro Val Lemina - TO",
    "San Pietro Vernotico - BR",
    "San Pietro Viminario - PD",
    "San Pio delle Camere - AQ",
    "San Polo dei Cavalieri - RM",
    "San Polo d'Enza - RE",
    "San Polo di Piave - TV",
    "San Polo Matese - CB",
    "San Ponso - TO",
    "San Possidonio - MO",
    "San Potito Sannitico - CE",
    "San Potito Ultra - AV",
    "San Prisco - CE",
    "San Procopio - RC",
    "San Prospero - MO",
    "San Quirico d'Orcia - SI",
    "San Quirino - PN",
    "San Raffaele Cimena - TO",
    "San Roberto - RC",
    "San Rocco al Porto - LO",
    "San Romano in Garfagnana - LU",
    "San Rufo - SA",
    "San Salvatore di Fitalia - ME",
    "San Salvatore Monferrato - AL",
    "San Salvatore Telesino - BN",
    "San Salvo - CH",
    "San Sebastiano al Vesuvio - NA",
    "San Sebastiano Curone - AL",
    "San Sebastiano da Po - TO",
    "San Secondo di Pinerolo - TO",
    "San Secondo Parmense - PR",
    "San Severino Lucano - PZ",
    "San Severino Marche - MC",
    "San Severo - FG",
    "San Siro - CO",
    "San Sossio Baronia - AV",
    "San Sostene - CZ",
    "San Sosti - CS",
    "San Sperate - CA",
    "San Tammaro - CE",
    "San Teodoro - ME",
    "San Teodoro - OT",
    "San Tomaso Agordino - BL",
    "San Valentino in Abruzzo Citeriore - PE",
    "San Valentino Torio - SA",
    "San Venanzo - TR",
    "San Vendemiano - TV",
    "San Vero Milis - OR",
    "San Vincenzo - LI",
    "San Vincenzo la Costa - CS",
    "San Vincenzo Valle Roveto - AQ",
    "San Vitaliano - NA",
    "San Vito - CA",
    "San Vito al Tagliamento - PN",
    "San Vito al Torre - UD",
    "San Vito Chietino - CH",
    "San Vito dei Normanni - BR",
    "San Vito di Cadore - BL",
    "San Vito di Fagagna - UD",
    "San Vito di Leguzzano - VI",
    "San Vito Lo Capo - TP",
    "San Vito Romano - RM",
    "San Vito sullo Ionio - CZ",
    "San Vittore del Lazio - FR",
    "San Vittore Olona - MI",
    "San Zeno di Montagna - VR",
    "San Zeno Naviglio - BS",
    "San Zenone al Lambro - MI",
    "San Zenone al Po - PV",
    "San Zenone degli Ezzelini - TV",
    "Sanarica - LE",
    "Sandigliano - BI",
    "Sandrigo - VI",
    "Sanfrè - CN",
    "Sanfront - CN",
    "Sangano - TO",
    "Sangiano - VA",
    "Sangineto - CS",
    "Sanguinetto - VR",
    "Sanluri - VS",
    "Sannazzaro de' Burgondi - PV",
    "Sannicandro di Bari - BA",
    "Sannicola - LE",
    "Sanremo - IM",
    "Sansepolcro - AR",
    "Santa Brigida - BG",
    "Santa Caterina Albanese - CS",
    "Santa Caterina dello Ionio - CZ",
    "Santa Caterina Villarmosa - CL",
    "Santa Cesarea Terme - LE",
    "Santa Cristina d'Aspromonte - RC",
    "Santa Cristina e Bissone - PV",
    "Santa Cristina Gela - PA",
    "Santa Cristina Valgardena - BZ",
    "Santa Croce Camerina - RG",
    "Santa Croce del Sannio - BN",
    "Santa Croce di Magliano - CB",
    "Santa Croce sull'Arno - PI",
    "Santa Domenica Talao - CS",
    "Santa Domenica Vittoria - ME",
    "Santa Elisabetta - AG",
    "Santa Fiora - GR",
    "Santa Flavia - PA",
    "Santa Giuletta - PV",
    "Santa Giusta - OR",
    "Santa Giustina - BL",
    "Santa Giustina in Colle - PD",
    "Santa Luce - PI",
    "Santa Lucia del Mela - ME",
    "Santa Lucia di Piave - TV",
    "Santa Lucia di Serino - AV",
    "Santa Margherita d'Adige - PD",
    "Santa Margherita di Belice - AG",
    "Santa Margherita di Staffora - PV",
    "Santa Margherita Ligure - GE",
    "Santa Maria a Monte - PI",
    "Santa Maria a Vico - CE",
    "Santa Maria Capua Vetere - CE",
    "Santa Maria Coghinas - SS",
    "Santa Maria del Cedro - CS",
    "Santa Maria del Molise - IS",
    "Santa Maria della Versa - PV",
    "Santa Maria di Licodia - CT",
    "Santa Maria di Sala - VE",
    "Santa Maria Hoè - LC",
    "Santa Maria Imbaro - CH",
    "Santa Maria la Carità - NA",
    "Santa Maria La Fossa - CE",
    "Santa Maria La Longa - UD",
    "Santa Maria Maggiore - VB",
    "Santa Maria Nuova - AN",
    "Santa Marina - SA",
    "Santa Marina Salina - ME",
    "Santa Marinella - RM",
    "Santa Ninfa - TP",
    "Santa Paolina - AV",
    "Santa Severina - KR",
    "Santa Sofia - FC",
    "Santa Sofia D'Epiro - CS",
    "Santa Teresa di Riva - ME",
    "Santa Teresa Gallura - OT",
    "Santa Venerina - CT",
    "Santa Vittoria d'Alba - CN",
    "Santa Vittoria in Matenano - FM",
    "Santadi - CI",
    "Sant'Agapito - IS",
    "Sant'Agata Bolognese - BO",
    "Sant'Agata De' Goti - BN",
    "Sant'Agata del Bianco - RC",
    "Sant'Agata di Esaro - CS",
    "Sant'Agata di Militello - ME",
    "Sant'Agata di Puglia - FG",
    "Sant'Agata Feltria - RN",
    "Sant'Agata Fossili - AL",
    "Sant'Agata Li Battiati - CT",
    "Sant'Agata sul Santerno - RA",
    "Sant'Agnello - NA",
    "Sant'Agostino - FE",
    "Sant'Albano Stura - CN",
    "Sant'Alessio con Vialone - PV",
    "Sant'Alessio in Aspromonte - RC",
    "Sant'Alessio Siculo - ME",
    "Sant'Alfio - CT",
    "Sant'Ambrogio di Torino - TO",
    "Sant'Ambrogio di Valpolicella - VR",
    "Sant'Ambrogio sul Garigliano - FR",
    "Sant'Anastasia - NA",
    "Sant'Anatolia di Narco - PG",
    "Sant'Andrea Apostolo dello Ionio - CZ",
    "Sant'Andrea del Garigliano - FR",
    "Sant'Andrea di Conza - AV",
    "Sant'Andrea Frius - CA",
    "Sant'Angelo a Cupolo - BN",
    "Sant'Angelo a Fasanella - SA",
    "Sant'Angelo a Scala - AV",
    "Sant'Angelo all'Esca - AV",
    "Sant'Angelo d'Alife - CE",
    "Sant'Angelo dei Lombardi - AV",
    "Sant'Angelo del Pesco - IS",
    "Sant'Angelo di Brolo - ME",
    "Sant'Angelo di Piove di Sacco - PD",
    "Sant'Angelo in Lizzola - PU",
    "Sant'Angelo in Pontano - MC",
    "Sant'Angelo in Vado - PU",
    "Sant'Angelo Le Fratte - PZ",
    "Sant'Angelo Limosano - CB",
    "Sant'Angelo Lodigiano - LO",
    "Sant'Angelo Lomellina - PV",
    "Sant'Angelo Muxaro - AG",
    "Sant'Angelo Romano - RM",
    "Sant'Anna Arresi - CI",
    "Sant'Anna d'Alfaedo - VR",
    "Sant'Antimo - NA",
    "Sant'Antioco - CI",
    "Sant'Antonino di Susa - TO",
    "Sant'Antonio Abate - NA",
    "Sant'Antonio di Gallura - OT",
    "Sant'Apollinare - FR",
    "Sant'Arcangelo - PZ",
    "Santarcangelo di Romagna - RN",
    "Sant'Arcangelo Trimonte - BN",
    "Sant'Arpino - CE",
    "Sant'Arsenio - SA",
    "Sante Marie - AQ",
    "Sant'Egidio alla Vibrata - TE",
    "Sant'Egidio del Monte Albino - SA",
    "Sant'Elena - PD",
    "Sant'Elena Sannita - IS",
    "Sant'Elia a Pianisi - CB",
    "Sant'Elia Fiumerapido - FR",
    "Sant'Elpidio a Mare - FM",
    "Santena - TO",
    "Santeramo in Colle - BA",
    "Sant'Eufemia a Maiella - PE",
    "Sant'Eufemia d'Aspromonte - RC",
    "Sant'Eusanio del Sangro - CH",
    "Sant'Eusanio Forconese - AQ",
    "Santhià - VC",
    "Santi Cosma e Damiano - LT",
    "Sant'Ilario dello Ionio - RC",
    "Sant'Ilario d'Enza - RE",
    "Sant'Ippolito - PU",
    "Santo Stefano al Mare - IM",
    "Santo Stefano Belbo - CN",
    "Santo Stefano d'Aveto - GE",
    "Santo Stefano del Sole - AV",
    "Santo Stefano di Cadore - BL",
    "Santo Stefano di Camastra - ME",
    "Santo Stefano di Magra - SP",
    "Santo Stefano di Rogliano - CS",
    "Santo Stefano di Sessanio - AQ",
    "Santo Stefano in Aspromonte - RC",
    "Santo Stefano Lodigiano - LO",
    "Santo Stefano Quisquina - AG",
    "Santo Stefano Roero - CN",
    "Santo Stefano Ticino - MI",
    "Santo Stino di Livenza - VE",
    "Sant'Olcese - GE",
    "Santomenna - SA",
    "Sant'Omero - TE",
    "Sant'Omobono Terme - BG",
    "Sant'Onofrio - VV",
    "Santopadre - FR",
    "Sant'Oreste - RM",
    "Santorso - VI",
    "Sant'Orsola Terme - TN",
    "Santu Lussurgiu - OR",
    "Sant'Urbano - PD",
    "Sanza - SA",
    "Sanzeno - TN",
    "Saonara - PD",
    "Saponara - ME",
    "Sappada - BL",
    "Sapri - SA",
    "Saracena - CS",
    "Saracinesco - RM",
    "Sarcedo - VI",
    "Sarconi - PZ",
    "Sardara - VS",
    "Sardigliano - AL",
    "Sarego - VI",
    "Sarentino - BZ",
    "Sarezzano - AL",
    "Sarezzo - BS",
    "Sarmato - PC",
    "Sarmede - TV",
    "Sarnano - MC",
    "Sarnico - BG",
    "Sarno - SA",
    "Sarnonico - TN",
    "Saronno - VA",
    "Sarre - AO",
    "Sarroch - CA",
    "Sarsina - FC",
    "Sarteano - SI",
    "Sartirana Lomellina - PV",
    "Sarule - NU",
    "Sarzana - SP",
    "Sassano - SA",
    "Sassari - SS",
    "Sassello - SV",
    "Sassetta - LI",
    "Sassinoro - BN",
    "Sasso di Castalda - PZ",
    "Sasso Marconi - BO",
    "Sassocorvaro - PU",
    "Sassofeltrio - PU",
    "Sassoferrato - AN",
    "Sassuolo - MO",
    "Satriano - CZ",
    "Satriano di Lucania - PZ",
    "Sauris - UD",
    "Sauze di Cesana - TO",
    "Sauze d'Oulx - TO",
    "Sava - TA",
    "Savelli - KR",
    "Saviano - NA",
    "Savigliano - CN",
    "Savignano Irpino - AV",
    "Savignano sul Panaro - MO",
    "Savignano sul Rubicone - FC",
    "Savigno - BO",
    "Savignone - GE",
    "Saviore dell'Adamello - BS",
    "Savoca - ME",
    "Savogna - UD",
    "Savogna d'Isonzo - GO",
    "Savoia di Lucania - PZ",
    "Savona - SV",
    "Scafa - PE",
    "Scafati - SA",
    "Scagnello - CN",
    "Scala - SA",
    "Scala Coeli - CS",
    "Scaldasole - PV",
    "Scalea - CS",
    "Scalenghe - TO",
    "Scaletta Zanclea - ME",
    "Scampitella - AV",
    "Scandale - KR",
    "Scandiano - RE",
    "Scandicci - FI",
    "Scandolara Ravara - CR",
    "Scandolara Ripa d'Oglio - CR",
    "Scandriglia - RI",
    "Scanno - AQ",
    "Scano di Montiferro - OR",
    "Scansano - GR",
    "Scanzano Jonico - MT",
    "Scanzorosciate - BG",
    "Scapoli - IS",
    "Scarlino - GR",
    "Scarmagno - TO",
    "Scarnafigi - CN",
    "Scarperia - FI",
    "Scena - BZ",
    "Scerni - CH",
    "Scheggia e Pascelupo - PG",
    "Scheggino - PG",
    "Schiavi di Abruzzo - CH",
    "Schiavon - VI",
    "Schignano - CO",
    "Schilpario - BG",
    "Schio - VI",
    "Schivenoglia - MN",
    "Sciacca - AG",
    "Sciara - PA",
    "Scicli - RG",
    "Scido - RC",
    "Scigliano - CS",
    "Scilla - RC",
    "Scillato - PA",
    "Sciolze - TO",
    "Scisciano - NA",
    "Sclafani Bagni - PA",
    "Scontrone - AQ",
    "Scopa - VC",
    "Scopello - VC",
    "Scoppito - AQ",
    "Scordia - CT",
    "Scorrano - LE",
    "Scorzè - VE",
    "Scurcola Marsicana - AQ",
    "Scurelle - TN",
    "Scurzolengo - AT",
    "Seborga - IM",
    "Secinaro - AQ",
    "Seclì - LE",
    "Secugnago - LO",
    "Sedegliano - UD",
    "Sedico - BL",
    "Sedilo - OR",
    "Sedini - SS",
    "Sedriano - MI",
    "Sedrina - BG",
    "Sefro - MC",
    "Segariu - VS",
    "Seggiano - GR",
    "Segni - RM",
    "Segonzano - TN",
    "Segrate - MI",
    "Segusino - TV",
    "Selargius - CA",
    "Selci - RI",
    "Selegas - CA",
    "Sellano - PG",
    "Sellero - BS",
    "Sellia - CZ",
    "Sellia Marina - CZ",
    "Selva dei Molini - BZ",
    "Selva di Cadore - BL",
    "Selva di Progno - VR",
    "Selva di Val Gardena - BZ",
    "Selvazzano Dentro - PD",
    "Selve Marcone - BI",
    "Selvino - BG",
    "Semestene - SS",
    "Semiana - PV",
    "Seminara - RC",
    "Semproniano - GR",
    "Senago - MI",
    "Senales - BZ",
    "Senale-San Felice - BZ",
    "Seneghe - OR",
    "Senerchia - AV",
    "Seniga - BS",
    "Senigallia - AN",
    "Senis - OR",
    "Senise - PZ",
    "Senna Comasco - CO",
    "Senna Lodigiana - LO",
    "Sennariolo - OR",
    "Sennori - SS",
    "Senorbì - CA",
    "Sepino - CB",
    "Seppiana - VB",
    "Sequals - PN",
    "Seravezza - LU",
    "Serdiana - CA",
    "Seregno - MB",
    "Seren del Grappa - BL",
    "Sergnano - CR",
    "Seriate - BG",
    "Serina - BG",
    "Serino - AV",
    "Serle - BS",
    "Sermide - MN",
    "Sermoneta - LT",
    "Sernaglia della Battaglia - TV",
    "Sernio - SO",
    "Serole - AT",
    "Serra d'Aiello - CS",
    "Serra de' Conti - AN",
    "Serra Pedace - CS",
    "Serra Riccò - GE",
    "Serra San Bruno - VV",
    "Serra San Quirico - AN",
    "Serra Sant'Abbondio - PU",
    "Serracapriola - FG",
    "Serradifalco - CL",
    "Serralunga d'Alba - CN",
    "Serralunga di Crea - AL",
    "Serramanna - VS",
    "Serramazzoni - MO",
    "Serramezzana - SA",
    "Serramonacesca - PE",
    "Serrapetrona - MC",
    "Serrara Fontana - NA",
    "Serrastretta - CZ",
    "Serrata - RC",
    "Serravalle a Po - MN",
    "Serravalle di Chienti - MC",
    "Serravalle Langhe - CN",
    "Serravalle Pistoiese - PT",
    "Serravalle Scrivia - AL",
    "Serravalle Sesia - VC",
    "Serre - SA",
    "Serrenti - VS",
    "Serri - CA",
    "Serrone - FR",
    "Serrungarina - PU",
    "Sersale - CZ",
    "Servigliano - FM",
    "Sessa Aurunca - CE",
    "Sessa Cilento - SA",
    "Sessame - AT",
    "Sessano del Molise - IS",
    "Sesta Godano - SP",
    "Sestino - AR",
    "Sesto - BZ",
    "Sesto al Reghena - PN",
    "Sesto Calende - VA",
    "Sesto Campano - IS",
    "Sesto ed Uniti - CR",
    "Sesto Fiorentino - FI",
    "Sesto San Giovanni - MI",
    "Sestola - MO",
    "Sestri Levante - GE",
    "Sestriere - TO",
    "Sestu - CA",
    "Settala - MI",
    "Settefrati - FR",
    "Settime - AT",
    "Settimo Milanese - MI",
    "Settimo Rottaro - TO",
    "Settimo San Pietro - CA",
    "Settimo Torinese - TO",
    "Settimo Vittone - TO",
    "Settingiano - CZ",
    "Setzu - VS",
    "Seui - OG",
    "Seulo - CA",
    "Seveso - MB",
    "Sezzadio - AL",
    "Sezze - LT",
    "Sfruz - TN",
    "Sgonico - TS",
    "Sgurgola - FR",
    "Siamaggiore - OR",
    "Siamanna - OR",
    "Siano - SA",
    "Siapiccia - OR",
    "Sicignano degli Alburni - SA",
    "Siculiana - AG",
    "Siddi - VS",
    "Siderno - RC",
    "Siena - SI",
    "Sigillo - PG",
    "Signa - FI",
    "Silandro - BZ",
    "Silanus - NU",
    "Silea - TV",
    "Siligo - SS",
    "Siliqua - CA",
    "Silius - CA",
    "Sillano - LU",
    "Sillavengo - NO",
    "Silvano d'Orba - AL",
    "Silvano Pietra - PV",
    "Silvi - TE",
    "Simala - OR",
    "Simaxis - OR",
    "Simbario - VV",
    "Simeri Crichi - CZ",
    "Sinagra - ME",
    "Sinalunga - SI",
    "Sindia - NU",
    "Sini - OR",
    "Sinio - CN",
    "Siniscola - NU",
    "Sinnai - CA",
    "Sinopoli - RC",
    "Siracusa - SR",
    "Sirignano - AV",
    "Siris - OR",
    "Sirmione - BS",
    "Sirolo - AN",
    "Sirone - LC",
    "Siror - TN",
    "Sirtori - LC",
    "Sissa - PR",
    "Siurgus Donigala - CA",
    "Siziano - PV",
    "Sizzano - NO",
    "Sluderno - BZ",
    "Smarano - TN",
    "Smerillo - FM",
    "Soave - VR",
    "Socchieve - UD",
    "Soddì - OR",
    "Sogliano al Rubicone - FC",
    "Sogliano Cavour - LE",
    "Soglio - AT",
    "Soiano del Lago - BS",
    "Solagna - VI",
    "Solarino - SR",
    "Solaro - MI",
    "Solarolo - RA",
    "Solarolo Rainerio - CR",
    "Solarussa - OR",
    "Solbiate - CO",
    "Solbiate Arno - VA",
    "Solbiate Olona - VA",
    "Soldano - IM",
    "Soleminis - CA",
    "Solero - AL",
    "Solesino - PD",
    "Soleto - LE",
    "Solferino - MN",
    "Soliera - MO",
    "Solignano - PR",
    "Solofra - AV",
    "Solonghello - AL",
    "Solopaca - BN",
    "Solto Collina - BG",
    "Solza - BG",
    "Somaglia - LO",
    "Somano - CN",
    "Somma Lombardo - VA",
    "Somma Vesuviana - NA",
    "Sommacampagna - VR",
    "Sommariva del Bosco - CN",
    "Sommariva Perno - CN",
    "Sommatino - CL",
    "Sommo - PV",
    "Sona - VR",
    "Soncino - CR",
    "Sondalo - SO",
    "Sondrio - SO",
    "Songavazzo - BG",
    "Sonico - BS",
    "Sonnino - LT",
    "Soprana - BI",
    "Sora - FR",
    "Soraga - TN",
    "Soragna - PR",
    "Sorano - GR",
    "Sorbo San Basile - CZ",
    "Sorbo Serpico - AV",
    "Sorbolo - PR",
    "Sordevolo - BI",
    "Sordio - LO",
    "Soresina - CR",
    "Sorgà - VR",
    "Sorgono - NU",
    "Sori - GE",
    "Sorianello - VV",
    "Soriano Calabro - VV",
    "Soriano nel Cimino - VT",
    "Sorico - CO",
    "Soriso - NO",
    "Sorisole - BG",
    "Sormano - CO",
    "Sorradile - OR",
    "Sorrento - NA",
    "Sorso - SS",
    "Sortino - SR",
    "Sospiro - CR",
    "Sospirolo - BL",
    "Sossano - VI",
    "Sostegno - BI",
    "Sotto il Monte Giovanni XXIII - BG",
    "Sover - TN",
    "Soverato - CZ",
    "Sovere - BG",
    "Soveria Mannelli - CZ",
    "Soveria Simeri - CZ",
    "Soverzene - BL",
    "Sovicille - SI",
    "Sovico - MB",
    "Sovizzo - VI",
    "Sovramonte - BL",
    "Sozzago - NO",
    "Spadafora - ME",
    "Spadola - VV",
    "Sparanise - CE",
    "Sparone - TO",
    "Specchia - LE",
    "Spello - PG",
    "Spera - TN",
    "Sperlinga - EN",
    "Sperlonga - LT",
    "Sperone - AV",
    "Spessa - PV",
    "Spezzano Albanese - CS",
    "Spezzano della Sila - CS",
    "Spezzano Piccolo - CS",
    "Spiazzo - TN",
    "Spigno Monferrato - AL",
    "Spigno Saturnia - LT",
    "Spilamberto - MO",
    "Spilimbergo - PN",
    "Spilinga - VV",
    "Spinadesco - CR",
    "Spinazzola - BT",
    "Spinea - VE",
    "Spineda - CR",
    "Spinete - CB",
    "Spineto Scrivia - AL",
    "Spinetoli - AP",
    "Spino d'Adda - CR",
    "Spinone al Lago - BG",
    "Spinoso - PZ",
    "Spirano - BG",
    "Spoleto - PG",
    "Spoltore - PE",
    "Spongano - LE",
    "Spormaggiore - TN",
    "Sporminore - TN",
    "Spotorno - SV",
    "Spresiano - TV",
    "Spriana - SO",
    "Squillace - CZ",
    "Squinzano - LE",
    "Staffolo - AN",
    "Stagno Lombardo - CR",
    "Staiti - RC",
    "Stalettì - CZ",
    "Stanghella - PD",
    "Staranzano - GO",
    "Statte - TA",
    "Stazzano - AL",
    "Stazzema - LU",
    "Stazzona - CO",
    "Stefanaconi - VV",
    "Stella - SV",
    "Stella Cilento - SA",
    "Stellanello - SV",
    "Stelvio - BZ",
    "Stenico - TN",
    "Sternatia - LE",
    "Stezzano - BG",
    "Stia - AR",
    "Stienta - RO",
    "Stigliano - MT",
    "Stignano - RC",
    "Stilo - RC",
    "Stimigliano - RI",
    "Stintino - SS",
    "Stio - SA",
    "Stornara - FG",
    "Stornarella - FG",
    "Storo - TN",
    "Stra - VE",
    "Stradella - PV",
    "Strambinello - TO",
    "Strambino - TO",
    "Strangolagalli - FR",
    "Stregna - UD",
    "Strembo - TN",
    "Stresa - VB",
    "Strevi - AL",
    "Striano - NA",
    "Strigno - TN",
    "Strona - BI",
    "Stroncone - TR",
    "Strongoli - KR",
    "Stroppiana - VC",
    "Stroppo - CN",
    "Strozza - BG",
    "Sturno - AV",
    "Suardi - PV",
    "Subbiano - AR",
    "Subiaco - RM",
    "Succivo - CE",
    "Sueglio - LC",
    "Suelli - CA",
    "Suello - LC",
    "Suisio - BG",
    "Sulbiate - MB",
    "Sulmona - AQ",
    "Sulzano - BS",
    "Sumirago - VA",
    "Summonte - AV",
    "Suni - OR",
    "Suno - NO",
    "Supersano - LE",
    "Supino - FR",
    "Surano - LE",
    "Surbo - LE",
    "Susa - TO",
    "Susegana - TV",
    "Sustinente - MN",
    "Sutera - CL",
    "Sutri - VT",
    "Sutrio - UD",
    "Suvereto - LI",
    "Suzzara - MN",
    "Taceno - LC",
    "Tadasuni - OR",
    "Taggia - IM",
    "Tagliacozzo - AQ",
    "Taglio di Po - RO",
    "Tagliolo Monferrato - AL",
    "Taibon Agordino - BL",
    "Taino - VA",
    "Taio - TN",
    "Taipana - UD",
    "Talamello - RN",
    "Talamona - SO",
    "Talana - OG",
    "Taleggio - BG",
    "Talla - AR",
    "Talmassons - UD",
    "Tambre - BL",
    "Taormina - ME",
    "Tarano - RI",
    "Taranta Peligna - CH",
    "Tarantasca - CN",
    "Taranto - TA",
    "Tarcento - UD",
    "Tarquinia - VT",
    "Tarsia - CS",
    "Tartano - SO",
    "Tarvisio - UD",
    "Tarzo - TV",
    "Tassarolo - AL",
    "Tassullo - TN",
    "Taurano - AV",
    "Taurasi - AV",
    "Taurianova - RC",
    "Taurisano - LE",
    "Tavagnacco - UD",
    "Tavagnasco - TO",
    "Tavarnelle Val di Pesa - FI",
    "Tavazzano con Villavesco - LO",
    "Tavenna - CB",
    "Taverna - CZ",
    "Tavernerio - CO",
    "Tavernola Bergamasca - BG",
    "Tavernole sul Mella - BS",
    "Taviano - LE",
    "Tavigliano - BI",
    "Tavoleto - PU",
    "Tavullia - PU",
    "Teana - PZ",
    "Teano - CE",
    "Teggiano - SA",
    "Teglio - SO",
    "Teglio Veneto - VE",
    "Telese Terme - BN",
    "Telgate - BG",
    "Telti - OT",
    "Telve - TN",
    "Telve di Sopra - TN",
    "Tempio Pausania - OT",
    "Temù - BS",
    "Tenna - TN",
    "Tenno - TN",
    "Teolo - PD",
    "Teor - UD",
    "Teora - AV",
    "Teramo - TE",
    "Terdobbiate - NO",
    "Terelle - FR",
    "Terento - BZ",
    "Terenzo - PR",
    "Tergu - SS",
    "Terlago - TN",
    "Terlano - BZ",
    "Terlizzi - BA",
    "Terme Vigliatore - ME",
    "Termeno sulla Strada del Vino - BZ",
    "Termini Imerese - PA",
    "Termoli - CB",
    "Ternate - VA",
    "Ternengo - BI",
    "Terni - TR",
    "Terno d'Isola - BG",
    "Terracina - LT",
    "Terragnolo - TN",
    "Terralba - OR",
    "Terranova da Sibari - CS",
    "Terranova dei Passerini - LO",
    "Terranova di Pollino - PZ",
    "Terranova Sappo Minulio - RC",
    "Terranuova Bracciolini - AR",
    "Terrasini - PA",
    "Terrassa Padovana - PD",
    "Terravecchia - CS",
    "Terrazzo - VR",
    "Terres - TN",
    "Terricciola - PI",
    "Terruggia - AL",
    "Tertenia - OG",
    "Terzigno - NA",
    "Terzo - AL",
    "Terzo d'Aquileia - UD",
    "Terzolas - TN",
    "Terzorio - IM",
    "Tesero - TN",
    "Tesimo - BZ",
    "Tessennano - VT",
    "Testico - SV",
    "Teti - NU",
    "Teulada - CA",
    "Teverola - CE",
    "Tezze sul Brenta - VI",
    "Thiene - VI",
    "Thiesi - SS",
    "Tiana - NU",
    "Ticengo - CR",
    "Ticineto - AL",
    "Tiggiano - LE",
    "Tiglieto - GE",
    "Tigliole - AT",
    "Tignale - BS",
    "Tinnura - OR",
    "Tione degli Abruzzi - AQ",
    "Tione di Trento - TN",
    "Tirano - SO",
    "Tires - BZ",
    "Tiriolo - CZ",
    "Tirolo - BZ",
    "Tissi - SS",
    "Tito - PZ",
    "Tivoli - RM",
    "Tizzano Val Parma - PR",
    "Toano - RE",
    "Tocco Caudio - BN",
    "Tocco da Casauria - PE",
    "Toceno - VB",
    "Todi - PG",
    "Toffia - RI",
    "Toirano - SV",
    "Tolentino - MC",
    "Tolfa - RM",
    "Tollegno - BI",
    "Tollo - CH",
    "Tolmezzo - UD",
    "Tolve - PZ",
    "Tombolo - PD",
    "Ton - TN",
    "Tonadico - TN",
    "Tonara - NU",
    "Tonco - AT",
    "Tonengo - AT",
    "Tonezza del Cimone - VI",
    "Tora e Piccilli - CE",
    "Torano Castello - CS",
    "Torano Nuovo - TE",
    "Torbole Casaglia - BS",
    "Torcegno - TN",
    "Torchiara - SA",
    "Torchiarolo - BR",
    "Torella dei Lombardi - AV",
    "Torella del Sannio - CB",
    "Torgiano - PG",
    "Torgnon - AO",
    "Torino - TO",
    "Torino di Sangro - CH",
    "Toritto - BA",
    "Torlino Vimercati - CR",
    "Tornaco - NO",
    "Tornareccio - CH",
    "Tornata - CR",
    "Tornimparte - AQ",
    "Torno - CO",
    "Tornolo - PR",
    "Toro - CB",
    "Torpè - NU",
    "Torraca - SA",
    "Torralba - SS",
    "Torrazza Coste - PV",
    "Torrazza Piemonte - TO",
    "Torrazzo - BI",
    "Torre Annunziata - NA",
    "Torre Beretti e Castellaro - PV",
    "Torre Boldone - BG",
    "Torre Bormida - CN",
    "Torre Cajetani - FR",
    "Torre Canavese - TO",
    "Torre d'Arese - PV",
    "Torre de' Busi - LC",
    "Torre de' Negri - PV",
    "Torre De' Passeri - PE",
    "Torre de' Picenardi - CR",
    "Torre de' Roveri - BG",
    "Torre del Greco - NA",
    "Torre di Mosto - VE",
    "Torre di Ruggiero - CZ",
    "Torre di Santa Maria - SO",
    "Torre d'Isola - PV",
    "Torre Le Nocelle - AV",
    "Torre Mondovì - CN",
    "Torre Orsaia - SA",
    "Torre Pallavicina - BG",
    "Torre Pellice - TO",
    "Torre San Giorgio - CN",
    "Torre San Patrizio - FM",
    "Torre Santa Susanna - BR",
    "Torreano - UD",
    "Torrebelvicino - VI",
    "Torrebruna - CH",
    "Torrecuso - BN",
    "Torreglia - PD",
    "Torregrotta - ME",
    "Torremaggiore - FG",
    "Torrenova - ME",
    "Torresina - CN",
    "Torretta - PA",
    "Torrevecchia Pia - PV",
    "Torrevecchia Teatina - CH",
    "Torri del Benaco - VR",
    "Torri di Quartesolo - VI",
    "Torri in Sabina - RI",
    "Torriana - RN",
    "Torrice - FR",
    "Torricella - TA",
    "Torricella del Pizzo - CR",
    "Torricella in Sabina - RI",
    "Torricella Peligna - CH",
    "Torricella Sicura - TE",
    "Torricella Verzate - PV",
    "Torriglia - GE",
    "Torrile - PR",
    "Torrioni - AV",
    "Torrita di Siena - SI",
    "Torrita Tiberina - RM",
    "Tortolì - OG",
    "Tortona - AL",
    "Tortora - CS",
    "Tortorella - SA",
    "Tortoreto - TE",
    "Tortorici - ME",
    "Torviscosa - UD",
    "Toscolano-Maderno - BS",
    "Tossicia - TE",
    "Tovo di Sant'Agata - SO",
    "Tovo San Giacomo - SV",
    "Trabia - PA",
    "Tradate - VA",
    "Tramatza - OR",
    "Trambileno - TN",
    "Tramonti - SA",
    "Tramonti di Sopra - PN",
    "Tramonti di Sotto - PN",
    "Tramutola - PZ",
    "Trana - TO",
    "Trani - BT",
    "Transacqua - TN",
    "Traona - SO",
    "Trapani - TP",
    "Trappeto - PA",
    "Trarego Viggiona - VB",
    "Trasacco - AQ",
    "Trasaghis - UD",
    "Trasquera - VB",
    "Tratalias - CI",
    "Trausella - TO",
    "Travacò Siccomario - PV",
    "Travagliato - BS",
    "Travedona-Monate - VA",
    "Traversella - TO",
    "Traversetolo - PR",
    "Traves - TO",
    "Travesio - PN",
    "Travo - PC",
    "Trebaseleghe - PD",
    "Trebisacce - CS",
    "Trecasali - PR",
    "Trecase - NA",
    "Trecastagni - CT",
    "Trecate - NO",
    "Trecchina - PZ",
    "Trecenta - RO",
    "Tredozio - FC",
    "Treglio - CH",
    "Tregnago - VR",
    "Treia - MC",
    "Treiso - CN",
    "Tremenico - LC",
    "Tremestieri Etneo - CT",
    "Tremezzo - CO",
    "Tremosine - BS",
    "Trenta - CS",
    "Trentinara - SA",
    "Trento - TN",
    "Trentola-Ducenta - CE",
    "Trenzano - BS",
    "Treppo Carnico - UD",
    "Treppo Grande - UD",
    "Trepuzzi - LE",
    "Trequanda - SI",
    "Tres - TN",
    "Tresana - MS",
    "Trescore Balneario - BG",
    "Trescore Cremasco - CR",
    "Tresigallo - FE",
    "Tresivio - SO",
    "Tresnuraghes - OR",
    "Trevenzuolo - VR",
    "Trevi - PG",
    "Trevi nel Lazio - FR",
    "Trevico - AV",
    "Treviglio - BG",
    "Trevignano - TV",
    "Trevignano Romano - RM",
    "Treville - AL",
    "Treviolo - BG",
    "Treviso - TV",
    "Treviso Bresciano - BS",
    "Trezzano Rosa - MI",
    "Trezzano sul Naviglio - MI",
    "Trezzo sull'Adda - MI",
    "Trezzo Tinella - CN",
    "Trezzone - CO",
    "Tribano - PD",
    "Tribiano - MI",
    "Tribogna - GE",
    "Tricarico - MT",
    "Tricase - LE",
    "Tricerro - VC",
    "Tricesimo - UD",
    "Trichiana - BL",
    "Triei - OG",
    "Trieste - TS",
    "Triggiano - BA",
    "Trigolo - CR",
    "Trinità - CN",
    "Trinità d'Agultu e Vignola - OT",
    "Trinitapoli - BT",
    "Trino - VC",
    "Triora - IM",
    "Tripi - ME",
    "Trisobbio - AL",
    "Trissino - VI",
    "Triuggio - MB",
    "Trivento - CB",
    "Trivero - BI",
    "Trivigliano - FR",
    "Trivignano Udinese - UD",
    "Trivigno - PZ",
    "Trivolzio - PV",
    "Trodena nel parco naturale - BZ",
    "Trofarello - TO",
    "Troia - FG",
    "Troina - EN",
    "Tromello - PV",
    "Trontano - VB",
    "Tronzano Lago Maggiore - VA",
    "Tronzano Vercellese - VC",
    "Tropea - VV",
    "Trovo - PV",
    "Truccazzano - MI",
    "Tubre - BZ",
    "Tuenno - TN",
    "Tufara - CB",
    "Tufillo - CH",
    "Tufino - NA",
    "Tufo - AV",
    "Tuglie - LE",
    "Tuili - VS",
    "Tula - SS",
    "Tuoro sul Trasimeno - PG",
    "Turania - RI",
    "Turano Lodigiano - LO",
    "Turate - CO",
    "Turbigo - MI",
    "Turi - BA",
    "Turri - VS",
    "Turriaco - GO",
    "Turrivalignani - PE",
    "Tursi - MT",
    "Tusa - ME",
    "Tuscania - VT",
    "Ubiale Clanezzo - BG",
    "Uboldo - VA",
    "Ucria - ME",
    "Udine - UD",
    "Ugento - LE",
    "Uggiano La Chiesa - LE",
    "Uggiate-Trevano - CO",
    "Ulà Tirso - OR",
    "Ulassai - OG",
    "Ultimo - BZ",
    "Umbertide - PG",
    "Umbriatico - KR",
    "Urago d'Oglio - BS",
    "Uras - OR",
    "Urbana - PD",
    "Urbania - PU",
    "Urbe - SV",
    "Urbino - PU",
    "Urbisaglia - MC",
    "Urgnano - BG",
    "Uri - SS",
    "Ururi - CB",
    "Urzulei - OG",
    "Uscio - GE",
    "Usellus - OR",
    "Usini - SS",
    "Usmate Velate - MB",
    "Ussana - CA",
    "Ussaramanna - VS",
    "Ussassai - OG",
    "Usseaux - TO",
    "Usseglio - TO",
    "Ussita - MC",
    "Ustica - PA",
    "Uta - CA",
    "Uzzano - PT",
    "Vaccarizzo Albanese - CS",
    "Vacone - RI",
    "Vacri - CH",
    "Vadena - BZ",
    "Vado Ligure - SV",
    "Vagli Sotto - LU",
    "Vaglia - FI",
    "Vaglio Basilicata - PZ",
    "Vaglio Serra - AT",
    "Vaiano - PO",
    "Vaiano Cremasco - CR",
    "Vaie - TO",
    "Vailate - CR",
    "Vairano Patenora - CE",
    "Vajont - PN",
    "Val della Torre - TO",
    "Val di Nizza - PV",
    "Val di Vizze - BZ",
    "Val Masino - SO",
    "Val Rezzo - CO",
    "Valbondione - BG",
    "Valbrembo - BG",
    "Valbrevenna - GE",
    "Valbrona - CO",
    "Valda - TN",
    "Valdagno - VI",
    "Valdaora - BZ",
    "Valdastico - VI",
    "Valdengo - BI",
    "Valderice - TP",
    "Valdidentro - SO",
    "Valdieri - CN",
    "Valdina - ME",
    "Valdisotto - SO",
    "Valdobbiadene - TV",
    "Valduggia - VC",
    "Valeggio - PV",
    "Valeggio sul Mincio - VR",
    "Valentano - VT",
    "Valenza - AL",
    "Valenzano - BA",
    "Valera Fratta - LO",
    "Valfabbrica - PG",
    "Valfenera - AT",
    "Valfloriana - TN",
    "Valfurva - SO",
    "Valganna - VA",
    "Valgioie - TO",
    "Valgoglio - BG",
    "Valgrana - CN",
    "Valgreghentino - LC",
    "Valgrisenche - AO",
    "Valguarnera Caropepe - EN",
    "Vallada Agordina - BL",
    "Vallanzengo - BI",
    "Vallarsa - TN",
    "Vallata - AV",
    "Valle Agricola - CE",
    "Valle Aurina - BZ",
    "Valle Castellana - TE",
    "Valle dell'Angelo - SA",
    "Valle di Cadore - BL",
    "Valle di Casies - BZ",
    "Valle di Maddaloni - CE",
    "Valle Lomellina - PV",
    "Valle Mosso - BI",
    "Valle Salimbene - PV",
    "Valle San Nicolao - BI",
    "Vallebona - IM",
    "Vallecorsa - FR",
    "Vallecrosia - IM",
    "Valledolmo - PA",
    "Valledoria - SS",
    "Vallefiorita - CZ",
    "Vallelonga - VV",
    "Vallelunga Pratameno - CL",
    "Vallemaio - FR",
    "Vallepietra - RM",
    "Vallerano - VT",
    "Vallermosa - CA",
    "Vallerotonda - FR",
    "Vallesaccarda - AV",
    "Valleve - BG",
    "Valli del Pasubio - VI",
    "Vallinfreda - RM",
    "Vallio Terme - BS",
    "Vallo della Lucania - SA",
    "Vallo di Nera - PG",
    "Vallo Torinese - TO",
    "Valloriate - CN",
    "Valmacca - AL",
    "Valmadrera - LC",
    "Valmala - CN",
    "Valmontone - RM",
    "Valmorea - CO",
    "Valmozzola - PR",
    "Valnegra - BG",
    "Valpelline - AO",
    "Valperga - TO",
    "Valprato Soana - TO",
    "Valsavarenche - AO",
    "Valsecca - BG",
    "Valsinni - MT",
    "Valsolda - CO",
    "Valstagna - VI",
    "Valstrona - VB",
    "Valtopina - PG",
    "Valtorta - BG",
    "Valtournenche - AO",
    "Valva - SA",
    "Valvasone - PN",
    "Valverde - PV",
    "Valverde - CT",
    "Valvestino - BS",
    "Vandoies - BZ",
    "Vanzaghello - MI",
    "Vanzago - MI",
    "Vanzone con San Carlo - VB",
    "Vaprio d'Adda - MI",
    "Vaprio d'Agogna - NO",
    "Varallo - VC",
    "Varallo Pombia - NO",
    "Varano Borghi - VA",
    "Varano de' Melegari - PR",
    "Varapodio - RC",
    "Varazze - SV",
    "Varco Sabino - RI",
    "Varedo - MB",
    "Varena - TN",
    "Varenna - LC",
    "Varese - VA",
    "Varese Ligure - SP",
    "Varisella - TO",
    "Varmo - UD",
    "Varna - BZ",
    "Varsi - PR",
    "Varzi - PV",
    "Varzo - VB",
    "Vas - BL",
    "Vasanello - VT",
    "Vasia - IM",
    "Vasto - CH",
    "Vastogirardi - IS",
    "Vattaro - TN",
    "Vauda Canavese - TO",
    "Vazzano - VV",
    "Vazzola - TV",
    "Vecchiano - PI",
    "Vedano al Lambro - MB",
    "Vedano Olona - VA",
    "Veddasca - VA",
    "Vedelago - TV",
    "Vedeseta - BG",
    "Veduggio con Colzano - MB",
    "Veggiano - PD",
    "Veglie - LE",
    "Veglio - BI",
    "Vejano - VT",
    "Veleso - CO",
    "Velezzo Lomellina - PV",
    "Velletri - RM",
    "Vellezzo Bellini - PV",
    "Velo d'Astico - VI",
    "Velo Veronese - VR",
    "Velturno - BZ",
    "Venafro - IS",
    "Venaria Reale - TO",
    "Venarotta - AP",
    "Venasca - CN",
    "Venaus - TO",
    "Vendone - SV",
    "Vendrogno - LC",
    "Venegono Inferiore - VA",
    "Venegono Superiore - VA",
    "Venetico - ME",
    "Venezia - VE",
    "Veniano - CO",
    "Venosa - PZ",
    "Venticano - AV",
    "Ventimiglia - IM",
    "Ventimiglia di Sicilia - PA",
    "Ventotene - LT",
    "Venzone - UD",
    "Verano - BZ",
    "Verano Brianza - MB",
    "Verbania - VB",
    "Verbicaro - CS",
    "Vercana - CO",
    "Verceia - SO",
    "Vercelli - VC",
    "Vercurago - LC",
    "Verdellino - BG",
    "Verdello - BG",
    "Verderio Inferiore - LC",
    "Verderio Superiore - LC",
    "Verduno - CN",
    "Vergato - BO",
    "Vergemoli - LU",
    "Verghereto - FC",
    "Vergiate - VA",
    "Vermezzo - MI",
    "Vermiglio - TN",
    "Vernante - CN",
    "Vernasca - PC",
    "Vernate - MI",
    "Vernazza - SP",
    "Vernio - PO",
    "Vernole - LE",
    "Verolanuova - BS",
    "Verolavecchia - BS",
    "Verolengo - TO",
    "Veroli - FR",
    "Verona - VR",
    "Veronella - VR",
    "Verrayes - AO",
    "Verrès - AO",
    "Verretto - PV",
    "Verrone - BI",
    "Verrua Po - PV",
    "Verrua Savoia - TO",
    "Vertemate con Minoprio - CO",
    "Vertova - BG",
    "Verucchio - RN",
    "Veruno - NO",
    "Vervio - SO",
    "Vervò - TN",
    "Verzegnis - UD",
    "Verzino - KR",
    "Verzuolo - CN",
    "Vescovana - PD",
    "Vescovato - CR",
    "Vesime - AT",
    "Vespolate - NO",
    "Vessalico - IM",
    "Vestenanova - VR",
    "Vestignè - TO",
    "Vestone - BS",
    "Vestreno - LC",
    "Vetralla - VT",
    "Vetto - RE",
    "Vezza d'Alba - CN",
    "Vezza d'Oglio - BS",
    "Vezzano - TN",
    "Vezzano Ligure - SP",
    "Vezzano sul Crostolo - RE",
    "Vezzi Portio - SV",
    "Viadana - MN",
    "Viadanica - BG",
    "Viagrande - CT",
    "Viale - AT",
    "Vialfrè - TO",
    "Viano - RE",
    "Viareggio - LU",
    "Viarigi - AT",
    "Vibo Valentia - VV",
    "Vibonati - SA",
    "Vicalvi - FR",
    "Vicari - PA",
    "Vicchio - FI",
    "Vicenza - VI",
    "Vico Canavese - TO",
    "Vico del Gargano - FG",
    "Vico Equense - NA",
    "Vico nel Lazio - FR",
    "Vicoforte - CN",
    "Vicoli - PE",
    "Vicolungo - NO",
    "Vicopisano - PI",
    "Vicovaro - RM",
    "Viddalba - SS",
    "Vidigulfo - PV",
    "Vidor - TV",
    "Vidracco - TO",
    "Vieste - FG",
    "Vietri di Potenza - PZ",
    "Vietri sul Mare - SA",
    "Viganella - VB",
    "Viganò - LC",
    "Vigano San Martino - BG",
    "Vigarano Mainarda - FE",
    "Vigasio - VR",
    "Vigevano - PV",
    "Viggianello - PZ",
    "Viggiano - PZ",
    "Viggiù - VA",
    "Vighizzolo d'Este - PD",
    "Vigliano Biellese - BI",
    "Vigliano d'Asti - AT",
    "Vignale Monferrato - AL",
    "Vignanello - VT",
    "Vignate - MI",
    "Vignola - MO",
    "Vignola-Falesina - TN",
    "Vignole Borbera - AL",
    "Vignolo - CN",
    "Vignone - VB",
    "Vigo di Cadore - BL",
    "Vigo di Fassa - TN",
    "Vigo Rendena - TN",
    "Vigodarzere - PD",
    "Vigolo - BG",
    "Vigolo Vattaro - TN",
    "Vigolzone - PC",
    "Vigone - TO",
    "Vigonovo - VE",
    "Vigonza - PD",
    "Viguzzolo - AL",
    "Villa Agnedo - TN",
    "Villa Bartolomea - VR",
    "Villa Basilica - LU",
    "Villa Biscossi - PV",
    "Villa Carcina - BS",
    "Villa Castelli - BR",
    "Villa Celiera - PE",
    "Villa Collemandina - LU",
    "Villa Cortese - MI",
    "Villa d'Adda - BG",
    "Villa d'Almè - BG",
    "Villa del Bosco - BI",
    "Villa del Conte - PD",
    "Villa di Briano - CE",
    "Villa di Chiavenna - SO",
    "Villa di Serio - BG",
    "Villa di Tirano - SO",
    "Villa d'Ogna - BG",
    "Villa Estense - PD",
    "Villa Faraldi - IM",
    "Villa Guardia - CO",
    "Villa Lagarina - TN",
    "Villa Latina - FR",
    "Villa Literno - CE",
    "Villa Minozzo - RE",
    "Villa Poma - MN",
    "Villa Rendena - TN",
    "Villa San Giovanni - RC",
    "Villa San Giovanni in Tuscia - VT",
    "Villa San Pietro - CA",
    "Villa San Secondo - AT",
    "Villa Santa Lucia - FR",
    "Villa Santa Lucia degli Abruzzi - AQ",
    "Villa Santa Maria - CH",
    "Villa Sant'Angelo - AQ",
    "Villa Sant'Antonio - OR",
    "Villa Santina - UD",
    "Villa Santo Stefano - FR",
    "Villa Verde - OR",
    "Villa Vicentina - UD",
    "Villabassa - BZ",
    "Villabate - PA",
    "Villachiara - BS",
    "Villacidro - VS",
    "Villadeati - AL",
    "Villadose - RO",
    "Villadossola - VB",
    "Villafalletto - CN",
    "Villafranca d'Asti - AT",
    "Villafranca di Verona - VR",
    "Villafranca in Lunigiana - MS",
    "Villafranca Padovana - PD",
    "Villafranca Piemonte - TO",
    "Villafranca Sicula - AG",
    "Villafranca Tirrena - ME",
    "Villafrati - PA",
    "Villaga - VI",
    "Villagrande Strisaili - OG",
    "Villalago - AQ",
    "Villalba - CL",
    "Villalfonsina - CH",
    "Villalvernia - AL",
    "Villamagna - CH",
    "Villamaina - AV",
    "Villamar - VS",
    "Villamarzana - RO",
    "Villamassargia - CI",
    "Villamiroglio - AL",
    "Villandro - BZ",
    "Villanova Biellese - BI",
    "Villanova Canavese - TO",
    "Villanova d'Albenga - SV",
    "Villanova d'Ardenghi - PV",
    "Villanova d'Asti - AT",
    "Villanova del Battista - AV",
    "Villanova del Ghebbo - RO",
    "Villanova del Sillaro - LO",
    "Villanova di Camposampiero - PD",
    "Villanova Marchesana - RO",
    "Villanova Mondovì - CN",
    "Villanova Monferrato - AL",
    "Villanova Monteleone - SS",
    "Villanova Solaro - CN",
    "Villanova sull'Arda - PC",
    "Villanova Truschedu - OR",
    "Villanova Tulo - CA",
    "Villanovaforru - VS",
    "Villanovafranca - VS",
    "Villanterio - PV",
    "Villanuova sul Clisi - BS",
    "Villaperuccio - CI",
    "Villapiana - CS",
    "Villaputzu - CA",
    "Villar Dora - TO",
    "Villar Focchiardo - TO",
    "Villar Pellice - TO",
    "Villar Perosa - TO",
    "Villar San Costanzo - CN",
    "Villarbasse - TO",
    "Villarboit - VC",
    "Villareggia - TO",
    "Villaricca - NA",
    "Villaromagnano - AL",
    "Villarosa - EN",
    "Villasalto - CA",
    "Villasanta - MB",
    "Villasimius - CA",
    "Villasor - CA",
    "Villaspeciosa - CA",
    "Villastellone - TO",
    "Villata - VC",
    "Villaurbana - OR",
    "Villavallelonga - AQ",
    "Villaverla - VI",
    "Villeneuve - AO",
    "Villesse - GO",
    "Villetta Barrea - AQ",
    "Villette - VB",
    "Villimpenta - MN",
    "Villongo - BG",
    "Villorba - TV",
    "Vilminore di Scalve - BG",
    "Vimercate - MB",
    "Vimodrone - MI",
    "Vinadio - CN",
    "Vinchiaturo - CB",
    "Vinchio - AT",
    "Vinci - FI",
    "Vinovo - TO",
    "Vinzaglio - NO",
    "Viola - CN",
    "Vione - BS",
    "Vipiteno - BZ",
    "Virgilio - MN",
    "Virle Piemonte - TO",
    "Visano - BS",
    "Vische - TO",
    "Visciano - NA",
    "Visco - UD",
    "Visone - AL",
    "Visso - MC",
    "Vistarino - PV",
    "Vistrorio - TO",
    "Vita - TP",
    "Viterbo - VT",
    "Viticuso - FR",
    "Vito d'Asio - PN",
    "Vitorchiano - VT",
    "Vittoria - RG",
    "Vittorio Veneto - TV",
    "Vittorito - AQ",
    "Vittuone - MI",
    "Vitulano - BN",
    "Vitulazio - CE",
    "Viù - TO",
    "Vivaro - PN",
    "Vivaro Romano - RM",
    "Viverone - BI",
    "Vizzini - CT",
    "Vizzola Ticino - VA",
    "Vizzolo Predabissi - MI",
    "Vo' - PD",
    "Vobarno - BS",
    "Vobbia - GE",
    "Vocca - VC",
    "Vodo Cadore - BL",
    "Voghera - PV",
    "Voghiera - FE",
    "Vogogna - VB",
    "Volano - TN",
    "Volla - NA",
    "Volongo - CR",
    "Volpago del Montello - TV",
    "Volpara - PV",
    "Volpedo - AL",
    "Volpeglino - AL",
    "Volpiano - TO",
    "Volta Mantovana - MN",
    "Voltaggio - AL",
    "Voltago Agordino - BL",
    "Volterra - PI",
    "Voltido - CR",
    "Volturara Appula - FG",
    "Volturara Irpina - AV",
    "Volturino - FG",
    "Volvera - TO",
    "Vottignasco - CN",
    "Zaccanopoli - VV",
    "Zafferana Etnea - CT",
    "Zagarise - CZ",
    "Zagarolo - RM",
    "Zambana - TN",
    "Zambrone - VV",
    "Zandobbio - BG",
    "Zanè - VI",
    "Zanica - BG",
    "Zapponeta - FG",
    "Zavattarello - PV",
    "Zeccone - PV",
    "Zeddiani - OR",
    "Zelbio - CO",
    "Zelo Buon Persico - LO",
    "Zelo Surrigone - MI",
    "Zeme - PV",
    "Zenevredo - PV",
    "Zenson di Piave - TV",
    "Zerba - PC",
    "Zerbo - PV",
    "Zerbolò - PV",
    "Zerfaliu - OR",
    "Zeri - MS",
    "Zermeghedo - VI",
    "Zero Branco - TV",
    "Zevio - VR",
    "Ziano di Fiemme - TN",
    "Ziano Piacentino - PC",
    "Zibello - PR",
    "Zibido San Giacomo - MI",
    "Zignago - SP",
    "Zimella - VR",
    "Zimone - BI",
    "Zinasco - PV",
    "Zoagli - GE",
    "Zocca - MO",
    "Zogno - BG",
    "Zola Predosa - BO",
    "Zoldo Alto - BL",
    "Zollino - LE",
    "Zone - BS",
    "Zoppè di Cadore - BL",
    "Zoppola - PN",
    "Zovencedo - VI",
    "Zubiena - BI",
    "Zuccarello - SV",
    "Zuclo - TN",
    "Zugliano - VI",
    "Zuglio - UD",
    "Zumaglia - BI",
    "Zumpano - CS",
    "Zungoli - AV",
    "Zungri - VV"
]

import axios from "axios";

export let baseurl="https://niklasprovieri.altervista.org/apit/public/"
export let baseEndpoint="https://niklasprovieri.altervista.org/apit/public/api/v1/"


export default function Requests(type,endpoint){
}
export function setToken(token){
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
}

import {Typography} from "@material-ui/core";
import {cityNames} from "../pages/citta";
import React from "react";

let nations = [
    {selectLabel: 'Afghanistan', selectValue: 'Afghanistan'},
    {selectLabel: 'Albania', selectValue: 'Albania'},
    {selectLabel: 'Algeria', selectValue: 'Algeria'},
    {selectLabel: 'Andorra', selectValue: 'Andorra'},
    {selectLabel: 'Angola', selectValue: 'Angola'},
    {selectLabel: 'Anguilla', selectValue: 'Anguilla'},
    {selectLabel: 'Antartide', selectValue: 'Antartide'},
    {selectLabel: 'Antigua e Barbuda', selectValue: 'Antigua e Barbuda'},
    {selectLabel: 'Antille Olandesi', selectValue: 'Antille Olandesi'},
    {selectLabel: 'Arabia Saudita', selectValue: 'Arabia Saudita'},
    {selectLabel: 'Argentina', selectValue: 'Argentina'},
    {selectLabel: 'Armenia', selectValue: 'Armenia'},
    {selectLabel: 'Aruba', selectValue: 'Aruba'},
    {selectLabel: 'Australia', selectValue: 'Australia'},
    {selectLabel: 'Austria', selectValue: 'Austria'},
    {selectLabel: 'Azerbaigian', selectValue: 'Azerbaigian'},
    {selectLabel: 'Bahamas', selectValue: 'Bahamas'},
    {selectLabel: 'Bahrein', selectValue: 'Bahrein'},
    {selectLabel: 'Bangladesh', selectValue: 'Bangladesh'},
    {selectLabel: 'Barbados', selectValue: 'Barbados'},
    {selectLabel: 'Belgio', selectValue: 'Belgio'},
    {selectLabel: 'Belize', selectValue: 'Belize'},
    {selectLabel: 'Benin', selectValue: 'Benin'},
    {selectLabel: 'Bermuda', selectValue: 'Bermuda'},
    {selectLabel: 'Bhutan', selectValue: 'Bhutan'},
    {selectLabel: 'Bielorussia', selectValue: 'Bielorussia'},
    {selectLabel: 'Bolivia', selectValue: 'Bolivia'},
    {selectLabel: 'Bosnia Erzegovina', selectValue: 'Bosnia Erzegovina'},
    {selectLabel: 'Botswana', selectValue: 'Botswana'},
    {selectLabel: 'Brasile', selectValue: 'Brasile'},
    {selectLabel: 'Brunei', selectValue: 'Brunei'},
    {selectLabel: 'Bulgaria', selectValue: 'Bulgaria'},
    {selectLabel: 'Burkina Faso', selectValue: 'Burkina Faso'},
    {selectLabel: 'Burundi', selectValue: 'Burundi'},
    {selectLabel: 'Cambogia', selectValue: 'Cambogia'},
    {selectLabel: 'Camerun', selectValue: 'Camerun'},
    {selectLabel: 'Canada', selectValue: 'Canada'},
    {selectLabel: 'Capo Verde', selectValue: 'Capo Verde'},
    {selectLabel: 'Ciad', selectValue: 'Ciad'},
    {selectLabel: 'Cile', selectValue: 'Cile'},
    {selectLabel: 'Cina', selectValue: 'Cina'},
    {selectLabel: 'Cipro', selectValue: 'Cipro'},
    {selectLabel: 'Colombia', selectValue: 'Colombia'},
    {selectLabel: 'Comore', selectValue: 'Comore'},
    {selectLabel: 'Congo', selectValue: 'Congo'},
    {selectLabel: 'Corea del Nord', selectValue: 'Corea del Nord'},
    {selectLabel: 'Corea del Sud', selectValue: 'Corea del Sud'},
    {selectLabel: 'Costa Rica', selectValue: 'Costa Rica'},
    {selectLabel: 'Costa d’Avorio', selectValue: 'Costa d’Avorio'},
    {selectLabel: 'Croazia', selectValue: 'Croazia'},
    {selectLabel: 'Cuba', selectValue: 'Cuba'},
    {selectLabel: 'Danimarca', selectValue: 'Danimarca'},
    {selectLabel: 'Dominica', selectValue: 'Dominica'},
    {selectLabel: 'Ecuador', selectValue: 'Ecuador'},
    {selectLabel: 'Egitto', selectValue: 'Egitto'},
    {selectLabel: 'El Salvador', selectValue: 'El Salvador'},
    {selectLabel: 'Emirati Arabi Uniti', selectValue: 'Emirati Arabi Uniti'},
    {selectLabel: 'Eritrea', selectValue: 'Eritrea'},
    {selectLabel: 'Estonia', selectValue: 'Estonia'},
    {selectLabel: 'Etiopia', selectValue: 'Etiopia'},
    {selectLabel: 'Federazione Russa', selectValue: 'Federazione Russa'},
    {selectLabel: 'Figi', selectValue: 'Figi'},
    {selectLabel: 'Filippine', selectValue: 'Filippine'},
    {selectLabel: 'Finlandia', selectValue: 'Finlandia'},
    {selectLabel: 'Francia', selectValue: 'Francia'},
    {selectLabel: 'Gabon', selectValue: 'Gabon'},
    {selectLabel: 'Gambia', selectValue: 'Gambia'},
    {selectLabel: 'Georgia', selectValue: 'Georgia'},
    {selectLabel: 'Georgia del Sud e Isole Sandwich del Sud', selectValue: 'Georgia del Sud e Isole Sandwich del Sud'},
    {selectLabel: 'Germania', selectValue: 'Germania'},
    {selectLabel: 'Ghana', selectValue: 'Ghana'},
    {selectLabel: 'Giamaica', selectValue: 'Giamaica'},
    {selectLabel: 'Giappone', selectValue: 'Giappone'},
    {selectLabel: 'Gibilterra', selectValue: 'Gibilterra'},
    {selectLabel: 'Gibuti', selectValue: 'Gibuti'},
    {selectLabel: 'Giordania', selectValue: 'Giordania'},
    {selectLabel: 'Grecia', selectValue: 'Grecia'},
    {selectLabel: 'Grenada', selectValue: 'Grenada'},
    {selectLabel: 'Groenlandia', selectValue: 'Groenlandia'},
    {selectLabel: 'Guadalupa', selectValue: 'Guadalupa'},
    {selectLabel: 'Guam', selectValue: 'Guam'},
    {selectLabel: 'Guatemala', selectValue: 'Guatemala'},
    {selectLabel: 'Guernsey', selectValue: 'Guernsey'},
    {selectLabel: 'Guiana Francese', selectValue: 'Guiana Francese'},
    {selectLabel: 'Guinea', selectValue: 'Guinea'},
    {selectLabel: 'Guinea Equatoriale', selectValue: 'Guinea Equatoriale'},
    {selectLabel: 'Guinea-Bissau', selectValue: 'Guinea-Bissau'},
    {selectLabel: 'Guyana', selectValue: 'Guyana'},
    {selectLabel: 'Haiti', selectValue: 'Haiti'},
    {selectLabel: 'Honduras', selectValue: 'Honduras'},
    {selectLabel: 'India', selectValue: 'India'},
    {selectLabel: 'Indonesia', selectValue: 'Indonesia'},
    {selectLabel: 'Iran', selectValue: 'Iran'},
    {selectLabel: 'Iraq', selectValue: 'Iraq'},
    {selectLabel: 'Irlanda', selectValue: 'Irlanda'},
    {selectLabel: 'Islanda', selectValue: 'Islanda'},
    {selectLabel: 'Isola Bouvet', selectValue: 'Isola Bouvet'},
    {selectLabel: 'Isola Norfolk', selectValue: 'Isola Norfolk'},
    {selectLabel: 'Isola di Christmas', selectValue: 'Isola di Christmas'},
    {selectLabel: 'Isola di Man', selectValue: 'Isola di Man'},
    {selectLabel: 'Isole Aland', selectValue: 'Isole Aland'},
    {selectLabel: 'Isole Cayman', selectValue: 'Isole Cayman'},
    {selectLabel: 'Isole Cocos', selectValue: 'Isole Cocos'},
    {selectLabel: 'Isole Cook', selectValue: 'Isole Cook'},
    {selectLabel: 'Isole Falkland', selectValue: 'Isole Falkland'},
    {selectLabel: 'Isole Faroe', selectValue: 'Isole Faroe'},
    {selectLabel: 'Isole Heard ed Isole McDonald', selectValue: 'Isole Heard ed Isole McDonald'},
    {selectLabel: 'Isole Marianne Settentrionali', selectValue: 'Isole Marianne Settentrionali'},
    {selectLabel: 'Isole Marshall', selectValue: 'Isole Marshall'},
    {selectLabel: 'Isole Minori lontane dagli Stati Uniti', selectValue: 'Isole Minori lontane dagli Stati Uniti'},
    {selectLabel: 'Isole Solomon', selectValue: 'Isole Solomon'},
    {selectLabel: 'Isole Turks e Caicos', selectValue: 'Isole Turks e Caicos'},
    {selectLabel: 'Isole Vergini Americane', selectValue: 'Isole Vergini Americane'},
    {selectLabel: 'Isole Vergini Britanniche', selectValue: 'Isole Vergini Britanniche'},
    {selectLabel: 'Israele', selectValue: 'Israele'},
    {selectLabel: 'Italia', selectValue: 'Italia'},
    {selectLabel: 'Jersey', selectValue: 'Jersey'},
    {selectLabel: 'Kazakistan', selectValue: 'Kazakistan'},
    {selectLabel: 'Kenya', selectValue: 'Kenya'},
    {selectLabel: 'Kirghizistan', selectValue: 'Kirghizistan'},
    {selectLabel: 'Kiribati', selectValue: 'Kiribati'},
    {selectLabel: 'Kuwait', selectValue: 'Kuwait'},
    {selectLabel: 'Laos', selectValue: 'Laos'},
    {selectLabel: 'Lesotho', selectValue: 'Lesotho'},
    {selectLabel: 'Lettonia', selectValue: 'Lettonia'},
    {selectLabel: 'Libano', selectValue: 'Libano'},
    {selectLabel: 'Liberia', selectValue: 'Liberia'},
    {selectLabel: 'Libia', selectValue: 'Libia'},
    {selectLabel: 'Liechtenstein', selectValue: 'Liechtenstein'},
    {selectLabel: 'Lituania', selectValue: 'Lituania'},
    {selectLabel: 'Lussemburgo', selectValue: 'Lussemburgo'},
    {selectLabel: 'Madagascar', selectValue: 'Madagascar'},
    {selectLabel: 'Malawi', selectValue: 'Malawi'},
    {selectLabel: 'Maldive', selectValue: 'Maldive'},
    {selectLabel: 'Malesia', selectValue: 'Malesia'},
    {selectLabel: 'Mali', selectValue: 'Mali'},
    {selectLabel: 'Malta', selectValue: 'Malta'},
    {selectLabel: 'Marocco', selectValue: 'Marocco'},
    {selectLabel: 'Martinica', selectValue: 'Martinica'},
    {selectLabel: 'Mauritania', selectValue: 'Mauritania'},
    {selectLabel: 'Mauritius', selectValue: 'Mauritius'},
    {selectLabel: 'Mayotte', selectValue: 'Mayotte'},
    {selectLabel: 'Messico', selectValue: 'Messico'},
    {selectLabel: 'Micronesia', selectValue: 'Micronesia'},
    {selectLabel: 'Moldavia', selectValue: 'Moldavia'},
    {selectLabel: 'Monaco', selectValue: 'Monaco'},
    {selectLabel: 'Mongolia', selectValue: 'Mongolia'},
    {selectLabel: 'Montenegro', selectValue: 'Montenegro'},
    {selectLabel: 'Montserrat', selectValue: 'Montserrat'},
    {selectLabel: 'Mozambico', selectValue: 'Mozambico'},
    {selectLabel: 'Myanmar', selectValue: 'Myanmar'},
    {selectLabel: 'Namibia', selectValue: 'Namibia'},
    {selectLabel: 'Nauru', selectValue: 'Nauru'},
    {selectLabel: 'Nepal', selectValue: 'Nepal'},
    {selectLabel: 'Nicaragua', selectValue: 'Nicaragua'},
    {selectLabel: 'Niger', selectValue: 'Niger'},
    {selectLabel: 'Nigeria', selectValue: 'Nigeria'},
    {selectLabel: 'Niue', selectValue: 'Niue'},
    {selectLabel: 'Norvegia', selectValue: 'Norvegia'},
    {selectLabel: 'Nuova Caledonia', selectValue: 'Nuova Caledonia'},
    {selectLabel: 'Nuova Zelanda', selectValue: 'Nuova Zelanda'},
    {selectLabel: 'Oman', selectValue: 'Oman'},
    {selectLabel: 'Paesi Bassi', selectValue: 'Paesi Bassi'},
    {selectLabel: 'Pakistan', selectValue: 'Pakistan'},
    {selectLabel: 'Palau', selectValue: 'Palau'},
    {selectLabel: 'Palestina', selectValue: 'Palestina'},
    {selectLabel: 'Panama', selectValue: 'Panama'},
    {selectLabel: 'Papua Nuova Guinea', selectValue: 'Papua Nuova Guinea'},
    {selectLabel: 'Paraguay', selectValue: 'Paraguay'},
    {selectLabel: 'Perù', selectValue: 'Perù'},
    {selectLabel: 'Pitcairn', selectValue: 'Pitcairn'},
    {selectLabel: 'Polinesia Francese', selectValue: 'Polinesia Francese'},
    {selectLabel: 'Polonia', selectValue: 'Polonia'},
    {selectLabel: 'Portogallo', selectValue: 'Portogallo'},
    {selectLabel: 'Portorico', selectValue: 'Portorico'},
    {selectLabel: 'Qatar', selectValue: 'Qatar'},
    {
        selectLabel: 'Regione Amministrativa Speciale di Hong Kong della Repubblica Popolare Cinese',
        selectValue: 'Regione Amministrativa Speciale di Hong Kong della Repubblica Popolare Cinese'
    },
    {
        selectLabel: 'Regione Amministrativa Speciale di Macao della Repubblica Popolare Cinese',
        selectValue: ' Regione Amministrativa Speciale di Macao della Repubblica Popolare Cinese'
    },
    {selectLabel: 'Regno Unito', selectValue: 'Regno Unito'},
    {selectLabel: 'Repubblica Ceca', selectValue: 'Repubblica Ceca'},
    {selectLabel: 'Repubblica Centrafricana', selectValue: 'Repubblica Centrafricana'},
    {selectLabel: 'Repubblica Democratica del Congo', selectValue: 'Repubblica Democratica del Congo'},
    {selectLabel: 'Repubblica Dominicana', selectValue: 'Repubblica Dominicana'},
    {selectLabel: 'Repubblica di Macedonia', selectValue: 'Repubblica di Macedonia'},
    {selectLabel: 'Romania', selectValue: 'Romania'},
    {selectLabel: 'Ruanda', selectValue: 'Ruanda'},
    {selectLabel: 'Réunion', selectValue: 'Réunion'},
    {selectLabel: 'Sahara Occidentale', selectValue: 'Sahara Occidentale'},
    {selectLabel: 'Saint Kitts e Nevis', selectValue: 'Saint Kitts e Nevis'},
    {selectLabel: 'Saint Lucia', selectValue: 'Saint Lucia'},
    {selectLabel: 'Saint Pierre e Miquelon', selectValue: 'Saint Pierre e Miquelon'},
    {selectLabel: 'Saint Vincent e Grenadines', selectValue: 'Saint Vincent e Grenadines'},
    {selectLabel: 'Samoa', selectValue: 'Samoa'},
    {selectLabel: 'Samoa Americane', selectValue: 'Samoa Americane'},
    {selectLabel: 'San Bartolomeo', selectValue: 'San Bartolomeo'},
    {selectLabel: 'San Marino', selectValue: 'San Marino'},
    {selectLabel: 'Sant’Elena', selectValue: 'Sant’Elena'},
    {selectLabel: 'Sao Tomé e Príncipe', selectValue: 'Sao Tomé e Príncipe'},
    {selectLabel: 'Senegal', selectValue: 'Senegal'},
    {selectLabel: 'Serbia', selectValue: 'Serbia'},
    {selectLabel: 'Serbia e Montenegro', selectValue: 'Serbia e Montenegro'},
    {selectLabel: 'Seychelles', selectValue: 'Seychelles'},
    {selectLabel: 'Sierra Leone', selectValue: 'Sierra Leone'},
    {selectLabel: 'Singapore', selectValue: 'Singapore'},
    {selectLabel: 'Siria', selectValue: 'Siria'},
    {selectLabel: 'Slovacchia', selectValue: 'Slovacchia'},
    {selectLabel: 'Slovenia', selectValue: 'Slovenia'},
    {selectLabel: 'Somalia', selectValue: 'Somalia'},
    {selectLabel: 'Spagna', selectValue: 'Spagna'},
    {selectLabel: 'Sri Lanka', selectValue: 'Sri Lanka'},
    {selectLabel: 'Stati Uniti', selectValue: 'Stati Uniti'},
    {selectLabel: 'Sudafrica', selectValue: 'Sudafrica'},
    {selectLabel: 'Sudan', selectValue: 'Sudan'},
    {selectLabel: 'Suriname', selectValue: 'Suriname'},
    {selectLabel: 'Svalbard e Jan Mayen', selectValue: 'Svalbard e Jan Mayen'},
    {selectLabel: 'Svezia', selectValue: 'Svezia'},
    {selectLabel: 'Svizzera', selectValue: 'Svizzera'},
    {selectLabel: 'Swaziland', selectValue: 'Swaziland'},
    {selectLabel: 'Tagikistan', selectValue: 'Tagikistan'},
    {selectLabel: 'Tailandia', selectValue: 'Tailandia'},
    {selectLabel: 'Taiwan', selectValue: 'Taiwan'},
    {selectLabel: 'Tanzania', selectValue: 'Tanzania'},
    {selectLabel: 'Territori australi francesi', selectValue: 'Territori australi francesi'},
    {
        selectLabel: 'Territorio Britannico dell’Oceano Indiano',
        selectValue: 'Territorio Britannico dell’Oceano Indiano'
    },
    {selectLabel: 'Timor Est', selectValue: 'Timor Est'},
    {selectLabel: 'Togo', selectValue: 'Togo'},
    {selectLabel: 'Tokelau', selectValue: 'Tokelau'},
    {selectLabel: 'Tonga', selectValue: 'Tonga'},
    {selectLabel: 'Trinidad e Tobago', selectValue: 'Trinidad e Tobago'},
    {selectLabel: 'Tunisia', selectValue: 'Tunisia'},
    {selectLabel: 'Turchia', selectValue: 'Turchia'},
    {selectLabel: 'Turkmenistan', selectValue: 'Turkmenistan'},
    {selectLabel: 'Tuvalu', selectValue: 'Tuvalu'},
    {selectLabel: 'Ucraina', selectValue: 'Ucraina'},
    {selectLabel: 'Uganda', selectValue: 'Uganda'},
    {selectLabel: 'Ungheria', selectValue: 'Ungheria'},
    {selectLabel: 'Uruguay', selectValue: 'Uruguay'},
    {selectLabel: 'Uzbekistan', selectValue: 'Uzbekistan'},
    {selectLabel: 'Vanuatu', selectValue: 'Vanuatu'},
    {selectLabel: 'Vaticano', selectValue: 'Vaticano'},
    {selectLabel: 'Venezuela', selectValue: 'Venezuela'},
    {selectLabel: 'Vietnam', selectValue: 'Vietnam'},
    {selectLabel: 'Wallis e Futuna', selectValue: 'Wallis e Futuna'},
    {selectLabel: 'Yemen', selectValue: 'Yemen'},
    {selectLabel: 'Zambia', selectValue: 'Zambia'},
    {selectLabel: 'Zimbabwe', selectValue: 'Zimbabwe'},
    {selectLabel: 'regione non valida o sconosciuta', selectValue: 'regione non valida o sconosciuta'},
];

export const REGISTRATION_FORM = [

    {
        name: 'e-mail',
        style: {marginBottom: 12},
        label: 'E-mail',
        regex: new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
    },

    {
        name: 'password',
        textType: 'password',
        label: 'Password',
        flex: 1,
        regex: new RegExp(/^([^0-9]*)$/)
    },
    {name: 'newPassword', label: 'Ripeti la password', textType: 'password', flex: 2, required: false},
    {
        type: 'terms'
    },
    {
        type: 'button',
        buttons: [{label: 'Registrati', bType: 'submit'}],
        style: {marginRight: 12},
        bStyle: {
            background: 'linear-gradient(to left,#fe821c ,#e45447)',
            width: '100%',
            borderRadius: 25,
            marginTop: 3,
            marginLeft: 6
        }
    }
];
export const LOGIN_FORM = [
    {
        name: 'e-mail',
        style: {marginBottom: 12},
        label: 'E-mail',
        regex: new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
    },
    {
        name: 'password',
        textType: 'password',
        label: 'Password',
        flex: 1,
        regex: new RegExp(/^([^0-9]*)$/)
    },
    {
        type: 'terms'
    },
    {
        type: 'button',
        buttons: [{label: 'LOGIN', bType: 'submit'}],
        style: {marginRight: 12},
        bStyle: {
            background: 'linear-gradient(to left,#fe821c ,#e45447)',
            width: '100%',
            borderRadius: 25,
            marginTop: 3,
            marginLeft: 6
        }
    }
];
export const ANAGRAFICS_FORM = (modify, closeModal) => [
    {
        name: 'name',
        style: {marginBottom: 12},
        flex: 1,
        minWidth: '45%',
        label: 'Nome'
    },
    {
        name: 'surname',
        style: {marginBottom: 12},
        flex: 1,
        minWidth: '45%',
        label: 'Cognome'
    },
    {
        name: 'nation',
        style: {marginBottom: 12},
        label: 'Nazione',
        textType: 'auto',
        selectValues: nations.map(e => ({selectValue: e.selectValue, selectLabel: e.selectValue.replace(/" "g/, '_')}))
    }, {
        name: 'genre',
        style: {marginBottom: 12},
        label: 'Genere',
        textType: 'select',
        selectValues: [
            {selectLabel: 'M', selectValue: 1},
            {selectLabel: 'F', selectValue: 2},
            {selectLabel: 'Altro', selectValue: 3}
        ]
    },
    {
        name: 'work',
        style: {marginBottom: 12},
        minWidth: '30%',
        label: 'Professione',
    },
    {
        name: 'email',
        minWidth: '30%',
        style: {marginBottom: 12},
        label: 'E-mail',
    },
    {
        name: 'Phone',
        style: {marginBottom: 12},
        label: 'Telefono',
    },
    {
        name: 'born',
        style: {marginBottom: 12, flex: 1},
        label: 'Data di nascita',
        placeholder: 'DD/MM/YYYY'
    },
    {
        name: 'region',
        style: {marginBottom: 12},
        minWidth: '30%',
        label: 'Regione',
        textType: 'auto',
        selectValues: region
    },
    {
        name: 'city',
        style: {marginBottom: 12},
        minWidth: '30%',
        label: 'Comune',
        textType: 'auto',
        selectValues: cityNames.map(e => ({selectValue: e, selectLabel: e}))
    },
    {
        name: 'address',
        minWidth: '30%',
        style: {marginBottom: 12},
        label: 'Indirizzo/CAP',
    },
    {
        name: 'sport',
        style: {marginBottom: 12, flex: 1},
        minWidth: '80%',
        label: 'Preferenze sportive',
    },

    {
        type: 'subtitle',
        value: 'Domande sul servizio',
        style: {textAlign: 'left', flex: 1, minWidth: '100%', fontWeight: 'bold'},
        variant: 'h5'
    },
    {
        type: 'subtitle',
        value: 'Che prodotto hai acquistato da noi? (Se articolo Tudor con referenza)'
    },
    {
        name: 'product_buy',
        style: {marginBottom: 12},
        flex: 1,
        label: 'Prodotti'
    },
    {
        type: 'subtitle',
        value: 'Quali prodotti o servizi ti picerebbe acquistare presso questa gioielleria in futuro?'
    },
    {
        name: 'product_want',
        multiline: true,
        rows: 4,
        minWidth: '98%',
        variant: 'outlined',
        style: {marginBottom: 12, flex: 1},
        label: '',
    },

    // {type: 'subtitle',value: 'Sono state soddisfatte le tue aspettative per il servizio clienti durante la tua ultima visita?'},
    {
        name: 'aspectative',
        minWidth: '98%',
        style: {marginBottom: 12, flex: 1},
        label: 'Sono state soddisfatte le tue aspettative durante la tua ultima visita?',
        labelStyle: {width: '60%'},
        placeholder: 'DD/MM/YYYY',
        type: 'radio',
        selectValues: [
            {selectLabel: 'Si', selectValue: 1},
            {selectLabel: 'Abbastanza', selectValue: 2},
            {selectLabel: 'No', selectValue: 3}
        ]
    }, {
        name: 'update',
        minWidth: '98%',
        style: {marginBottom: 12, flex: 1},
        label: 'Sai interessato a ricevere aggiornamenti o offerte speciali, circa i brand della Gioielleria?',
        placeholder: 'DD/MM/YYYY',
        type: 'radio',
        selectValues: [
            {selectLabel: 'Si', selectValue: 1},
            {selectLabel: 'No', selectValue: 2}
        ]
    }, {
        name: 'contactType',
        minWidth: '98%',
        style: {marginBottom: 12, flex: 1},
        label: 'Quali sono le tue preferenze di comunicazione con noi?\n',
        placeholder: 'DD/MM/YYYY',
        type: 'radio',
        selectValues: [
            {selectLabel: 'Telefono', selectValue: 1},
            {selectLabel: 'E-mail', selectValue: 2},
            {selectLabel: 'Non contattatemi', selectValue: 3}
        ]
    },
    {
        type: 'subtitle',
        value: <Typography style={{
            display: "flex",
            alignItems: "center",
            textAlign: 'left',
            flex: 1,
            minWidth: '100%',
            fontWeight: 'normal',
            fontSize: 14
        }}>Compilando
            il questionario accetterà le finalità del trattamento dei dati personali che può
            visionare <div style={{marginLeft: 4}}
                           dangerouslySetInnerHTML={{__html: '<a target="_blank" href="https://www.iubenda.com/privacy-policy/32781657" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy "> Qui</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>'}}/>
        </Typography>,
        style: {textAlign: 'left', flex: 1, minWidth: '100%', fontWeight: 'normal', fontSize: 14},
    },
    {
        type: 'button',
        buttons: [{onClick:closeModal, label: modify?'Chiudi':'Invia', bType: modify?'button':'submit'},
            {label: 'Salva', bType: 'submit',id:'modifySave'}
        ],
        style: {marginRight: 12, marginLeft: 'auto'},
        bStyle: {
            background: 'linear-gradient(to left,#fe821c ,#e45447)',
            // width: '100%',
            borderRadius: 25,
            marginTop: 3,
            marginLeft: 6
        }
    },
    /*
    Qual è la tua fascia di prezzo per gli acquisti di gioielli/orologi? Sei interessato a prodotti di lusso o a prodotti di fascia media?*/
];
let region =
    [{
        "selectValue": "13",
        "selectLabel": "Abruzzo",
        "latitudine": 42.354008,
        "longitudine": 13.391992
    }, {
        "selectValue": "17",
        "selectLabel": "Basilicata",
        "latitudine": 40.633333,
        "longitudine": 15.800000
    }, {
        "selectValue": "18",
        "selectLabel": "Calabria",
        "latitudine": 38.910000,
        "longitudine": 16.587500
    }, {
        "selectValue": "15",
        "selectLabel": "Campania",
        "latitudine": 40.833333,
        "longitudine": 14.250000
    }, {
        "selectValue": "8",
        "selectLabel": "Emilia-Romagna",
        "latitudine": 44.493889,
        "longitudine": 11.342778
    }, {
        "selectValue": "6",
        "selectLabel": "Friuli-Venezia Giulia",
        "latitudine": 45.636111,
        "longitudine": 13.804167
    }, {
        "selectValue": "12",
        "selectLabel": "Lazio",
        "latitudine": 41.893056,
        "longitudine": 12.482778
    }, {
        "selectValue": "7",
        "selectLabel": "Liguria",
        "latitudine": 44.411156,
        "longitudine": 8.932661
    }, {
        "selectValue": "3",
        "selectLabel": "Lombardia",
        "latitudine": 45.464161,
        "longitudine": 9.190336
    }, {
        "selectValue": "11",
        "selectLabel": "Marche",
        "latitudine": 43.616667,
        "longitudine": 13.516667
    }, {
        "selectValue": "14",
        "selectLabel": "Molise",
        "latitudine": 41.561000,
        "longitudine": 14.668400
    }, {
        "selectValue": "1",
        "selectLabel": "Piemonte",
        "latitudine": 45.066667,
        "longitudine": 7.700000
    }, {
        "selectValue": "16",
        "selectLabel": "Puglia",
        "latitudine": 41.125278,
        "longitudine": 16.866667
    }, {
        "selectValue": "20",
        "selectLabel": "Sardegna",
        "latitudine": 39.216667,
        "longitudine": 9.116667
    }, {
        "selectValue": "19",
        "selectLabel": "Sicilia",
        "latitudine": 38.115556,
        "longitudine": 13.361389
    }, {
        "selectValue": "9",
        "selectLabel": "Toscana",
        "latitudine": 43.771389,
        "longitudine": 11.254167
    }, {
        "selectValue": "4",
        "selectLabel": "Trentino-Alto Adige/Südtirol",
        "latitudine": 46.066667,
        "longitudine": 11.116667
    }, {
        "selectValue": "10",
        "selectLabel": "Umbria",
        "latitudine": 43.112100,
        "longitudine": 12.388800
    }, {
        "selectValue": "2",
        "selectLabel": "Valle d'Aosta/Vallée d'Aoste",
        "latitudine": 45.737222,
        "longitudine": 7.320556
    }, {
        "selectValue": "5",
        "selectLabel": "Veneto",
        "latitudine": 45.439722,
        "longitudine": 12.331944
    }]

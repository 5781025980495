import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import video from "../assets/pexels-rostislav-uzunov-8303104.mp4";
import image from "../assets/sprovieri-sito.png";
import PersonalData from "./PersonalData";
import '../App.css';
import moment from "moment/moment";
import {baseurl, setToken} from "../http";
import {Button, TextField, Typography} from "@material-ui/core";
import DefaultLayout from "../DefaultLayout";

export default function SurveyList(props) {
    const [login, setLogin] = useState()
    let user = 'admin@admin.crms'
    const ref=useRef()
    const [loginData, setLoginData] = useState({email: 'admin@admin.crms', password: null})
    let token = localStorage.getItem('token')
    let tokenData
    if(token)tokenData=JSON.parse(token)
    useEffect(()=>{
        if(tokenData||tokenData?.date<moment(new Date()).format('YYYY-MM-DD')){
            console.log('token Trovato')
            setToken(tokenData.token)
        }
    },[tokenData])
    if (!tokenData||tokenData?.date<moment(new Date()).format('YYYY-MM-DD')) return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        overflow: 'hidden'
    }}>
        <video autoPlay muted loop id="myVideo">
            <source src={video} type="video/mp4"/>
        </video>
        <div className={'card'}  style={{width: '300px',minHeight:'unset',height:'fit-content'}}>
            <Typography variant={'h5'} style={{textAlign: 'center'}}>Attenzione!</Typography>
            &nbsp;
            <Typography style={{textAlign: 'center'}}>Inserire la password per continuare!</Typography>
            &nbsp;
            <TextField name={'password'} value={loginData.password} label={'Password'}
                       onChange={event => setLoginData({email: user, password: event.target.value})} type={'password'}/>
            &nbsp;
            <Button style={{
                background: 'linear-gradient(to left,#fe821c ,#e45447)',
                // width: '100%',
                borderRadius: 25,
                color:'white',
                marginTop: 3,
                padding:'4px 10px',
                marginLeft: 'auto'
            }}
                    onClick={() => {
                        if(loginData.password)
                            axios.post(baseurl + 'login', loginData).then(res => {
                                localStorage.setItem('token', JSON.stringify({
                                    token: res.data.message.Authorization,
                                    date: moment(new Date()).format('YYYY-MM-DD')
                                }))
                                window.location.reload()
                            }).catch(err => alert(JSON.stringify(err.response.data.message)))
                        else alert('inserisci una password per continuare')
                    }}>Login</Button>
        </div>
    </div>
    return (
        <DefaultLayout survey/>
    );
}

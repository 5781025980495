import React, {useEffect, useLayoutEffect, useState} from "react";
import GeneralForm from "../GeneralInportedComponents/GeneralForm";
import {ANAGRAFICS_FORM} from "../services/FormProviders";
import {Typography} from "@material-ui/core";
import axios from "axios";
import {baseEndpoint} from "../http";
import ModalSuccess from './ModalSuccess'
import ModalTerms from "./ModalTerms";
export default function PersonalData(props) {
    //region variables
    const [key, setKey] = useState(Date.now())
    const [modal,setModal] = useState(null)
    const [modalTerm,setModalTerm] = useState(null)
    //endregion

    //region lifeCycleEvents

    //onStart
    useEffect(() => {

    }, [])
    useLayoutEffect(()=>{
        document.getElementById('modifySave')?.remove()
    },[])
    //endregion

    //region functions

    //endregion
    return <div>
        <ModalTerms open={modalTerm} close={()=>setModalTerm(false)}/>
        <Typography variant={"h5"} style={{minWidth: 'max-content', fontWeight: 'bold'}}>Parlaci di te</Typography>
        &nbsp;
        <div style={{display: 'flex', flexDirection: 'row'}} key={key}>
            <ModalSuccess open={modal} close={()=>{
                setModal(null)
                props.cardRef?.current?.scrollTo(0,0)
                // document.getElementById('logo').scrollIntoView({behavior:'smooth',block:"start"})
                setKey(Date.now())
            }}/>
            <GeneralForm data={ANAGRAFICS_FORM(false)} onSubmit={(data, obj) => {
                console.log(data)
                axios.post(baseEndpoint + 'notes', {
                    name: (data?.surname || data?.name) ? ((data?.surname || '') +" "+ (data?.name || '')) : 'Anonimo',
                    description: JSON.stringify(data)
                }).then(res => {
                    setModal(true)
                    setKey(Date.now())
                }).catch(console.log)
            }}/>

        </div>

    </div>
}

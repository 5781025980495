import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseEndpoint} from "../http";
import {Button, Modal, Typography} from "@material-ui/core";
import cao, {ANAGRAFICS_FORM} from "../services/FormProviders";
import ModalTerms from "./ModalTerms";
import GeneralForm from "../GeneralInportedComponents/GeneralForm";
import ModalSuccess from "./ModalSuccess";

export default function Surveys(props) {
    //region variables
    const [data, setData] = useState([])
    const [modalData, setModalData] = useState({open: false, data: {}})
    const [success, setSuccess] = useState({open: false})

    //region lifeCycleEvents

    //onStart
    useEffect(() => {
    getData()
    }, [])
    //endregion
    console.log(data)
    //region functions
    const getData=()=>{
        axios.get(baseEndpoint + 'notes?perPage=15000').then(res => {
            setData(res?.data?.message?.data)
        })
    }
    //endregion
    return <div>
        <ModalSuccess open={success.open} title={'Successo'} label={'Elemento modificato con successo'} close={()=>{
            getData()
            setSuccess({open: false})
            setModalData({open:false})
        }}/>

        <Button onClick={() => window.location.hash = ''} style={{position: 'absolute', top: 24, left: 24}}
                variant={'contained'} color={"secondary"}>Torna ai sondaggi</Button>
        <div style={{border:'1px solid #a4a4a4',padding:6,borderRadius:16,background:'#e4e4e4'}}>
        <div style={{display: 'flex', justifyContent: 'flex-start',fontWeight:'bold',borderBottom:'1px solid #a4a4a4'}}>
            <Typography style={{width: 25,padding:'0 5px'}}>ID</Typography>
            <Typography style={{width: 200,borderLeft:'1px solid #a4a4a4',padding:'0 5px',fontWeight:'bold'}}>Nome</Typography>
            <Typography style={{width: 250,borderLeft:'1px solid #a4a4a4',padding:'0 5px',fontWeight:'bold'}}>Prodotti che vorresti acquistare</Typography>
            <Typography style={{width: 250,borderLeft:'1px solid #a4a4a4',padding:'0 5px',fontWeight:'bold'}}>Prodotti acquistati</Typography>
            {/*<Typography >{e.description}</Typography>*/}
        </div>
        {data.map((e,i) => <div onClick={() => setModalData({open: true, data: JSON.parse(e.description),id:e.id})}
                            style={{display: 'flex', justifyContent: 'flex-start',borderBottom:i<data.length-1&&'1px solid #a4a4a4'}}>
                <Typography style={{width: 25,padding:'0 5px'}}>{e.id}</Typography>
            <Typography style={{width: 200,borderLeft:'1px solid #a4a4a4',padding:'0 5px'}}>{e.name}</Typography>
            <Typography style={{width: 250,borderLeft:'1px solid #a4a4a4',padding:'0 5px'}}>{JSON.parse(e.description).product_want}</Typography>
            <Typography style={{width: 250,borderLeft:'1px solid #a4a4a4',padding:'0 5px'}}>{JSON.parse(e.description).product_buy}</Typography>
            </div>
        )}
        </div>
        <Modal open={modalData.open} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <div style={{width: '90%',overflow:'auto',height:'90%', background: 'white', padding: 24,borderRadius:24}}>
                <GeneralForm modify data={ANAGRAFICS_FORM(true,()=>setModalData({open: false,data:{}}))} dataForm={modalData.data} onSubmit={(submit)=>{
                    axios.put(baseEndpoint + 'notes/'+modalData.id, {
                        id:modalData.id,
                        name:submit.surname+' '+submit.name,
                        description: JSON.stringify(submit)
                    }).then(res => {
                        setSuccess({open: true})
                    }).catch(console.log)

                }
                }/>
            </div>
        </Modal>
    </div>
}

// eslint-disable-next-line import/prefer-default-export
export function FormToObject(formData) {
	const object = {};
	formData.forEach(function (value, key) {
		object[key] = value;
	});
	return object;
}
export const selectMonthData = [
	'Gennaio',
	'Febbraio',
	'Marzo',
	'Aprile',
	'Maggio',
	'Giugno',
	'Luglio',
	'Agosto',
	'Settembre',
	'Ottobre',
	'Novembre',
	'Dicembre'
];
export const selectYearData = ['2019', '2020', '2021', '2022', '2023', '2024', '2025'];

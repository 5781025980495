import React, {useEffect} from "react";
import {Button, Modal, Typography} from "@material-ui/core";
import axios from "axios";
import {baseurl} from "../http";
import moment from "moment/moment";

export default function ModalSuccess({setKey, open, close, label, title})
{
    //region variables

    //endregion

    //region lifeCycleEvents

    //onStart
    useEffect(() => {

    }, [])
    //endregion
    //region functions
    //endregion
    return <Modal open={open} style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{
            width: 300,
            height: label?135: 185,
            borderRadius: 16,
            textAlign: 'center',
            margin: 30,
            padding: 12,
            display: 'flex',
            flexDirection: 'column',
            background: 'white'
        }}>
            <Typography variant={'h5'} style={{fontWeight: 'bold'}}>{title ||'Completato!'}</Typography>
            &nbsp;
            <Typography>{!label && 'Sondaggio inserito con successo'}</Typography>
            <Typography>{label || 'Grazie per aver risposto alle nostre domande'}</Typography>
            &nbsp;
            <Button style={{
                background: 'linear-gradient(to left,#fe821c ,#e45447)',
                // width: '100%',
                borderRadius: 25,
                color: 'white',
                marginTop: 3,
                padding: '4px 10px',
                marginLeft: 'auto'
            }}
                    onClick={() => close()}>Chiudi</Button>
        </div>
    </Modal>
}

import React, {useEffect} from "react";
import {Button, Modal, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseurl} from "../http";
import moment from "moment/moment";

export default function ModalTerms(props) {

    return <Modal style={{justifyContent:'center', display:"flex",padding:20,alignItems:'center'}} open={props.open} close={props.close} onClose={props.close}>
        <div style={{width:700,background:'white',borderRadius:20,padding:16}}>
            <Typography variant={"h5"}>Termini e condizioni</Typography>
            &nbsp;
            <Typography variant={"h6"}>Clausole:</Typography>
            <Typography variant={"h6"}>-Consenso di fornire i propri dati personali ed al trattamento di essi a fini di marketing</Typography>
            <Typography variant={"h6"}>-Revoca del consenso del trattamento dei dati personali in qualsiasi momento</Typography>
            &nbsp;
            <Typography >I dati raccolti dal CRM saranno trattati con la massima riservatezza ed in conformità al Regolamento Gnerale sulla Protezione dei Dati (RGPD) del' Unione Europea (UE) 2016/679, articolo 9.</Typography>
            <Typography >Compilando il questionario acconstirà al trattamento dei dati personali secondo i metodi citati.</Typography>
            &nbsp;
            <Typography >Per richiedere la revoca dei dati si prega di contattare niklasprovieri@libero.it o di effettuare una richiesta nel negozio di riferimento. </Typography>
            <Typography >Il questionario è facoltativo ed è possibile compilarlo in anonimato</Typography>
            <div style={{display:'flex'}}>
            <Button onClick={props.close} style={{
                background: 'linear-gradient(to left,#fe821c ,#e45447)',
                // width: '100%',
                marginTop:8,
                borderRadius: 25,
                color:'white',
                padding:'4px 10px',
                marginLeft: 'auto'
                    }}>Chiudi</Button>

            </div>
        </div>

    </Modal>
}

import React, {useEffect, useRef} from "react";
import video from'./assets/pexels-rostislav-uzunov-8303104.mp4'
import image from'./assets/sprovieri-sito.png'
import PersonalData from "./pages/PersonalData";
import {AppBar} from "@material-ui/core";
import axios from "axios";
import {baseEndpoint} from "./http";
import Surveys from "./pages/Surveys";
export default function DefaultLayout(props) {
    //region variables
    //endregion
    const ref=useRef()
    //region lifeCycleEvents

    //onStart
    useEffect(() => {
        if(props.survey){

        }
    }, [props.survey])
    //endregion

    //region functions

    //endregion
    return <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',paddingTop:20,overflow:'scroll'}}>
        <video autoPlay muted loop id="myVideo">
            <source src={video} type="video/mp4"/>
        </video>
        <div className={'card'} ref={ref} style={{marginTop:22}}>
            <img src={image} id={'logo'} style={{width:250,alignSelf:'center'}} alt={"logo"}/>
            {!props.survey?<PersonalData cardRef={ref}/>:<Surveys/>}
        </div>
    </div>
}

/* eslint-disable no-dupe-keys */

import React, {useEffect, useState} from 'react';
import {Avatar, Button, Checkbox, Link, Radio, RadioGroup, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {FormToObject} from './FormDataUtils';
import GeneralTextInput from './GeneralTextInput';
import GeneralRadio from "./GeneralRadio";

export default function GeneralForm(props) {
    const {data} = props;
    const [dataForm, setDataForm] = useState({});
    useEffect(() => {
        if (props.dataForm) setDataForm(props.dataForm);
    }, [props.dataForm]);
    let re;

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                const formdata = new FormData(e.target);
                const dataObject = FormToObject(formdata);
                props.onSubmit(dataObject, formdata);
            }}
            style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap',gap:8}}
            className={`flex flex-wrap Fgap ${props.rounded && 'field-rounded'}`}
        >
            {data.map(e => {
                if (e.type === 'avatar')
                    return (
                        <div className="flex w-full justify-center ">
                            <label className="relative change-container" htmlFor="avatarData">
                                <Avatar
                                    className="hover-opacity cursor-pointer"
                                    style={{
                                        ...{width: '12rem', height: '12rem', margin: '20px 0', marginTop: 16},
                                        ...e.style
                                    }}
                                    src={
                                        props.avatarSRC ||
                                        'https://gladstoneentertainment.com/wp-content/uploads/2018/05/avatar-placeholder.gif'
                                    }
                                />
                                <div
                                    className="absolute a-center w-128 text-center hidden z-0 toDisplay cursor-pointer h-full">
                                    <Typography className="text-white">{e.label}</Typography>
                                    <input style={{display: 'none'}} type="file" id="avatarData" name={e.name}/>
                                </div>
                            </label>
                        </div>
                    );
                if (e.type === 'radio') {
                    return <GeneralRadio e={e} name={e.name}/>
                }
                if (e.type === 'button')
                    return (
                        <div className="w-full flex justify-end" style={e.style}>
                            {e.buttons?.map(el => (
                                <Button id={el.id} style={{...e.bStyle}} onClick={el.onClick} variant="contained" color="secondary" type={el.bType}>
                                    {el.label}
                                </Button>
                            ))}
                        </div>
                    );
                if (e.type === 'terms')
                    return (
                        <div className="w-full flex justify-start items-center" style={e.style}>
                            <Checkbox color="primary"/>{' '}
                            <Link href="#" target="_blank">
                                Accetto i termini
                            </Link>
                        </div>
                    );
                if (e.type !== 'subtitle') {
                    return (
                        <GeneralTextInput
                            onChange={e.onChange}
                            variant={e.variant}
                            modify={props.modify}
                            name={e.name}
                            setData={value => {
                                const temp = {...dataForm};
                                temp[e.name] = value;
                                setDataForm(temp);
                            }}
                            placeholder={e.placeholder}
                            minW={e.minWidth}
                            maxW={e.minWidth}
                            multiple={e.multiple}
                            style={e.style}
                            value={dataForm[e.name] || e.value || ''}
                            flex={e.flex}
                            regex={e.regex || null}
                            textType={e.textType}
                            selectValues={e.selectValues}
                            type={e.type}
                            multiline={e.multiline}
                            rows={e.rows}
                            label={e.label}
                            required={e.required}
                        />
                    );
                }


                return (
                    <Typography variant={e.variant} style={e.style || {fontSize:16,textAlign:'left',width:'100%'}} className="w-full">
                        {e.value}
                    </Typography>
                );
            })}
        </form>
    );
}
GeneralForm.propTypes = {
    data: PropTypes.array.isRequired,
    dataForm: PropTypes.object,
    onSubmit: PropTypes.func.isRequired
};

import {Checkbox, Chip, IconButton, ListItemText, MenuItem, TextField, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {Autocomplete} from "@material-ui/lab";
import {CloseRounded, DeleteRounded, Edit} from '@material-ui/icons'
// import { DatePicker, DesktopDatePicker, TimePicker } from '@mui/lab';

export default function GeneralTextInput(props) {
    const [edit, setEdit] = useState()
    console.log(props.modify)
    // if (props.textType === 'time')
    // 	return (
    // 		<TimePicker
    // 			label="Time"
    // 			value={props.value}
    // 			style={{ ...{ flex: props.flex || 1, minWidth: props.minW || 200, marginRight: 8 }, ...props.style }}
    // 			onChange={e => {
    // 				if (props.onChange) props.onChange(e);
    // 				props.setData(e);
    // 			}}
    // 			renderInput={params => <TextField {...params} />}
    // 		/>
    // 	);
    if (props.textType === 'calendar')
        return (
            <div
                className="flex child-full"
                style={{flex: props.flex || 1, minWidth: props.minW || 200, marginRight: 8}}
            >
                <input type={'date'}/>
                {/*calendar*/}
                {/* <DesktopDatePicker */}
                {/*	inputFormat="DD/MM/YYYY" */}
                {/*	label={props.label} */}
                {/*	className="w-full flex flex-1 " */}
                {/*	componentsProps={{ style: { width: '100%' } }} */}
                {/*	value={props.value || new Date()} */}
                {/*	onChange={e => { */}
                {/*		if (props.onChange) props.onChange(e); */}
                {/*		props.setData(e); */}
                {/*	}} */}
                {/*	renderInput={params => <TextField {...params} />} */}
                {/* /> */}
            </div>
        );
    if (props.textType === 'auto')
        return (
            (((!props.modify) || (!props.value)) || edit) ? (
                <Autocomplete label={props.label}
                              variant={props.variant || 'filled'}
                              name={props.name || ''}
                              style={{
                                  ...{
                                      flex: props.flex || 1,
                                      minWidth: props.minW || 200,
                                      marginRight: 8
                                  }, ...props.style
                              }}
                              size={"small"}
                              filterOptions={(options, state) => {
                                  console.log(options)
                                  return options.filter((item) =>
                                      item.selectLabel.toLowerCase().includes(state.inputValue.toLowerCase())
                                  ).slice(0, 50);
                              }}
                              getOptionLabel={(option) => option.selectLabel}
                              onChange={e => {
                                  if (props.onChange) props.onChange(e.target.value);
                                  props.setData(e.target.value);
                                  if (edit) setEdit(false)
                              }}
                              placeholder={props.placeholder}
                              required={props.required}
                              options={props.selectValues}
                              renderInput={e => (
                                  <TextField
                                      label={props.label}
                                      variant={props.variant || 'filled'}
                                      name={props.name} {...e}/>
                              )}
                />
            ) : (
                <div style={{
                    display: 'flex', alignItems: 'center', ...{
                        flex: props.flex || 1,
                        minWidth: props.minW || 200,
                        justifyContent:'space-between',
                        paddingLeft: 12,
                        marginRight: 8
                    }, ...props.style
                }}
                >
                    <Typography>{props.value}</Typography>
                    <DeleteRounded name={'pencil'} onClick={() => setEdit(true)} color={'error'} />
                </div>
            )
        )
    console.log(props.value, props.name)
    return (
        <TextField
            label={props.label}
            variant={props.variant || 'filled'}
            name={props.name || ''}
            size={"small"}
            onChange={e => {
                if (props.onChange) props.onChange(e.target.value);
                props.setData(e.target.value);
            }}
            style={{...{flex: props.flex || 1, minWidth: props.minW || 200, marginRight: 8}, ...props.style}}
            value={props.value}
            placeholder={props.placeholder}
            required={props.required}
            error={props.regex && props.value ? !props.regex.test(props.value) : false}
            helperText={props.regex && props.value ? !props.regex.test(props.value) && 'Campo non valido' : null}
            type={props.textType || 'text'}
            multiline={props.multiline}
            rows={props.rows}
            select={props.textType === 'select'}
            SelectProps={
                props.multiple
                    ? {
                        multiple: true,
                        value: props.value || [],
                        onChange: e => {
                            props.setData(e.target.value);
                        },
                        renderValue: selected =>
                            selected.map((e, i) => {
                                return <Chip className="mr-4" title={e} label={e} size="small" color="secondary"/>;
                            })
                    }
                    : {}
            }
            color={props.color || 'primary'}
        >
            {props.textType === 'select' &&
            props.selectValues?.map(e => {
                if (!props.multiple)
                    return (
                        <MenuItem key={e.selectValue} value={e.selectValue}>
                            {e.selectLabel}
                        </MenuItem>
                    );
                return (
                    <MenuItem key={e.selectValue} value={e.selectValue}>
                        <Checkbox checked={props.value.indexOf(e.selectValue) > -1}/>
                        <ListItemText primary={e.selectLabel}/>
                    </MenuItem>
                );
            })}
        </TextField>
    );
}

import logo from './logo.svg';
import './App.css';
import DefaultLayout from "./DefaultLayout";
import video from "./assets/pexels-rostislav-uzunov-8303104.mp4";
import React, {useEffect, useState} from "react";
import {Button, TextField, Typography} from "@material-ui/core";
import axios from "axios";
import {baseurl, setToken} from "./http";
import moment from 'moment/moment';
import { useLocation } from 'react-router-dom';

function App() {
    const [login, setLogin] = useState(false)
    let user = 'admin@admin.crms'
    const [loginData, setLoginData] = useState({email: 'admin@admin.crms', password: null})
    let token = localStorage.getItem('token')
    let tokenData
    try {
        if(token)tokenData=JSON.parse(token)

    }catch (e){
        tokenData=null
    }
    const navigation=useLocation()
    useEffect(()=>setLogin(navigation.hash),[navigation,navigation.hash])
    useEffect(()=>{
        if(tokenData||tokenData?.date<moment(new Date()).format('YYYY-MM-DD')){
            console.log('token Trovato')
            setToken(tokenData.token)
        }
    },[tokenData])
    if(window.location.hash!=='#list')return <DefaultLayout/>
    if (!tokenData||tokenData?.date<moment(new Date()).format('YYYY-MM-DD')) return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        overflow: 'hidden'
    }}>
        <video autoPlay muted loop id="myVideo">
            <source src={video} type="video/mp4"/>
        </video>
        <div className={'card'} style={{width: '300px',minHeight:'unset',height:'fit-content'}}>
            <Typography variant={'h5'} style={{textAlign: 'center'}}>Attenzione!</Typography>
            &nbsp;
            <Typography style={{textAlign: 'center'}}>Inserire la password per continuare!</Typography>
            &nbsp;
            <TextField name={'password'} value={loginData.password} label={'Password'}
                       onChange={event => setLoginData({email: user, password: event.target.value})} type={'password'}/>
            &nbsp;
            <Button style={{
                background: 'linear-gradient(to left,#fe821c ,#e45447)',
                // width: '100%',
                borderRadius: 25,
                color:'white',
                marginTop: 3,
                padding:'4px 10px',
                marginLeft: 'auto'
            }}
                    onClick={() => {
                        if(loginData.password)
                        axios.post(baseurl + 'login', loginData).then(res => {
                            localStorage.setItem('token', JSON.stringify({
                                token: res.data.message.Authorization,
                                date: moment(new Date()).format('YYYY-MM-DD')
                            }))
                            window.location.reload()
                        }).catch(err => alert(JSON.stringify(err.response.data.message)))
                    else alert('inserisci una password per continuare')
                    }}>Login</Button>
        </div>
    </div>
    return (
        <DefaultLayout survey={window.location.hash==='#list'}/>
    );
}

export default App;

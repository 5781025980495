import React, {useEffect, useState} from "react";
import {Radio, RadioGroup, Typography} from "@material-ui/core";
const minW=850
export default function GeneralRadio({e,name}) {
    //region variables
    const [selectedValue,setSelectedValue]=useState(1)
    //endregion

    //region lifeCycleEvents
    console.log(selectedValue)
    //onStart
    useEffect(() => {

    }, [])
    //endregion

    //region functions

    //endregion
    return <div style={{display: 'flex', justifyContent: 'space-between', width: '100%',alignItems:'center',...(window.innerWidth<minW?{flexDirection:'column'}:{})}}>
        <Typography style={{...e.labelStyle,...(window.innerWidth<minW?{width:'100%'}:{})}}>{e.label}:</Typography>
        <RadioGroup value={selectedValue} style={{marginLeft:window.innerWidth<minW&&'auto'}} onChange={(event, value)=>setSelectedValue(parseInt(value))}>
            <div style={{display:'flex',justifyContent:'center'}}>
                {e.selectValues.map(el => <div style={{display:'flex',alignItems:'center'}}>
                    <Typography>{el.selectLabel}</Typography>
                    <Radio name={el.name} placeholder={el.placeholder} value={el.selectValue}/>
                </div>)}
            </div>
            <input value={selectedValue} style={{display:'none'}} name={name}/>
        </RadioGroup>
    </div>
}
